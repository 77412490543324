@import '../../Theme/base.scss';

#root {
   .profile-dropdown-icons {
      font-size: 18px;
      margin: 0 3px 0 0;
   }
   .notification-bell {
      margin-left: 5px;
      padding: 0 8px;
      font-size: 30px;
      height: 100%;
      line-height: 80px;
      transition-property: background-color;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      &:hover {
         background-color: $pgdb-lightgrey;
      }
   }
}

.left-nav-menu {
   margin: 10px 0 !important;
}

.right-nav-panel {
   position: absolute;
   right: 0;
   top: 0;
}

.profile-right-drop-down {
   margin-left: 0 !important;
}

.flex-vertical-center {
   display: flex;
   align-items: center;
}

.profile-link {
   padding: 0 0 0 5px;
   transition-property: background-color;
   transition-duration: 0.3s;
   transition-timing-function: ease;
   &:hover {
      background-color: $pgdb-lightgrey;
   }
}

.profile {
   height: 38px;
   width: 38px;
   text-align: center;
   margin-left: 10px;
}

.header-dropdown {
   .dropdown-content {
      height: auto !important;
      width: 170px !important;
      top: 80px !important;
      left: 0px !important;
      overflow: visible !important;
   }
   #dropdown1 {
      li:first-child {
         margin-top: 0;
      }
      li:last-child {
         margin-bottom: 0;
      }
   }
}

@media only screen and (max-width: 1300px) {
   .hide-on-tablet-and-down{
      display: none !important;
   }

   .brand-logo{
      left:50%;
      transform: translateX(-50%) !important;
   }
}

@media only screen and (max-width: 1300px) {
   nav a.sidenav-trigger {
      display:block !important;
      margin-left: 5% !important;
    }
}

.close-panel-padding {
   padding-top: 5px;
   padding-right: 5px;
   padding-left: 5px;
}

.no-margin-bottom {
   margin-bottom: 0px;
}
