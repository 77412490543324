.summary hr {
  border: none;
  height: 1px;
  color: black;
  /* Old IE */
  background-color: black;
  /* Modern Browsers */ }

.summary .thick {
  height: 3px; }

.summary .drawer {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  font-family: 'HelveticaNeueLTPro-Bd'; }
  .summary .drawer .mock-collapsible-header {
    padding: 15px; }
  .summary .drawer li {
    display: inline; }
    .summary .drawer li .drawer-title {
      background-color: inherit;
      border: none;
      display: flex;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000; }
      .summary .drawer li .drawer-title .header-title {
        margin-right: auto; }
      .summary .drawer li .drawer-title .edit-btn {
        color: #00afdb;
        color: #00afdb;
        font-size: 14px;
        display: flex;
        align-items: center;
        margin: 0 20px; }
      .summary .drawer li .drawer-title .caret {
        margin-right: 0;
        transition: transform 0.2s ease; }
    .summary .drawer li.active .drawer-title .caret {
      transform: rotate(-180deg);
      transform-origin: 0.6em 0.5em; }
    .summary .drawer li .default-cursor {
      cursor: auto; }
    .summary .drawer li .drawer-outer-body {
      padding: 0rem;
      border: none; }
      .summary .drawer li .drawer-outer-body .drawer-inner-body {
        padding: 0rem 2rem; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .licence-number-cell,
        .summary .drawer li .drawer-outer-body .drawer-inner-body .name-cell {
          width: 150px; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .cpd-cell {
          padding-left: 80px; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .shorter {
          margin-bottom: 5px; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .subtitle {
          font-size: 14px;
          font-family: 'HelveticaNeueLTPro-Roman';
          color: #c4c4c4; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .description {
          font-size: 16px;
          font-family: 'HelveticaNeueLTPro-Md'; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .terms-and-cond {
          line-height: 1.2rem;
          font-size: 12px; }
    .summary .drawer li .table-container {
      font-family: 'HelveticaNeueLTPro-Roman'; }
      .summary .drawer li .table-container .row {
        margin-bottom: 0; }
  .summary .drawer .licence-table tr,
  .summary .drawer .licence-table th {
    border: none; }
  .summary .drawer .licence-table .licence-cell {
    width: 450px; }
  .summary .drawer .licence-table .disciplinary-cell {
    padding-left: 29px; }
  .summary .drawer .licence-table .amount-header {
    text-align: right; }
  .summary .drawer .licence-table .amount-cell {
    width: 9%;
    text-align: right;
    padding-right: 5px; }

.summary .total-table td {
  font-weight: bold;
  text-align: right; }

.summary .total-table .currency-cell {
  width: 10px; }

.summary .total-table .total-cell {
  width: 12.5%;
  padding-right: 35px; }

.summary .total-table .total-label-cell {
  width: 20%;
  text-align: right;
  padding-right: 25px; }

.summary .generic-table tr:first-child {
  border-bottom: none; }

.summary .generic-table .licence-cell {
  border-bottom: none;
  font-weight: bold; }

.summary .supervisor-table .supervisor-licence-cell {
  width: 450px; }

.question-and-answer > .col {
  padding-top: 15px;
  padding-bottom: 15px; }

.question-and-answer .s2 {
  text-align: right;
  font-weight: bold; }
