.licence-payment-view-table {
  margin-bottom: 20px; }
  .licence-payment-view-table tr {
    border-top: solid 1px #e4e4e4;
    border-bottom: solid 1px #e4e4e4; }
    .licence-payment-view-table tr:first-child {
      border-top-color: #c4c4c4; }
    .licence-payment-view-table tr:last-child {
      border-bottom-color: #c4c4c4; }
    .licence-payment-view-table tr td {
      padding-left: 20px; }
    .licence-payment-view-table tr .activeblue-text {
      font-weight: bold; }
  .licence-payment-view-table .name-cell {
    font-weight: bold;
    width: 170px; }
  .licence-payment-view-table .supervisor-cell {
    width: 320px; }
