.error-page-container {
    .error-message-body {
        // height: 300px;
    }

    .controls {
        padding-top: 50px;
        button {
            &:first-child {
                margin-left: 0px;
            }
        }
    }

    .warning-icon {
        color: red;
        padding-right: 20px;
    }
}