@import '../../../Theme/base.scss';

.registration-payment-header{
   font-size: 1.5em;
   font-weight: 700;
}

.registration-payment-container{
   .radio-display{
      flex-direction: column;

      .radio-option{
         margin: 10px 0px;
      }
   }

   .radio-groups{
      margin-top: 10px;
      margin-bottom: 10px;
      border-bottom: none;

      .radio-option{
         display: inline;
      }
   }

   .subheader{
      color:grey;
      font-size: 1.1em;
      font-style: italic;
   }

   [type="checkbox"].filled-in:disabled:not(:checked) + span:not(.lever)::after{
      background-color: lightgray;
   }

   .registration-only-container{
      margin: 10px 0px 0px 40px;
   }

   .spinner-container{
      display: inline-flex;
      width: 100%;
      justify-content: center;

      .loading-button{
         width:50px;
         height:50px;
      }
   }
}



.payment-subheader{
   font-size: 1.1em;
}

.total-amount-container{
   width: 100%;
   background-color: white;
   padding: 15px 20px;
   border-radius: 5px;
   box-shadow: 0 3px 10px rgba(87, 89, 89, 0.49);

   .initial-amount{
      color: grey;
      
      .text{
         padding: 12px 20px;
      }
      .amount{
         padding: 12px 20px;
      }
   }

   .first-row{
      padding: 16px 20px 8px 20px;
   }

   .last-row{
      padding: 8px 20px 16px 20px;
   }

   td{
      padding: 8px 20px;
   }

   tr{
      border-bottom: none;
      padding: 10px;
   }

   .text{
      width: 70%;
      font-weight: bold;
   }

   .amount{
      width: 30%;
      text-align: right;
      font-weight: bold;
   }
}

.close-modal-button{
   padding: 0px 0px 20px 0px;
}

.close-payment-button{
   padding: 10px 15px 10px 15px;
   border-radius: 28px;
   border: $pgdb-activeblue 1px solid;
   border-width: 2px;
   border-style: solid;
   background-color: $pgdb-activeblue;
   color: $pgdb-white;
   text-align: center;
   font-family: 'HelveticaNeueLTPro-Md';
   line-height: 16px;
   font-size: 12px;
   font-weight: 500;
   text-transform: none;
   min-width:100px;
   cursor: pointer;
}

.poli-pay-button, .windcave-pay-button{
   width: 85%;
   box-shadow: 1px 1px 4px 0px rgba(156,156,156,0.84);
   background-color: #fafafa;
   border: 1px solid #f0f3f4;
   padding-top: 10px;
   padding-bottom: 10px;
  
}

.poli-pay-button{
   margin-top:10px;
}
.windcave-pay-button{
   margin-top: 20px;
   margin-bottom: 20px;
}

.registration-pay-button:hover{
   background-color: $pgdb-activeblue !important;
}

@media (max-width: 500px) {
   .poli-pay-button, .windcave-pay-button{
      width: 90%;
   }
 }

.payment-title{
   text-align:center !important;
   font-weight: 700;
}

.payment-text{
   text-align:center !important;
}

.payment-cursor {
  cursor: pointer;
}

.waiver-text{
   font-weight: bold;
   margin: 0px
}

.table-clause-container{
   margin-top: 10px;

   .table-clause-text{
      font-weight: bold;
      margin: 0px
   }
}
