@import '../../../Theme/base.scss';
$first-cell-padding: 80px;
$licence-cell-width: 450px;

.summary {
   hr {
      border: none;
      height: 1px;
      color: black; /* Old IE */
      background-color: black; /* Modern Browsers */
   }
   .thick {
      height: 3px;
   }

   .drawer {
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      border: none;
      font-family: 'HelveticaNeueLTPro-Bd';

      .mock-collapsible-header {
         padding: 15px;
      }

      li {
         display: inline;

         .drawer-title {
            background-color: inherit;
            border: none;
            display: flex;
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;

            .header-title {
               margin-right: auto;
            }

            .edit-btn {
               color: $pgdb-activeblue;
               color: #00afdb;
               font-size: 14px;
               display: flex;
               align-items: center;
               margin: 0 20px;
            }

            .caret {
               margin-right: 0;
               transition: transform 0.2s ease;
            }
         }
         &.active {
            .drawer-title {
               .caret {
                  transform: rotate(-180deg);
                  transform-origin: 0.6em 0.5em;
               }
            }
         }

         .default-cursor {
            cursor: auto;
         }

         .drawer-outer-body {
            padding: 0rem;
            border: none;

            .drawer-inner-body {
               padding: 0rem 2rem;

               .licence-number-cell,
               .name-cell {
                  width: 150px;
               }

               .cpd-cell {
                  padding-left: $first-cell-padding;
               }

               .shorter {
                  margin-bottom: 5px;
               }

               .subtitle {
                  font-size: 14px;
                  font-family: 'HelveticaNeueLTPro-Roman';
                  color: $pgdb-grey;
               }
               .description {
                  font-size: 16px;
                  font-family: 'HelveticaNeueLTPro-Md';
               }

               .terms-and-cond {
                  line-height: 1.2rem;
                  font-size: 12px;
               }
            }
         }

         .table-container {
            font-family: 'HelveticaNeueLTPro-Roman';

            .row {
               margin-bottom: 0;
            }
         }
      }

      .licence-table {
         tr,
         th {
            border: none;
         }

         .licence-cell {
            width: $licence-cell-width;
         }

         .disciplinary-cell {
            padding-left: 29px;
         }

         .amount-header {
            text-align: right;
         }

         .amount-cell {
            width: 9%;
            text-align: right;
            padding-right: 5px;
         }
      }
   }

   .total-table {
      td {
         font-weight: bold;
         text-align: right;
      }
      .currency-cell {
         width: 10px;
      }
      .total-cell {
         width: 12.5%;
         padding-right: 35px;
      }
      .total-label-cell {
         width: 20%;
         text-align: right;
         padding-right: 25px;
      }
   }

   .generic-table {
      tr {
         &:first-child {
            border-bottom: none;
         }
      }

      .licence-cell {
         border-bottom: none;
         font-weight: bold;
      }
   }

   .supervisor-table {
      .supervisor-licence-cell {
         width: $licence-cell-width;
      }
   }
}

.question-and-answer {
   & > .col {
      padding-top: 15px;
      padding-bottom: 15px;
   }

   .s2 {
      text-align: right;
      font-weight: bold;
   }
}