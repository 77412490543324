.exam-root .summary hr {
  border: none;
  height: 1px;
  color: black;
  /* Old IE */
  background-color: black;
  /* Modern Browsers */ }

.exam-root .summary .thick {
  height: 3px; }

.exam-root .borderless {
  border: 0; }

.exam-root .drawer {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  font-family: 'HelveticaNeueLTPro-Bd'; }
  .exam-root .drawer .mock-collapsible-header {
    padding: 15px; }
  .exam-root .drawer li {
    display: inline; }
    .exam-root .drawer li .drawer-title {
      background-color: inherit;
      border: none;
      display: flex;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000; }
      .exam-root .drawer li .drawer-title .header-title {
        margin-right: auto; }
      .exam-root .drawer li .drawer-title .edit-btn {
        color: #00afdb;
        color: #00afdb;
        font-size: 14px;
        display: flex;
        align-items: center;
        margin: 0 20px; }
      .exam-root .drawer li .drawer-title .caret {
        margin-right: 0;
        transition: transform 0.2s ease; }
    .exam-root .drawer li.active .drawer-title .caret {
      transform: rotate(-180deg);
      transform-origin: 0.6em 0.5em; }
    .exam-root .drawer li .default-cursor {
      cursor: auto; }
    .exam-root .drawer li .drawer-outer-body {
      padding: 0rem;
      border: none; }
      .exam-root .drawer li .drawer-outer-body .drawer-inner-body {
        padding: 0rem 2rem; }
        .exam-root .drawer li .drawer-outer-body .drawer-inner-body .licence-number-cell {
          width: 80px; }
        .exam-root .drawer li .drawer-outer-body .drawer-inner-body .cpd-cell {
          padding-left: 80px; }
        .exam-root .drawer li .drawer-outer-body .drawer-inner-body .shorter {
          margin-bottom: 5px; }
        .exam-root .drawer li .drawer-outer-body .drawer-inner-body .subtitle {
          font-size: 14px;
          font-family: 'HelveticaNeueLTPro-Roman';
          color: #c4c4c4; }
        .exam-root .drawer li .drawer-outer-body .drawer-inner-body .description {
          font-size: 16px;
          font-family: 'HelveticaNeueLTPro-Md'; }
        .exam-root .drawer li .drawer-outer-body .drawer-inner-body .terms-and-cond {
          line-height: 1.2rem;
          font-size: 12px; }
    .exam-root .drawer li .table-container {
      font-family: 'HelveticaNeueLTPro-Roman'; }
      .exam-root .drawer li .table-container .row {
        margin-bottom: 0; }
  .exam-root .drawer .licence-table tr,
  .exam-root .drawer .licence-table th {
    border: none; }

.exam-root .amount-header {
  text-align: right; }

.exam-root .amount-cell {
  text-align: right;
  padding-right: 5px; }

.exam-root .total-table td {
  font-weight: bold;
  text-align: right;
  width: 15%; }

.exam-root .total-table .total-cell {
  padding-right: 30px; }

.exam-root .generic-table tr:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
