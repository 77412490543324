@import '../../../Theme/base.scss';
  
.registration-type-profile-row {
  background-color: none;
  margin-bottom: 0;
}

  .registration-container {
   
    .row.no-margin {
       margin-bottom: 0;
    }
    
    .radio-left-groups{
      [disabled|=""] + .radio-option-label{
        cursor: auto;
      }
    }

    .registration-type-profile-row{
      button.loading-button {
        display: flex;
        align-items: center;
        padding-top: 28px;
        padding-bottom: 28px;
      }
    }
  }

  .important-note {
    padding: 15px;
    background-color: $pgdb-white;
    .important-note-header {
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
        color: $pgdb-activeblue;
      }
    }
    .important-note-content {
      svg {
        font-size: 10px;
        margin-right: 5px;
      }
      .important-note-sublist {
        margin-left: 15px;
      } 
    }  
    .important-note-footer {
      
      svg {
        font-size: 10px;
        margin-right: 5px;
        color: $pgdb-activeblue;
      }
      .important-note-sublist {
        margin-left: 15px;
      } 
      
      flex-direction: column;
    }    
  }

.view-only-note {
  color: $pgdb-red;
}
.contact-numnber {
  color: $pgdb-activeblue;
}

.navigate-buttons {
  text-align: center;
  text-transform: none;
  float: right;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}





