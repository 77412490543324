@font-face {
  font-family: 'MyriadPro';
  src: local("MyriadPro"), url("./../../fonts/MyriadPro-Regular.eot");
  src: url("./../../fonts/MyriadPro-Regular.eot?#iefix") format("embedded-opentype"), url("./../../fonts/MyriadPro-Regular.woff") format("woff"), url("./../../fonts/MyriadPro-Regular.ttf") format("truetype"), url("./../../fonts/MyriadPro-Regular.svg#MyriadPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'MyriadPro-It';
  src: local("MyriadPro"), url("./../../fonts/MyriadPro-It.eot");
  src: url("./../../fonts/MyriadPro-It.eot?#iefix") format("embedded-opentype"), url("./../../fonts/MyriadPro-It.woff") format("woff"), url("./../../fonts/MyriadPro-It.ttf") format("truetype"), url("./../../fonts/MyriadPro-It.svg#MyriadPro-It") format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'MyriadPro';
  src: local("MyriadPro"), url("./../../fonts/MyriadPro-Bold.eot");
  src: url("./../../fonts/MyriadPro-Bold.eot?#iefix") format("embedded-opentype"), url("./../../fonts/MyriadPro-Bold.woff") format("woff"), url("./../../fonts/MyriadPro-Bold.ttf") format("truetype"), url("./../../fonts/MyriadPro-Bold.svg#MyriadPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

.preloadFont {
  font-family: 'MyriadPro', Arial;
  font-weight: normal;
  font-style: normal; }

canvas {
  font-family: 'MyriadPro', Arial;
  font-weight: normal;
  font-style: normal;
  /* For scaling */
  /* border-radius: 20px;
     width: 400px; */ }

.columnheader {
  color: #000000;
  font-size: 30px;
  line-height: 29px;
  font-family: 'HelveticaNeueLTPro-Md'; }

.sub-header {
  color: #666666;
  font-size: 18px;
  margin-top: -10px;
  padding-bottom: 50px; }

.canvas-card {
  width: 475px;
  height: 299px;
  background-size: contain;
  margin: auto;
  position: relative; }

.card-front {
  background-image: url("/images/card/Licence_Card_Front.png"); }

.card-back {
  background-image: url("/images/card/Licence_Card_Back.png"); }

.card-front-text {
  top: 230px;
  width: 200px;
  padding-left: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }

.front-barcode {
  font-family: 'MyriadPro';
  text-align: left;
  font-size: 30px;
  line-height: 1; }

.front-expiry {
  font-family: 'MyriadPro';
  display: inline-block;
  text-align: left;
  font-size: 16px; }

.back-barcode-container {
  display: flex; }

.mini-card-icons {
  width: 30px;
  padding-bottom: 3px; }

.mini-licence-tile {
  display: inline-block;
  padding-top: 5px;
  padding-right: 3px;
  padding-left: 3px;
  padding-bottom: 2px;
  border-radius: 25px 25px 5px 5px;
  width: 38px; }

.mini-icon-text {
  font-family: 'MyriadPro';
  font-size: 8px;
  color: white; }

.mini-tile-wrapper {
  position: absolute;
  bottom: 20px;
  left: 40px; }

.mini-licence-tile:nth-child(n + 2) {
  margin-left: 10px; }

.gasfitter-tile {
  background-color: #c3511d; }

.plumber-tile {
  background-color: #672e8f; }

.drainlayer-tile {
  background-color: #328b39; }

.card-profile-photo {
  position: absolute;
  width: 140px;
  left: 29px;
  top: 26px;
  border-radius: 13px;
  height: 170px;
  object-fit: cover; }

.no-photo-supplied {
  position: absolute;
  width: 120px;
  left: 40px;
  top: 50px;
  height: 128px;
  font-size: 20px;
  line-height: 40px;
  font-weight: bold; }

.disc-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 13px; }

.detail-wrapper {
  display: flex;
  height: 85%; }

.photoContainer {
  display: flex;
  width: 40%;
  padding: 25px; }

.name-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%; }
  .name-container .card-firstname {
    font-size: 1.1em;
    margin: 0px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-align: left; }
  .name-container .card-lastname {
    font-size: 1.1em;
    margin: 0px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-align: left; }

.details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 60%;
  padding: 25px 15px 0px 0px; }

.barcode-details-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: end;
  padding-bottom: 2px; }
  .barcode-details-container p {
    margin: 0px; }
  .barcode-details-container .licence-number-text {
    font-weight: bold;
    font-size: 1.3em;
    margin-bottom: -8px; }
  .barcode-details-container .expiry-text {
    font-weight: bold;
    font-style: italic;
    font-size: 0.7em; }

.barcode-container {
  margin-right: 5px; }

.licence-type-container {
  display: flex;
  margin-bottom: 5px; }
  .licence-type-container .licence-type-logo {
    margin-left: 0px;
    max-height: 25px; }
  .licence-type-container .licence-type-text {
    margin: 0px 10px; }
