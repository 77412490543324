@import '../../../../Theme/base.scss';

.exam-confirmation {
   .s5ths,
   .m5ths,
   .l5ths,
   .xl5ths {
      margin-left: auto;
      left: auto;
      right: auto;
   }

   .row .col.s5ths {
      width: 20%;
   }

   @media only screen and (min-width: 601px) {
      .row .col.m5ths {
         width: 20%;
      }
   }

   @media only screen and (min-width: 993px) {
      .row .col.l5ths {
         width: 20%;
      }
   }

   .sub-header {
      padding-bottom: 0px;
   }

   .sub-header-small {
      margin-bottom: -15px;
   }

   .total-table .total-cell {
      padding-right: 25px;
   }
   
   .total-cell {
      width: 9.5%;
      padding-right: 35px;
   }
}
