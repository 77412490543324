.exam .customHr {
  border-top: 1px solid lightgray;
  padding-top: 10px; }

.exam .thickerLine {
  border-bottom: 3px solid gray; }

.exam .removeBorder {
  border-bottom: 0; }

.exam .grey-text {
  color: gray !important; }

.exam .exam-link-button {
  background-color: transparent;
  color: #039be5 !important;
  cursor: pointer;
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
  -webkit-tap-highlight-color: transparent; }

.exam .generic-table tr:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.exam .eligibility-report .eligibility-course-list .eligibility-course-name:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

.exam .eligibility-report .eligibility-course-list .eligibility-course-name {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
  padding: 15px; }

.exam .eligibility-report .control-group {
  padding: 10px;
  margin-bottom: 20px; }
  .exam .eligibility-report .control-group .btn {
    margin-right: 20px;
    margin-bottom: 10px; }
