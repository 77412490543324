@import '../../../Theme/base.scss';

.registration-container {
   .collapsible-header {
      background-color: inherit;
      border-bottom: none;
      display: inherit;
      padding: 0;
      i {
         position: absolute;
         right: 25px;
         top: 38px;
      }
      .header-column {
         position: relative;
      }
      .row {
         margin-bottom: 0;
      }
   }

   .collapsible-body {
      padding: inherit;
      border-bottom: none;
   }

   .collapsible {
      border: none;
      margin: inherit;
      box-shadow: none;
      -webkit-box-shadow: none;
      .header-column {
         i.add-icon {
            display: inherit;
         }
         i.remove-icon {
            display: none;
         }
      }
      .active {
         .header-column {
            i.add-icon {
               display: none;
            }
            i.remove-icon {
               display: inherit;
            }
         }
         .collapsible-body {
            display: block;
         }
      }
   }

   .registration-trade-header {
      h2 {
         color: $pgdb-darkpurple;
         margin: 20px 10px;
      }

      background: $pgdb-white;
      text-align: center;
      margin-top: 20px;
      padding: 15px;
      border-radius: 50px;
   }

   .row.no-margin {
      margin-bottom: 0;
   }

   .trade-row {
      &.col.s2 {
         margin-left: 0;
         margin-right: 5px;
         padding-left: 0;
         padding-right: 0;
      }
      .application-section {
         display: table;
         min-height: 334px;
         .row.complete {
            display: table-cell;
            vertical-align: bottom;
         }
      }
   }

   .registration-row {
      position: relative;
      .certifier-complete {
         position: absolute;
         bottom: 0;
      }
   }

   .trade-box {
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      display: inline-block;
      width: 28%;
      vertical-align: text-bottom;
      margin-right: 3px;
      position: relative;

      &.completed {
         &.P {
            background-image: linear-gradient($pgdb-purple, $pgdb-darkpurple);
         }
         &.G {
            background-image: linear-gradient($pgdb-orange, $pgdb-darkorange);
         }
         &.D {
            background-image: linear-gradient(
               $pgdb-lightgreen,
               $pgdb-darkgreen
            );
         }

         p {
            color: $pgdb-white;
         }
         img {
            margin: auto;
            left: 0;
            right: 0;
            position: absolute;
            top: -100%;
            height: 100%;
            max-height: 100px;
            width: 100%;
            max-width: 100px;
         }
      }
      &.pending {
         background-color: $pgdb-white;
         img {
            display: none;
         }
      }
      &.suspended {
         background-image: linear-gradient($pgdb-grey, $pgdb-darkgrey);
         p {
            color: $pgdb-white;
         }
         img {
            display: none;
         }
      }
      &.journeyman {
         padding-top: 40px;
         padding-bottom: 10px;
         margin-top: 210px;
         img {
            top: -100px;
         }
         img.trophy-stars {
            top: -125px;
         }
         &.pending {
            padding-top: 77px;
         }
      }
      &.tradesman {
         padding-top: 100px;
         padding-bottom: 10px;
         img {
            top: -100px;
         }

         img.trophy-stars {
            top: -125px;
         }
         &.pending {
            padding-top: 117px;
         }
      }
      &.certifying {
         padding-top: 160px;
         padding-bottom: 10px;
         img {
            display: none;
         }
         &.pending {
            padding-top: 177px;
         }
      }

      img.discipline-img {
         position: absolute;
         width: 25%;
         max-width: 25px;
         margin: 0 auto;
         left: 0;
         right: 0;
         top: -115px;
      }

      .trade-level {
         font-size: 10px;
         line-height: 14px;
         text-transform: capitalize;
      }

      .trade-status {
         font-size: 11px;
         line-height: 17px;
         font-weight: bold;
         text-transform: uppercase;

         &.completed {
            color: $pgdb-white;
         }

         &.pending {
            color: $pgdb-green;
         }

         &.not-available {
            color: $pgdb-red;
         }
      }

      p {
         color: $pgdb-black;
         text-align: center;
         vertical-align: text-bottom;
      }
   }

   .trade-heading {
      font-size: 14px;
      line-height: 17px;
      font-weight: bold;
   }

   .trade-name {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 19px;
      letter-spacing: 0;
   }

   .trophy-img-section {
      position: relative;
   }

   .application-name {
      font-weight: bolder;
      font-size: 20px;
   }

   .application-heading {
      font-weight: bold;
   }

   .application-condition {
      p {
         margin-top: 5px;
      }
      .row {
         margin-bottom: 0;
      }
      i {
         text-align: center;
         width: 100%;
         &.smaller {
            font-size: 8px;
            margin-top: 11px;
         }
      }
   }

   .certifier-suspended {
      text-align: center;
      background: $pgdb-white;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      padding-bottom: 5px;
      padding-top: 80px;

      h3 {
         padding: 0px 20px 80px 20px;
      }
   }

   .certifier-complete {
      text-align: center;
      background: $pgdb-white;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      padding-bottom: 5px;

      .trophy-img {
         max-width: 45%;
      }

      .discipline-img {
         position: absolute;
         width: 15%;
         margin: 0 auto;
         left: 0;
         right: 0;
         top: 20%;
      }

      h3 {
         color: $pgdb-darkpurple;
         font-weight: bold;
         line-height: 50px;
      }
      p {
         margin: 0 auto;
         font-size: 12px;
         color: $pgdb-darkpurple;
      }
      .registration-congratulations-text {
         font-weight: bold;
      }
   }

   .trade-splitter {
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      top: 100px;
      font-size: 20px;
   }

   .trade-subtitle,
   .apply-now-btn,
   .invalid-registration-title {
      text-align: center;
   }

   .fit-text {
      margin-top: 20px;
      text-align: right;
      font-style: italic;
      font-size: 12px;
      .btn-small {
         padding: 10px 25px;
         font-size: 12px;
      }
   }
}

.fit-modal-body {
   padding: 0 30px 5px 30px;
   p {
      text-align: left;
   }
}

.fit-model-icon {
   cursor: pointer;
   margin: 10px 10px 0 0;
}
