@import '../../Theme/base.scss';

.loading-wrapper-main {
   position: absolute;
   top: 25%;
   left: 50%;
   -moz-transform: translateX(-50%);
   -webkit-transform: translateX(-50%);
   -o-transform: translateX(-50%);
   -ms-transform: translateX(-50%);

   .loading-wrapper-content {
      display: flex;
      flex-direction: column;
   }

   .loading-wrapper-oops-text {
      padding-bottom: 10px;
      color: $pgdb-red;
   }
}

.loading-overlay-container {
   position: relative;
   width: 100%;
   height: 100%;

   .loading-overlay-animator {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(244, 244, 244, 0.7);
      background-image: url('/images/loading.svg');
      background-repeat: no-repeat;
      background-position-x: 50%;
      background-position-y: 50%;
      z-index: 999;
   }
}

.loading-fade-in {
   animation: 0.4s linear 0s 1 fadeIn;
}

@keyframes fadeIn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}
