@import '../../Theme/base.scss';

#modal-footer .btn-outline {
    background-color: transparent;
    color: $pgdb-activeblue;
    border: solid 2px $pgdb-activeblue;
  }
  
  .modal-footer {
    padding-bottom: 50px;
    a {
      margin: 0 15px;
    }
  }
  
  .modal-content-padding {
    padding: 55px;
    padding-bottom: 0;
  }
  
  .modal-content-line-height {
    line-height: 1.2;
  }
  
  .generic-modal {
    z-index: 1003;
    top: 10%;
    width: 500px;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    background-color: #fafafa;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  }
  
  .generic-modal-overlay {
    z-index: 1002;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .button-margin {
    margin: 0 10px;
  }
  
  .left-section {
    margin-left: 25%;
    padding-bottom: 10px;
  }
  
  .radio-align-left {
    text-align: left;
    padding-bottom: 20px;
  }