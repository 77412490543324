@import '../../Theme/base.scss';

.radio-groups {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 10px;
    border-bottom: solid 1px $pgdb-grey;
    width: 100%;

    .radio-label {
        display: flex;
        margin-bottom: 10px;
    }

    .radio-option {
        display: flex;
    }

    .radio-display {
        display: flex;
    }

    @media (max-width: 1350px) and (min-width:993x) {
        .registration-type-radio.radio-display{
            flex-direction:column;
            flex-wrap:wrap;
            justify-content: flex-start;
        }
        .radio-option{
            padding-bottom:20px;
        }
    }

    @media (max-width: 700px) {
        .registration-type-radio.radio-display{
            flex-direction:column;
            flex-wrap:wrap;
            justify-content: flex-start;
        }
        .radio-option{
            padding-bottom:20px;
        }
    }

    .radio-option-label {
        padding-right: 12px;
    }

    .label-icon {
        margin-right: 10px;
    }

    @media (min-width: 1375px) {
        .registration-fit-and-proper-radio-buttons{
            display:flex;
            flex-direction: row;
        }
    }

    @media (max-width: 1375px) {
        .registration-fit-and-proper-radio-options{
            padding-left: 17px;
        }
    }

    .registration-fit-and-proper-question-text{
        padding-right: 30px;
    }

    .registration-fit-and-proper-radio-buttons{
        margin-bottom: 30px;
    }
   
}