@import '../../Theme/base.scss';

.payment-column {
    font-size: 14px;
    color: $pgdb-darkgrey;
  }
  
  .receipt-item-first, .receipt-item-middle {
    border-bottom: none;
  }
  
  .receipt-item-first > td, .receipt-item-middle > td {
    padding-bottom: 7.5px;
  }
  
  .receipt-item-last > td, .receipt-item-middle > td {
    padding-top: 6px;
  }