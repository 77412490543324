@import '../../Theme/base.scss';

.country-multiselect {
    .country-selector {
        .country-select-header {
            display: block;
            margin: 20px 0 6px 20px;
        }
        .country-dropdown {
            display: inline-flex;
            padding-left: 18px;
        }
    }

    .countries-selected-list {
        padding: 5px;
        margin: 10px;
        .country-item {
            display: inline-block;
            padding: 10px;
            padding-left: 15px;
            margin-top: 5px;
            margin-left: 5px;
            border-radius: 28px;
            background-color: $pgdb-white;
            color: $pgdb-black;
            min-width: 100px;
            font-weight: 200;

            .delete-button {
                cursor: pointer;
                margin-left: 25px;
            }
        }
    }
}

.country-add-button {
    min-width: 128px;
}