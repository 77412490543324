@import '../../Theme/base.scss';

.callout {
    display: block;
    margin: 1.5rem 1rem;
  }
  
  .callout-body {
    text-align: left;
    border-radius: 15px;
    background-color: #fff;
    padding: 20px;
    position: relative;
    &:before {
      content: '';
      width: 0px;
      height: 0px;
      position: absolute;
      border-left: 15px solid transparent;
      border-right: 15px solid #fff;
      border-top: 15px solid #fff;
      border-bottom: 15px solid transparent;
      left: -16px;
      top: 0px;
      margin: 0;
    }
  }
  
  @media (max-width: 992px) {
    .callout-profile-img {
      float: left;
    }
    .callout-body {
      left: 30px;
    }
  }
  
  @media (min-width: 993px) {
    .callout-profile-img {
      float: left;
      margin-left: -80px;
    }
  }