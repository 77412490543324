/*
  This file specifies css styles that are meant to override the base styles provided by materialize.
  The selectors here are duplicated from the materialize library, and are used to override the base styles
  to the ones as per design.

  The selectors are big and cumbersome because thats what materialize uses, and we are using them to ensure
  we are overriding the right elements.

  If you are implementing new styles, and wish to override these base styles, you can use a class with a high
  specificity like the following:

  #root .text-input {
  }

  For more information:
  https://developer.mozilla.org/en-US/docs/Web/CSS/Specificity
  https://specificity.keegan.st/
*/

input:not([type]),
input[type='text']:not(.browser-default),
input[type='password']:not(.browser-default),
input[type='email']:not(.browser-default),
input[type='url']:not(.browser-default),
input[type='time']:not(.browser-default),
input[type='date']:not(.browser-default),
input[type='datetime']:not(.browser-default),
input[type='datetime-local']:not(.browser-default),
input[type='tel']:not(.browser-default),
input[type='number']:not(.browser-default),
input[type='search']:not(.browser-default),
textarea.materialize-textarea,
select {
   border: 1px solid var(--pgdb-grey);
   background-color: #ffffff;
   text-align: left;
   border-radius: 6px;
   padding: 15px;
   color: #666666;
   margin: 0;
   height: 56px;
   box-sizing: border-box;
   font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light',
      'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
   font-size: 100%;
   transition: none;
}

select {
   border: 1px solid var(--pgdb-darkgrey);
}

input:not([type]):disabled,
input:not([type])[readonly='readonly'],
input[type='text']:not(.browser-default):disabled,
input[type='text']:not(.browser-default)[readonly='readonly'],
input[type='password']:not(.browser-default):disabled,
input[type='password']:not(.browser-default)[readonly='readonly'],
input[type='email']:not(.browser-default):disabled,
input[type='email']:not(.browser-default)[readonly='readonly'],
input[type='url']:not(.browser-default):disabled,
input[type='url']:not(.browser-default)[readonly='readonly'],
input[type='time']:not(.browser-default):disabled,
input[type='time']:not(.browser-default)[readonly='readonly'],
input[type='date']:not(.browser-default):disabled,
input[type='date']:not(.browser-default)[readonly='readonly'],
input[type='datetime']:not(.browser-default):disabled,
input[type='datetime']:not(.browser-default)[readonly='readonly'],
input[type='datetime-local']:not(.browser-default):disabled,
input[type='datetime-local']:not(.browser-default)[readonly='readonly'],
input[type='tel']:not(.browser-default):disabled,
input[type='tel']:not(.browser-default)[readonly='readonly'],
input[type='number']:not(.browser-default):disabled,
input[type='number']:not(.browser-default)[readonly='readonly'],
input[type='search']:not(.browser-default):disabled,
input[type='search']:not(.browser-default)[readonly='readonly'],
textarea.materialize-textarea:disabled,
textarea.materialize-textarea[readonly='readonly'] {
   border-bottom: 1px solid #666666;
   color: #c4c4c4;
}

input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
   border: 1px solid #00afdb;
   box-shadow: none;
}

#toast-container {
   top: auto !important;
   bottom: 2%;
   width: 20em;
   right: 0;
   left: 0;
   margin-right: auto;
   margin-left: auto;
}

#toast-container .toast.success {
   background-color: #26a69a;
}

#toast-container .toast.error {
   background-color: #bb2b2b;
}
