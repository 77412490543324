// Base style colors
$pgdb-blue: #00539f;
$pgdb-lightblue: #a0cae4;
$pgdb-black: #000000;
$pgdb-darkgrey: #666666;
$pgdb-grey: #c4c4c4;
$pgdb-lightgrey: #e4e4e4;
$pgdb-verylightgrey: #f5f5f5;
$pgdb-purple: #9561a8;
$pgdb-darkpurple: #03115c;
$pgdb-orange: #f78f1e;
$pgdb-darkorange: #c3511d;
$pgdb-lightgreen: #8cc63f;
$pgdb-green: #8cc63f;
$pgdb-darkgreen: #328b39;
$pgdb-red: #ee3224;
$pgdb-activeblue: #00afdb;
$pgdb-white: #ffffff;
$pgdb-black-2: #08080d;
$pgdb-blue-2: #0058a8;
$border-light: #d6d6d6;
