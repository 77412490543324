.generic-table {
   margin-top: 20px;
   .supervisor-body {
      .table-heading {
         border-top: 0;
      }
   }
}

.table-data {
   padding: 15px 0;
}

.inline-icon {
   vertical-align: bottom;
   margin-right: 5px;
   float: left;
}

.height-adjust {
   margin-top: 2px;
}

.block-text {
   display: block;
}

.status-cell {
   max-width: 300px;
}

.my-supervisor-text{
   padding-bottom: 10px;
}

.second-line {
   margin-left: 29px;
   margin-right:10px;
}

.supervision-search-text {
   margin-left: 20px;
}

.table-text-box {
   display: flex;
   align-items: center;
}

.supervisor-body {
   .table-text-box {
      input {
         min-width: 75px;
      }
   }
}
