.company-root .generic-table .main-row {
  font-weight: bold; }

.company-root .generic-table .supervisor-cell {
  width: 300px; }

.company-root .generic-table .name-cell {
  width: 150px; }

.company-root .generic-table .currency-cell {
  padding: 0; }

.company-root .generic-table tr:first-child {
  border-bottom: none; }

.company-root .company-section .col {
  padding-left: 0px; }

.company-root .total-table td {
  font-weight: bold;
  text-align: right; }

.company-root .total-table .total-cell {
  width: 10%;
  padding-right: 35px; }

.company-root .total-table .currency-cell {
  width: 20px; }

.company-root .total-table .total-label-cell {
  width: 50%; }

.company-root .drawer {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  font-family: 'HelveticaNeueLTPro-Bd'; }
  .company-root .drawer .mock-collapsible-header {
    padding: 15px; }
  .company-root .drawer li {
    display: inline; }
    .company-root .drawer li .drawer-title {
      background-color: inherit;
      border: none;
      display: flex;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000; }
      .company-root .drawer li .drawer-title .header-title {
        margin-right: auto;
        font-weight: bold; }
      .company-root .drawer li .drawer-title .edit-btn {
        color: #00afdb;
        color: #00afdb;
        font-size: 14px;
        display: flex;
        align-items: center;
        margin: 0 20px; }
      .company-root .drawer li .drawer-title .caret {
        margin-right: 0;
        transition: transform 0.2s ease; }
    .company-root .drawer li.active .drawer-title .caret {
      transform: rotate(-180deg);
      transform-origin: 0.6em 0.5em; }
    .company-root .drawer li .default-cursor {
      cursor: auto; }
    .company-root .drawer li .drawer-outer-body {
      padding: 0rem;
      border: none; }
      .company-root .drawer li .drawer-outer-body .drawer-inner-body {
        padding: 0rem 2rem; }
        .company-root .drawer li .drawer-outer-body .drawer-inner-body .licence-number-cell {
          width: 150px; }
        .company-root .drawer li .drawer-outer-body .drawer-inner-body .cpd-cell {
          padding-left: 150px; }
        .company-root .drawer li .drawer-outer-body .drawer-inner-body .shorter {
          margin-bottom: 5px; }
        .company-root .drawer li .drawer-outer-body .drawer-inner-body .subtitle {
          font-size: 14px;
          font-family: 'HelveticaNeueLTPro-Roman';
          color: #c4c4c4;
          width: 150px; }
        .company-root .drawer li .drawer-outer-body .drawer-inner-body .description {
          font-size: 16px;
          font-family: 'HelveticaNeueLTPro-Md'; }
        .company-root .drawer li .drawer-outer-body .drawer-inner-body .terms-and-cond {
          line-height: 1.2rem;
          font-size: 12px; }
    .company-root .drawer li .table-container {
      font-family: 'HelveticaNeueLTPro-Roman'; }
      .company-root .drawer li .table-container .row {
        margin-bottom: 0; }
  .company-root .drawer .licence-table tr,
  .company-root .drawer .licence-table th {
    border: none; }
  .company-root .drawer .licence-table .amount-header {
    text-align: right; }
  .company-root .drawer .licence-table .amount-cell {
    text-align: right;
    padding-right: 5px;
    width: 6%; }
