@import '../../Theme/base.scss';

.model-dialog {
    display: block;
    position: fixed;
    font-size: 16px;
    background-color: white;
    color: black;
    color: $pgdb-black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-top-left-radius: 0.3rem;
    padding: 15px;
    z-index: 1003;
    background-color: #fafafa;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  }
  
  .model-dialog-overlay {
    z-index: 1002;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .model-dialog-footer {
    padding-top: 50px;
    text-align: center;
  }
  
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }