@import '../../../Theme/base.scss';

.upload-document-container{
   display: flex;
   flex-direction: row;
   justify-content: space-between;

   .document-type-container {
      .section-title {
         font-weight: bold;
      }

      .info-icon {
         cursor: pointer;
         color: $pgdb-darkgrey;
         float: left;
      }
   }

   .tool-tip-container {
      .close-icon {
         margin-top: -15px;
         margin-right: -15px;
         cursor: pointer;;
      }
      width: 80%;
      max-width: 450px;
      padding: 25px;
      background-color: white;
      position: absolute;
      // margin-left: 200px;
      z-index: 1004;
      .innter-text {
         margin-top: 10px;
         color: $pgdb-darkgrey;
      }
   }
}

.reg-application-uploaddoc-button {
   button.btn {
      display: flex;
      align-items: center;
      padding-top: 28px;
      padding-bottom: 28px;
   }
}

.upload-document-subheading {
   color: grey;
}

.upload-section {

   .border-red {
      border: solid 1px $pgdb-red;
      color: $pgdb-red;
   }

   .invalid-file {
      margin-top: -5px;
      margin-left: 5px;
      margin-right: 5px;
      padding: 5px;
      background-color: $pgdb-red;
      color: $pgdb-white;
   }
   .uploaded-files {
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 30px;

      .uploaded-file-item {
         padding: 2px;
         height: 50px;
         margin: 5px;
         background-color: $pgdb-lightgrey;
         display: flex;
         justify-content: space-between;
         
         .uploaded-file-icon-and-name{
            padding-top:4px;
            display:flex;
            align-items:center;
            max-width: 70%;    

         }

         .attach-icon {
            margin-right: 15px;
         }

         .delete-button {
            margin-top: 10px;
            margin-right: 10px;
         }
         .delete-button:hover {
            color: $pgdb-activeblue;
         }

         .disabled {
            color:  $pgdb-grey;
         }

         .file-name {
            cursor: pointer;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
         }

         .file-name:hover {
            font-weight: bold;
         }

         .uploaded-file-delete {
            cursor: pointer;
         }

         .loading-button {
            margin-top: 10px;
            height: 45px;
            width: 45px;
            background-color: transparent;
            border: none;
         }

         .uploaded-file-review {
            cursor: default;
         }
      }
   }
}