@import '../../Theme/base.scss';

.filepond--root{
   height: unset !important;
   display: flex;
}

.filepond--browser {
   display: none;
 }

 .filepond--data{
   display: none;
 }

 .filepond--drop-label{
   label{
      font-size: 1em;
      font-weight: bold;
   }
 }

.filepond--list-scroller{
   display: none;
}

.hideContainer{
   display: none;
}

.upload-document-container{
    margin: 25px 0px;
 }
 
 .upload-document-button {
    cursor: pointer;
    border: none;
    text-decoration: none;
    color: grey;
    background-color: #f4f4f4;
    letter-spacing: .5px;
    padding: 15px;
    border-radius: 28px;
    line-height: 16px;
    font-size: 12px;
    text-transform: none;
    float: right;
    border: solid 2px grey;
    min-height: 100%;
 }

 .upload-document-button:focus{
   cursor: pointer;
   background-color: #f4f4f4;
 }
 
 .upload-document-button:hover{
   background-color: $pgdb-grey;
 }