@import '../../Theme/base.scss';

.columnheader {
   color: $pgdb-black;
   font-size: 30px;
   line-height: 29px;
   font-family: 'HelveticaNeueLTPro-Md';
}

.sub-header {
   color: $pgdb-darkgrey;
   font-size: 18px;
   margin-top: -10px;
   padding-bottom: 50px;
}

.licence-modal {
   background-color: transparent;
   -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
   box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
