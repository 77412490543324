body {
   margin: 0;
   padding: 0;
   font-family: sans-serif;
   overflow-y: scroll;
}

// fix to get the sticky footer
#root,
.ai-container {
   display: flex;
   min-height: 100vh;
   flex-direction: column;
}
