@font-face {
  font-family: 'Material-Icons-Round';
  src: url("./../fonts/Material-Icons-Round.ttf") format("truetype"); }

@font-face {
  font-family: 'HelveticaNeueLTPro-Roman';
  src: url("./../fonts/38CC3B_0_0.eot");
  src: url("./../fonts/38CC3B_0_0.eot?#iefix") format("embedded-opentype"), url("./../fonts/38CC3B_0_0.woff2") format("woff2"), url("./../fonts/38CC3B_0_0.woff") format("woff"), url("./../fonts/38CC3B_0_0.ttf") format("truetype"); }

@font-face {
  font-family: 'HelveticaNeueLTPro-Md';
  src: url("./../fonts/38CC3B_1_0.eot");
  src: url("./../fonts/38CC3B_1_0.eot?#iefix") format("embedded-opentype"), url("./../fonts/38CC3B_1_0.woff2") format("woff2"), url("./../fonts/38CC3B_1_0.woff") format("woff"), url("./../fonts/38CC3B_1_0.ttf") format("truetype"); }

@font-face {
  font-family: 'HelveticaNeueLTPro-Bd';
  src: url("./../fonts/38CC3B_2_0.eot");
  src: url("./../fonts/38CC3B_2_0.eot?#iefix") format("embedded-opentype"), url("./../fonts/38CC3B_2_0.woff2") format("woff2"), url("./../fonts/38CC3B_2_0.woff") format("woff"), url("./../fonts/38CC3B_2_0.ttf") format("truetype"); }

i.round {
  font-family: 'Material-Icons-Round'; }

body {
  background-color: #f4f4f4;
  display: -webkit-flex !important;
  display: flex !important;
  min-height: 100vh !important;
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
  font-family: 'HelveticaNeueLTPro-Roman' !important; }

* {
  font-family: 'HelveticaNeueLTPro-Roman'; }

main {
  -webkit-flex: 1 0 auto !important;
          flex: 1 0 auto !important; }

.title-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.license-application-container {
  margin: 0px 20px 0px 20px; }

.licence-application-banner {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  background-image: -webkit-linear-gradient(#0058a8, #03115c);
  background-image: linear-gradient(#0058a8, #03115c);
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: center;
          align-self: center;
  border-radius: 15px;
  padding: 5px; }

.licence-application-title {
  margin-top: 20px;
  color: white;
  padding-left: 10px;
  padding-right: 10px; }

.licence-application-button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  border: none;
  outline: 0;
  text-decoration: none;
  color: #fff;
  padding: 15px 45px 15px 45px;
  border-radius: 28px;
  background-color: #00afdb;
  font-family: 'HelveticaNeueLTPro-Md';
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }

.btn,
.btn-large,
.btn-small {
  padding-top: 20px;
  padding-bottom: 35px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 28px;
  background-color: #00afdb;
  text-align: center;
  font-family: 'HelveticaNeueLTPro-Md';
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
  text-transform: none; }

.btn:hover,
.btn-large:hover,
.btn-small:hover,
.btn:focus,
.btn-large:focus,
.btn-small:focus {
  background-color: #00afdb; }

.btn.btn-inactive {
  background-color: #f5f5f5;
  color: #00afdb;
  border-width: 2px;
  border-style: solid;
  padding: 18px 28px 33px 28px;
  /* removes 2px to account for the border */ }
  .btn.btn-inactive:hover, .btn.btn-inactive:focus {
    background-color: #f5f5f5;
    border-width: 2px;
    border-style: solid; }

.registration-application-button {
  border: none;
  outline: 0;
  text-decoration: none;
  color: #fff;
  letter-spacing: .5px;
  padding: 15px;
  border-radius: 28px;
  background-color: #00afdb;
  text-align: center;
  font-family: 'HelveticaNeueLTPro-Md';
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  float: right;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  min-height: 100%; }

.registration-application-button:focus {
  background-color: #00afdb; }

.vline,
hr.vertical {
  border: none;
  border-right: 1px solid #c4c4c4;
  height: 438px;
  width: 1px; }

::-ms-clear {
  /* Stops Edge from displaying the X in edited input fields*/
  display: none; }

.input-header {
  margin: 20px 0 6px 20px;
  display: inline-block; }

.no-bottom-margin {
  margin-bottom: 0; }

/**
      TODO: end
  **/
.grey2-text {
  color: #666666; }

.grey2-Note-text {
  color: #666666;
  font-style: italic; }

.smaller {
  font-size: 12px; }

.small {
  font-size: 14px; }

.medium-text {
  font-size: 18px; }

.far-left {
  /* left: -250px; */
  padding: 0;
  padding-top: 5px !important;
  padding-left: 40px; }

@media (min-width: 993px) {
  .logo {
    max-height: 30px;
    margin-left: 40px; } }

@media (max-width: 992px) {
  .logo {
    max-height: 30px; } }

.link-underline {
  /* border-bottom: 1px solid #039be5 !important; */ }

/* Navbar */
nav {
  height: 80px !important;
  line-height: 80px !important; }
  nav .button-collapse i {
    height: 80px !important;
    line-height: 80px !important; }
  nav .brand-logo {
    font-size: 1.6rem; }

.dashboard-panels {
  box-sizing: border-box;
  height: 301px;
  border: 1px solid #f0f3f4;
  border-radius: 10px !important;
  background-color: #ffffff; }

.card-layout.disabled {
  display: none; }

.card-header {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  text-align: center; }

.card-line {
  box-sizing: border-box;
  height: 1px;
  width: 16px;
  border: 2px solid;
  border-radius: 100px !important;
  margin: auto;
  margin-bottom: 20px; }

.notification-icon {
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 50%;
  display: inline-block;
  border: 7px solid #ee3224; }

.arrow {
  line-height: 20px;
  padding-right: 20px;
  font-size: 20px; }

.dashboard-link-header {
  font-weight: bold;
  font-size: 18px;
  padding: 15px 5px;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px; }

.loading-screen-user-message {
  z-index: 10000000002;
  position: absolute;
  top: 54%;
  width: 100%;
  padding: 10px;
  text-align: center; }

@media (max-width: 992px) {
  .loading-screen-user-message {
    font-size: smaller; }
    .loading-screen-user-message h3,
    .loading-screen-user-message h4 {
      font-size: small; } }

.dashboard-special-user-message {
  color: #f78f1e;
  text-align: center;
  padding: 10px 100px; }

.modal-easter {
  width: 40% !important;
  height: 60% !important; }

.sub-text {
  margin-top: -20px;
  font-size: 18px;
  display: inline-block; }

.bold {
  font-weight: bold; }

.helvetica-bold {
  font-family: 'HelveticaNeueLTPro-Bd'; }

.exam-header {
  border-top: 1px solid #d6d6d6;
  font-size: 12px;
  line-height: 14px;
  font-weight: 100;
  padding: 40px 0px 10px 40px; }

.table-heading {
  border-top: 1px solid #d6d6d6;
  font-size: 12px;
  line-height: 14px;
  font-weight: 100; }

.table-header {
  border-top: 1px solid #d6d6d6;
  font-size: 12px;
  line-height: 14px;
  font-weight: 100;
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px; }

.table-item {
  margin-top: 0;
  margin-bottom: 0; }

.table-text-box {
  max-width: 210px; }

.table-set-size {
  width: 400px; }

.checkbox-blue[type='checkbox'].filled-in:checked + label:after {
  border: 2px solid #ff9800 !important;
  background-color: #ff9800 !important; }

.input-center {
  width: 120% !important;
  text-align: center !important; }

.footer-copyright {
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c4c4c4;
  padding-left: 0px !important; }

.footer-menu {
  opacity: 0.7;
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666666;
  padding-top: 25px !important;
  margin-bottom: -20px !important; }

footer {
  padding-top: 70px !important;
  padding-bottom: 30px !important; }
  footer div > div > div > p {
    font-size: 14px !important; }

.grey-text {
  color: #c4c4c4; }

.darkgrey-text {
  color: #666666; }

.activeblue-text {
  color: #00afdb; }

.medium-font {
  font-family: 'HelveticaNeueLTPro-Md'; }

.medium-font-size {
  font-size: 16px; }

.position-absolute {
  position: absolute; }

.disable-wordwrap {
  white-space: nowrap; }

.inner-page-container {
  max-width: 800px;
  margin: auto; }

.text-align-left {
  text-align: left; }

.icon-left-pad {
  padding-left: 29px;
  /* icon=24px, existing padding=5px */ }

.no-left-pad {
  padding-left: 0; }

.registration-payment-table {
  font-size: 14px; }

.registration-payment-font-size {
  font-size: 14px !important; }

.small-right-pad {
  padding-right: 10px; }

/* ------------------------------- */
/* Text styles */
.H8 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 50px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h1 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MH8 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h2 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h3 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h4 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MH1 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h5 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h6 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.H7 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body1 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body2 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body3 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body4 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body5 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body6 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody4 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody5 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody6 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body7 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body8 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body9 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody10 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody11 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody12 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody13 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body10 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody15 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 8px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

/* ------------------------------- */
.title-bold {
  object-fit: contain;
  font-family: 'HelveticaNeueLTPro-Md';
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-left: -5px !important; }

.sub-title {
  object-fit: contain;
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #666666; }

a.large {
  object-fit: contain;
  opacity: 0.7;
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #00afdb; }

p.medium {
  object-fit: contain;
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #666666; }

p.footer {
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666666; }

.input-field > label:not(.label-icon).active {
  object-fit: contain !important;
  font-family: 'HelveticaNeueLTPro-Roman' !important;
  font-size: 17px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #666666 !important; }

#root .input-invalid {
  border: 1px solid #ee3224; }

nav ul a.active {
  color: #00afdb !important;
  border-bottom: 2px solid #00afdb; }

/* -- underline -- */
.link {
  font-family: 'HelveticaNeueLTPro-Md' !important;
  text-decoration: none;
  position: relative;
  color: #00afdb !important;
  padding-bottom: 4px; }
  .link:after {
    content: '';
    height: 1px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0%;
    background: #00afdb;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease; }
  .link:hover:after {
    width: 100%;
    left: 0; }

/* ---------------- */
#dropdown1 li > * {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* line-height: 20px !important; */
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 14px;
  color: #666666; }

#dropdown1 li:first-child {
  margin-top: 20px; }

#dropdown1 li:last-child {
  margin-top: 20px;
  margin-bottom: 20px; }

.text-red {
  color: #ee3224; }

/* @media only screen and (min-width: 601px)
  nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
      height: 20px !important;
      line-height: 20px !important;
  } */
.licence-padding {
  margin-top: 40px !important;
  padding-bottom: 30px !important; }

.password-padding {
  padding-bottom: 19px !important; }

.login-btn {
  margin-top: 10px !important; }

.company-padding {
  margin-top: 27px !important; }

.alert {
  padding: 0px !important; }

.alert_box {
  border-radius: 6px !important; }

/* Address Finder Result Box */
ul.af_list {
  z-index: 5;
  list-style: none;
  margin: 0;
  border: solid 1px #999;
  border-radius: 5px;
  background: #fff;
  font-size: 1em; }

li.af_item {
  cursor: pointer;
  padding: 0.5em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

li.af_hover {
  background-color: #0058a8;
  color: #fff; }

li.af_footer {
  font-size: 0.8em;
  color: #666;
  text-align: right;
  display: none; }

.cursor-pointer {
  cursor: pointer; }

.manual-address-link {
  float: right;
  cursor: pointer;
  padding-top: 0.5em; }

.manual-address-fields {
  padding: 0.5em 1em 1.5em 1em;
  background-color: #e8e8e8; }

.af_empty {
  padding: 5px; }

.btn-small.manual-save {
  margin-top: 1em; }

.btn-small.manual-cancel {
  margin-top: 1em;
  margin-left: 0.5em; }

.btn-ml-30 {
  margin-left: 30px; }

button.btn-link {
  background: none;
  border-radius: 5px;
  color: #00afdb;
  font-weight: 500;
  font-family: 'HelveticaNeueLTPro-Md';
  text-transform: none; }

.button-colour-invert {
  background: none;
  border: solid 1px #00afdb;
  color: #00afdb;
  box-shadow: none; }
  .button-colour-invert:hover {
    color: white; }

select.select-input {
  outline: 0;
  /* removes border when selecting */
  border: 1px solid #c4c4c4; }

select.select-highlighted, select:focus, select:active {
  border: 3px solid #00afdb;
  padding: 13px; }

option {
  font-size: 16px; }

#root input[type='text'].text-input:focus, #root input[type='text'].text-input:active {
  border: 3px solid #00afdb;
  box-shadow: none;
  padding: 13px; }

#root .text-input {
  border: 1px solid #c4c4c4; }

input[type='text'].input-disabled:disabled {
  background-color: #ddd;
  border: 1px solid #666666;
  padding: 15px; }
  input[type='text'].input-disabled:disabled::-webkit-input-placeholder {
    color: #000; }
  input[type='text'].input-disabled:disabled::-ms-input-placeholder {
    color: #000; }
  input[type='text'].input-disabled:disabled::placeholder {
    color: #000; }

.inline-text {
  display: inline-block; }

.subheader {
  font-family: 'HelveticaNeueLTPro-Md'; }

.invalid-message {
  color: #ee3224; }

.input-label-horizontal {
  font-size: 100%;
  margin-left: 20px; }

.hide-element {
  display: none; }

/* Checkbox */
[type='checkbox'] + span:not(.lever) {
  height: 20px; }

[type='checkbox'].filled-in + span:not(.lever):after {
  border-radius: 3px; }

[type='checkbox'].filled-in:checked + span:not(.lever):after {
  border: 2px solid #00afdb;
  background-color: #00afdb;
  width: 24px;
  height: 24px; }

[type='checkbox'].filled-in:not(:checked) + span:not(.lever):after {
  height: 24px;
  width: 24px;
  border: 2px solid #c4c4c4; }

[type='checkbox'].filled-in:checked + span:not(.lever):before {
  width: 10px;
  height: 16px; }

[type='radio']:checked + span:after {
  border: 2px solid #00afdb; }

[type='radio'].with-gap:checked + span:before, [type='radio'].with-gap:checked + span:after {
  border: 2px solid #00afdb; }

[type='radio']:checked + span:after,
[type='radio'].with-gap:checked + span:after {
  background-color: #00afdb; }

[type='radio'] + span:before, [type='radio'] + span:after {
  width: 24px;
  height: 24px; }

[type='radio']:not(:checked) + span,
[type='radio']:checked + span {
  line-height: 30px; }

.input-error {
  max-width: 210px;
  background-color: #ee3224;
  z-index: 20;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 8px;
  font-size: 14px;
  color: #fff; }

.input-error:after {
  content: '';
  position: absolute;
  top: 0;
  right: 70%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #ee3224;
  margin-top: -20px; }

.arrow-text {
  padding-left: 45px;
  display: flow-root;
  margin-top: -25px; }

/* Removed blue outline from chrome */
:focus {
  outline: none !important; }

ul.bullet li {
  padding-left: 45px;
  padding-bottom: 5px;
  position: relative; }
  ul.bullet li:before {
    content: 'arrow_forward';
    font-family: 'Material Icons';
    position: absolute;
    font-size: 20px;
    left: 0;
    top: 0;
    line-height: 20px;
    -webkit-font-feature-settings: 'liga';
            font-feature-settings: 'liga'; }

ul.cross li {
  padding-left: 45px;
  padding-bottom: 5px;
  position: relative; }
  ul.cross li:before {
    content: 'clear';
    font-family: 'Material Icons';
    position: absolute;
    font-size: 22px;
    left: 0;
    top: 0;
    line-height: 24px;
    -webkit-font-feature-settings: 'liga';
            font-feature-settings: 'liga'; }

.loading-button {
  background-image: url(/images/loading-spinner-1.svg);
  background-position-x: 10px;
  background-position-y: 50%;
  background-repeat: no-repeat;
  padding-left: 60px; }

.btn-primary {
  margin-left: 5px;
  margin-right: 5px; }

.btn-secondary {
  margin-left: 5px;
  margin-right: 5px;
  border-color: #00afdb;
  color: #00afdb;
  background-color: #ffffff !important;
  text-align: center; }

.btn-secondary:hover {
  border-color: #00afdb;
  color: #00afdb;
  background-color: #ffffff !important; }
