@import '../../Theme/base.scss';

.invalid-message-block {
   margin: 5px 0 0 0;
}

.btn-search-margin {
   margin-top: 20px;
   margin-bottom: 10px;
}

.search-switch-link {
   font-size: 14px;
   font-weight: 500;
   font-family: 'HelveticaNeueLTPro-Md';
   color: $pgdb-activeblue;
   vertical-align: middle;
}

.multi-line-cell {
   margin-bottom: 3px;
}

.cell-valign {
   vertical-align: top;
}

.btn-toggle {
   line-height: 1em;
   height: auto;
   padding: 10px 10px 5px 10px;
   margin: -7px 5px 0px 5px;
}

.btn-toggle:active,
.btn-toggle:hover {
   background-color: $pgdb-lightgrey;
}

.min-cell-width {
   table-layout: fixed;
   width: 1px;
   /* min non-zero value */
}

.pr-highlighted-result {
   /*
      halfway between --pgdb-verylightgrey (#f5f5f5) and white (#fff) 
      as the definition is for the background to be #fff with opacity of 0.5
      where the rest of the page has a background color of #f5f5f5
    */
   background-color: #fafafa;
}

.pr-row-hover {
   cursor: pointer;
}

.pr-row-hover:hover {
   background-color: #fafafa;
}

.pr-separate-border {
   border-collapse: separate;
}

.pr-row-right-pad {
   padding-right: 50px;
}

.pr-row-left-indent {
   padding-left: 45px;
}

.pr-table {
   border-collapse: separate;
}

.pr-loading-spinner {
   //@extend .loading-button;
   background-image: url(/images/loading-spinner-1.svg);
   background-position-x: 10px;
   background-position-y: 50%;
   background-repeat: no-repeat;
   padding-left: 60px;
   //end extend

   padding-top: 10px;
   padding-bottom: 10px;
}

#root .pr-table-header {
   border-top: 0;
}

#root .pr-sticky-table-header {
   font-weight: bold;
   border-top: 1px solid #d6d6d6;

   position: -webkit-sticky;
   position: sticky;
   top: 0;
   background-color: #f4f4f4;
}

#root .pr-row-section-header {
   padding: 6px 0;
   color: #9b9b9b;
   border-bottom: 1px #d6d6d6 solid;
   font-size: 12px;
   margin-left: 15px;
}

.pr-row-section-header .col {
   padding: 6px 0;
}

.pr-row-no-vert-pad {
   padding-top: 0;
   padding-bottom: 0;
}

.pr-row-small-mb {
   margin-bottom: 10px;
}

.pr-text-active {
   color: $pgdb-green;
}

.pr-text-inactive {
   color: $pgdb-grey;
}

.pr-subtext {
   color: $pgdb-darkgrey;
}

.pr-advanced-row-footer {
   margin-bottom: 20px;
}

.pr-row-no-border {
   border-bottom: 0;
}

.drop-down {
   margin-bottom: 20px;
}

.condition-modal {
   width: 700px;
}
