@import '../../Theme/base.scss';

.radio-left-groups {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 10px;
    border-bottom: solid 1px $pgdb-grey;
    width: 100%;

    .radio-label {
        display: flex;
        margin-bottom: 10px;
    }

    .radio-option {
        display: flex;
    }

    .radio-display {
        display: flex;
    }

    @media (max-width: 1350px) and (min-width:993px) {
        .radio-display{
            flex-direction:column;
            flex-wrap:wrap;
            justify-content: flex-start;
        }
        .radio-option{
            padding-bottom:20px;
        }
    }

    @media (max-width: 700px) {
        .radio-display{
            flex-direction:column;
            flex-wrap:wrap;
            justify-content: flex-start;
        }
        .radio-option{
            padding-bottom:20px;
        }
    }

    .radio-option-label {
        width: 220px;
    }

    .label-icon {
        margin-right: 10px;
    }
}