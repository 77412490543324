@import '../../../Theme/base.scss';

.questions-page-divider {
  display: flex;
  gap: 50px;
  .questions-section.fit-and-proper-page {
    min-width: 600px;
  }
  .important-note {
    padding: 40px;
    background-color: $pgdb-white;
    .important-note-header {
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
        color: $pgdb-activeblue;
      }
    }
    .important-note-content {
      svg {
        font-size: 10px;
        margin-right: 5px;
        color: $pgdb-activeblue;
      }
      .important-note-sublist {
        margin-left: 15px;
      }
    }         
  }
}
.view-only-note {
  color: $pgdb-red;
}
.contact-numnber {
  color: $pgdb-activeblue;
}  








