@import '../../../Theme/base.scss';

.btn-primary {
   margin-left: 5px;
   margin-right: 5px;
}

.btn-secondary {
   margin-left: 5px;
   margin-right: 5px;
   border: $pgdb-activeblue 1px solid;
   color: $pgdb-activeblue;
   background-color: $pgdb-white !important;
   text-align: center;
}
.btn-secondary:hover {
   border-color: #00afdb;
   color: $pgdb-activeblue;
   background-color: $pgdb-white !important;
}
