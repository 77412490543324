@import '../../Theme/base.scss';

.datepicker-controls input[type=text]:not(.browser-default) {
    background-color: transparent;
    border: none;
    border-radius: 0;
    outline: none;
    height: 3rem;
    font-size: 16px;
    padding: 0;
    box-shadow: none;
    box-sizing: content-box;
    -webkit-transition: border .3s, -webkit-box-shadow .3s;
    -webkit-transition: box-shadow .3s, border .3s;
    transition: box-shadow .3s, border .3s;
  }
  
  .blue-link-design {
    color: $pgdb-activeblue !important;
    border: none !important;
    background-color: transparent !important;
    text-align: center !important;
    cursor: pointer !important;
  }
  
  .datepicker-date-display {
    background-color: $pgdb-activeblue;
  }
  
  .datepicker-cancel, .datepicker-clear, .datepicker-today, .datepicker-done {
    color: $pgdb-activeblue;
  }
  
  .datepicker-table td {
    &.is-selected {
      background-color: $pgdb-activeblue;
    }
    &.is-today {
      color: $pgdb-activeblue;
    }
  }
  
  .datepicker-day-button:focus {
    background-color: rgba(0, 175, 219, 0.25);
  }
  
  .dropdown-content li > {
    a, span {
      color: $pgdb-activeblue;
    }
  }