@import '../../Theme/base.scss';
/* ========== Card Layout ========== */

.licence-card {
   border-radius: 70px;
   border-top-right-radius: 200px;
   border-top-left-radius: 200px;
   height: 310px;
   width: 160px;
   color: white;
   margin-bottom: 20px;
   > {
      p:nth-child(2) {
         color: $pgdb-lightgrey;
      }
      .trade {
         font-size: 18px;
         text-align: center;
         margin-bottom: -25px;
         margin-top: -27px;
         padding-bottom: 30px;
         font-family: 'HelveticaNeueLTPro-Bd';
      }
      .level {
         font-size: 18px;
         text-align: center;
      }
      i {
         text-align: center;
         font-size: 3.5rem;
      }
   }
}

.licence-card a,
.licence-card span {
   font-size: 16px;
   font-family: 'HelveticaNeueLTPro-Bd';
   color: $pgdb-white;
   text-align: center;
}

.vLicence-div {
   width: 160px;
}

.vLicence {
   text-align: center;
   cursor: pointer;
   font-size: 12px;
   font-family: 'HelveticaNeueLTPro-md';
}

/* ========== Card Layout x2 ========== */

.licence-card-x2 {
   width: 220px;
   height: 427px;
   > {
      .card-image {
         width: 165px;
         height: 165px;
      }
      .level,
      .trade {
         font-size: 24px;
      }
      .vLicence {
         font-size: 16px;
      }
   }
}

.licence-statuses,
.licence-card-x2 a,
.licence-card-x2 span {
   font-size: 20px;
}
.licence-statuses {
   color: $pgdb-black;
   line-height: 29px;
   font-family: 'HelveticaNeueLTPro-Md';
   margin: 0px;
}
.licence-statuses-msg {
   color: $pgdb-darkgrey;
   font-size: 16px;
   margin-top: -10px;
   padding-bottom: 50px;
}

.licence-card-x2 i {
   text-align: center;
   font-size: 4.5rem;
}

/* ========== Purple ========== */

.purple-licence {
   background-image: linear-gradient(#9561a8, #672e8f);
}

/* ========== Orange ========== */

.orange-licence {
   background-image: linear-gradient(#f78f1e, #c3511d);
}

/* ========== Green ========== */

.green-licence {
   background-image: linear-gradient(#8cc63f, #328b39);
}

/* ========== Pending ========== */

.pending-licence {
   background-image: linear-gradient(#e4e4e4, #c4c4c4);
   color: black;
   > {
      p:nth-child(2) {
         color: $pgdb-darkgrey;
      }
   }
}

pending-licence a,
.pending-licence span {
   color: black;
}

/* ========== Disabled ========== */

.disabled-licence {
   background-image: linear-gradient(#e4e4e47a, #c4c4c47a);
   color: black;
   > {
      p:nth-child(2) {
         color: $pgdb-darkgrey;
      }
   }
}

.disabled-licence i {
   color: $pgdb-red;
}

.disabled-licence a,
.disabled-licence span {
   color: $pgdb-red;
}

/* ============================== */

.card-image {
   background-position: 20px;
   margin-top: 20px;
   width: 120px;
   height: 120px;
   border-radius: 360px;
}

.opacity-01 {
   opacity: 0.1;
}

.renew-btn {
   margin-top: 10px;
   margin-bottom: 20px;
}

div.change-hover div.renew-action {
   display: inline;
}
div.change-hover:hover div.renew-action {
   display: none;
}
div.change-hover div.change-action {
   display: none;
}
div.change-hover:hover div.change-action {
   display: inline;
   cursor: pointer;
}
