@import '../../Theme/base.scss';

#cpd-section {
   margin-bottom: 2em;
   font-family: 'HelveticaNeueLTPro-Md';
   a {
      color: $pgdb-activeblue;
   }
}

#cpd-section-header {
   font-size: 30px;
   font-weight: bold;
}

#cpd-section-sub-header {
   color: $pgdb-darkgrey;
   font-size: 16px;
   margin-top: 0;
}

#cpd-status-header {
   color: $pgdb-black;
   font-size: 30px;
   margin-bottom: 0;
   font-weight: bold;
   letter-spacing: 1px;
   line-height: 1.2em;
}

#cpd-sub-description {
   color: $pgdb-black;
   font-size: 16px;
   margin-top: 15px;
}

#cpd-description {
   color: $pgdb-black;
   font-size: 30px;
   padding-bottom: 30px;
   margin-top: 5px;
   margin-bottom: 0;
   line-height: 1.2em;
   &.active-blue {
      color: $pgdb-activeblue;
   }
}

.ml-0 {
   margin-left: 0px !important;
}

.mr-1 {
   margin-right: 1rem;
}

.ml-1 {
   margin-left: 1rem;
}
