@import '../../../Theme/base.scss';

.checkbox-wrapper,
.link-wrapper {
   margin-top: 16px;
}

#root .next-button-wrapper {
   margin-top: 40px;
   margin-bottom: 145px;
}

.profile-page-padding {
   margin-top: 50px;
}

.upload-photo {
   height: 194px;
   width: 162px;
   border-radius: 6px;
   border: solid 1px $pgdb-grey;
   background-color: $pgdb-white;
   color: $pgdb-activeblue;
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   display: table;
   &:hover {
      border: solid 1px $pgdb-activeblue;
      a {
         display: table-cell !important;
         background-color: rgba(0, 0, 0, 0.3);
         font-weight: bold;
         text-shadow: #fff 0px 0px 5px, #fff 0px 0px 10px, #fff 0px 0px 15px,
            $pgdb-blue 0px 0px 20px, $pgdb-blue 0px 0px 30px,
            $pgdb-blue 0px 0px 40px, $pgdb-blue 0px 0px 50px,
            $pgdb-blue 0px 0px 75px;
      }
   }
   a,
   label {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
   }
   &:hover label {
      display: none !important;
   }
}

.photo-button-wrapper {
   display: flex;
}

.photo-description {
   margin-top: 45px;
   max-width: 60%;
}

.photo-description-wrapper {
   display: flex;
   justify-content: center;
   flex-direction: column;
}

.profile-row {
   margin-bottom: 0;
}

.section-header {
   font-weight: bold;
   font-size: 18px;
   padding: 0;
   display: inline-block;
   font-family: 'HelveticaNeueLTPro-Md';
}

.section-break {
   margin-top: 46px;
}

.red-text {
   color: $pgdb-red;
}

.green-text {
   color: $pgdb-green;
}

.button-text {
   margin-top: 20px;
   display: inline-block;
}

.photo-upload-section {
   .tnc-row {
      margin-bottom: 40px;
      .black-text {
         font-size: 14px !important;
      }
   }
   .control-row {
      margin-bottom: 70px;
   }
}
