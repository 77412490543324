@import '../../../../Theme/base.scss';

.profile-photo-container > {
    .photo-editor {
      padding: 0px;
      > {
        .avatar-frame > canvas {
          border: solid 4px $pgdb-darkgrey;
          border-radius: 0;
          background-image: url(/images/grid.svg);
          background-size: auto auto;
        }
        .avatar-control-frame {
          position: relative;
          max-width: 320px;
          height: 380px;
          margin-left: -12px !important;
          background-color: $pgdb-darkgrey;
          > .control-button-list {
            display: inline-grid;
            padding-left: 45px;
            padding-right: 100px;
            padding-top: 10px;
            margin-top: 10px;
          }
          margin-left: 0px;
          ul > li {
            color: $pgdb-verylightgrey;
            display: inline-flex;
            margin-top: 20px;
            background: transparent;
            user-select: none;
            &:hover {
              color: $pgdb-activeblue;
            }
            > span {
              cursor: pointer;
              padding-left: 10px;
              padding-left: 10px;
            }
          }
        }
      }
    }
    .controls button {
      margin-right: 20px;
    }
  }
  
  .callout-body > .canvasCard {
    width: 350px;
  }
  
  .preview-modal {
    background: transparent;
    border: none;
    display: block;
    position: fixed;
    color: $pgdb-black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1003;
    margin: auto;
    &:focus {
      outline: none;
    }
  }
  
  .card-preview:focus {
    outline: none;
  }