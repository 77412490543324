@import '../../../../Theme/base.scss';


.registration-review-container{
    border-bottom: solid 1px #c4c4c4;
    margin-bottom: 10px;

}

.review-heading{
    display:flex;
    justify-content: space-between;
    align-items: center;

    .review-title{
        color: #808080;
        font-weight:700;
    }
}


.review-edit-section{
    display:flex;
    align-items: flex-end;
    
    .review-edit-button{
        color: #808080;
        display:flex;
        align-items: center;
    }

    .edit-icon{
        font-size:18px;
        margin-right:5px;
        color: #808080;
    }
}

.fit-and-proper-review-index{
    margin-right: 10px;
}

.fit-and-proper-review-questions{
    margin-bottom: 10px;
}

.fit-and-proper-review-answers{
    font-weight:700;
}
.review-details, .review-description{
    margin-top: 10px;
    margin-bottom:10px;
}

.review-description{
    text-transform: uppercase;
}

.review-subheading{
    font-weight: 700;
}

.review-checkbox-label{

    input[type="checkbox"]{
        opacity:1;
        position: static;
    }
    
    .checkbox-input{
        margin-right:15px
    }

    label{
        font-size: inherit;
        color: black;
    }
   
}

.upload-section {
    .uploaded-files {
       margin-left: 0px;
       margin-right: 10px;
       margin-bottom: 30px;
 
       .uploaded-file-item {
          padding: 2px;
          height: 50px;
          margin: 5px;
          background-color: $pgdb-lightgrey;
          display: flex;
          justify-content: space-between;
          
          .uploaded-file-icon-and-name{
             padding-top:4px;
             display:flex;
             align-items:center;
             max-width: 70%;    
 
          }
          
          .file-name {
             cursor: pointer;
             display: inline-block;
             white-space: nowrap;
             overflow: hidden;
             text-overflow: ellipsis;
          }
    
       }
    }
 }