.company-contact-details .control-row {
  margin-bottom: 70px; }

.pay-and-nominate .columnheader {
  line-height: 35px; }

.pay-and-nominate .sub-heading {
  color: #666666;
  font-size: 18px;
  font-family: 'HelveticaNeueLTPro-Roman' !important; }

.pay-and-nominate .control-row {
  margin-bottom: 70px; }

.pay-and-nominate .application-table .generic-table .name-header-cell {
  padding-left: 0px; }

.pay-and-nominate .application-table .generic-table .currency-cell {
  font-weight: bold; }

.pay-and-nominate .application-table .generic-table th,
.pay-and-nominate .application-table .generic-table td {
  padding-left: 10px;
  border-bottom: none; }
  .pay-and-nominate .application-table .generic-table th:first-child,
  .pay-and-nominate .application-table .generic-table td:first-child {
    padding-left: 0px; }

.pay-and-nominate .application-table .generic-table i {
  padding-right: 10px;
  float: right; }

.pay-and-nominate .application-table .generic-table .link-button {
  cursor: pointer; }

.pay-and-nominate .application-table .generic-table .licence-row {
  border-bottom: none;
  font-weight: bold; }

.pay-and-nominate .application-table .generic-table .first-row {
  border-top: solid 1px #d6d6d6; }

.pay-and-nominate .application-table .generic-table .fee-row {
  border-bottom: none; }
  .pay-and-nominate .application-table .generic-table .fee-row td {
    padding-top: 0px; }

.pay-and-nominate .application-table .generic-table .sub-total-row {
  font-weight: bold;
  border-top: solid 1px #d6d6d6;
  border-bottom: none; }

.pay-and-nominate .application-table .generic-table .gst-total-row {
  border-bottom: none; }
  .pay-and-nominate .application-table .generic-table .gst-total-row .currency-cell {
    font-weight: normal; }

.pay-and-nominate .application-table .generic-table .total-row {
  border-top: 1px solid;
  border-bottom: 2px solid;
  font-weight: bold; }

.pay-and-nominate .application-table .generic-table .name-cell {
  padding-left: 0px; }

.pay-and-nominate .application-table .generic-table .amount-cell {
  text-align: right;
  padding-right: 15px; }

.pay-and-nominate .application-table .generic-table .table-text-box {
  width: 210px;
  height: 56px; }
  .pay-and-nominate .application-table .generic-table .table-text-box div {
    width: 100px;
    float: left; }
  .pay-and-nominate .application-table .generic-table .table-text-box span.cancel-nomination {
    float: left;
    font-weight: normal;
    margin-left: 10px; }
