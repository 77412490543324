.bulk-renew-container {
    padding-top: 30px;
    .controls {
        margin-top: 30px;
        button {
            &:last-child {
                margin-left: 30px;
            }
        }
    }
}

.confirm-controls {
    text-align: center;
    button {
        &:last-child {
            margin-left: 30px;
        }
    }
}

.confirm-modal-body {
    max-height: 30px;
    
    padding: 55px;
    .confirm-message {
        font-size: 20px;
    }
}