body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow-y: scroll; }

#root,
.ai-container {
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column; }

#root .profile-dropdown-icons {
  font-size: 18px;
  margin: 0 3px 0 0; }

#root .notification-bell {
  margin-left: 5px;
  padding: 0 8px;
  font-size: 30px;
  height: 100%;
  line-height: 80px;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease; }
  #root .notification-bell:hover {
    background-color: #e4e4e4; }

.left-nav-menu {
  margin: 10px 0 !important; }

.right-nav-panel {
  position: absolute;
  right: 0;
  top: 0; }

.profile-right-drop-down {
  margin-left: 0 !important; }

.flex-vertical-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.profile-link {
  padding: 0 0 0 5px;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease; }
  .profile-link:hover {
    background-color: #e4e4e4; }

.profile {
  height: 38px;
  width: 38px;
  text-align: center;
  margin-left: 10px; }

.header-dropdown .dropdown-content {
  height: auto !important;
  width: 170px !important;
  top: 80px !important;
  left: 0px !important;
  overflow: visible !important; }

.header-dropdown #dropdown1 li:first-child {
  margin-top: 0; }

.header-dropdown #dropdown1 li:last-child {
  margin-bottom: 0; }

@media only screen and (max-width: 1300px) {
  .hide-on-tablet-and-down {
    display: none !important; }
  .brand-logo {
    left: 50%;
    -webkit-transform: translateX(-50%) !important;
            transform: translateX(-50%) !important; } }

@media only screen and (max-width: 1300px) {
  nav a.sidenav-trigger {
    display: block !important;
    margin-left: 5% !important; } }

.close-panel-padding {
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px; }

.no-margin-bottom {
  margin-bottom: 0px; }

/* ========== Card Layout ========== */
.licence-card {
  border-radius: 70px;
  border-top-right-radius: 200px;
  border-top-left-radius: 200px;
  height: 310px;
  width: 160px;
  color: white;
  margin-bottom: 20px; }
  .licence-card > p:nth-child(2) {
    color: #e4e4e4; }
  .licence-card > .trade {
    font-size: 18px;
    text-align: center;
    margin-bottom: -25px;
    margin-top: -27px;
    padding-bottom: 30px;
    font-family: 'HelveticaNeueLTPro-Bd'; }
  .licence-card > .level {
    font-size: 18px;
    text-align: center; }
  .licence-card > i {
    text-align: center;
    font-size: 3.5rem; }

.licence-card a,
.licence-card span {
  font-size: 16px;
  font-family: 'HelveticaNeueLTPro-Bd';
  color: #ffffff;
  text-align: center; }

.vLicence-div {
  width: 160px; }

.vLicence {
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  font-family: 'HelveticaNeueLTPro-md'; }

/* ========== Card Layout x2 ========== */
.licence-card-x2 {
  width: 220px;
  height: 427px; }
  .licence-card-x2 > .card-image {
    width: 165px;
    height: 165px; }
  .licence-card-x2 > .level,
  .licence-card-x2 > .trade {
    font-size: 24px; }
  .licence-card-x2 > .vLicence {
    font-size: 16px; }

.licence-statuses,
.licence-card-x2 a,
.licence-card-x2 span {
  font-size: 20px; }

.licence-statuses {
  color: #000000;
  line-height: 29px;
  font-family: 'HelveticaNeueLTPro-Md';
  margin: 0px; }

.licence-statuses-msg {
  color: #666666;
  font-size: 16px;
  margin-top: -10px;
  padding-bottom: 50px; }

.licence-card-x2 i {
  text-align: center;
  font-size: 4.5rem; }

/* ========== Purple ========== */
.purple-licence {
  background-image: -webkit-linear-gradient(#9561a8, #672e8f);
  background-image: linear-gradient(#9561a8, #672e8f); }

/* ========== Orange ========== */
.orange-licence {
  background-image: -webkit-linear-gradient(#f78f1e, #c3511d);
  background-image: linear-gradient(#f78f1e, #c3511d); }

/* ========== Green ========== */
.green-licence {
  background-image: -webkit-linear-gradient(#8cc63f, #328b39);
  background-image: linear-gradient(#8cc63f, #328b39); }

/* ========== Pending ========== */
.pending-licence {
  background-image: -webkit-linear-gradient(#e4e4e4, #c4c4c4);
  background-image: linear-gradient(#e4e4e4, #c4c4c4);
  color: black; }
  .pending-licence > p:nth-child(2) {
    color: #666666; }

pending-licence a,
.pending-licence span {
  color: black; }

/* ========== Disabled ========== */
.disabled-licence {
  background-image: -webkit-linear-gradient(#e4e4e47a, #c4c4c47a);
  background-image: linear-gradient(#e4e4e47a, #c4c4c47a);
  color: black; }
  .disabled-licence > p:nth-child(2) {
    color: #666666; }

.disabled-licence i {
  color: #ee3224; }

.disabled-licence a,
.disabled-licence span {
  color: #ee3224; }

/* ============================== */
.card-image {
  background-position: 20px;
  margin-top: 20px;
  width: 120px;
  height: 120px;
  border-radius: 360px; }

.opacity-01 {
  opacity: 0.1; }

.renew-btn {
  margin-top: 10px;
  margin-bottom: 20px; }

div.change-hover div.renew-action {
  display: inline; }

div.change-hover:hover div.renew-action {
  display: none; }

div.change-hover div.change-action {
  display: none; }

div.change-hover:hover div.change-action {
  display: inline;
  cursor: pointer; }

.text-link {
  cursor: pointer; }

@font-face {
  font-family: 'MyriadPro';
  src: local("MyriadPro"), url(/static/media/MyriadPro-Regular.104190e9.eot);
  src: url(/static/media/MyriadPro-Regular.104190e9.eot?#iefix) format("embedded-opentype"), url(/static/media/MyriadPro-Regular.6441e999.woff) format("woff"), url(/static/media/MyriadPro-Regular.e5515e90.ttf) format("truetype"), url(/static/media/MyriadPro-Regular.32492e63.svg#MyriadPro-Regular) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'MyriadPro-It';
  src: local("MyriadPro"), url(/static/media/MyriadPro-It.3f4d7321.eot);
  src: url(/static/media/MyriadPro-It.3f4d7321.eot?#iefix) format("embedded-opentype"), url(/static/media/MyriadPro-It.5d593171.woff) format("woff"), url(/static/media/MyriadPro-It.e3b26fb4.ttf) format("truetype"), url(/static/media/MyriadPro-It.823384fb.svg#MyriadPro-It) format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'MyriadPro';
  src: local("MyriadPro"), url(/static/media/MyriadPro-Bold.dc897f05.eot);
  src: url(/static/media/MyriadPro-Bold.dc897f05.eot?#iefix) format("embedded-opentype"), url(/static/media/MyriadPro-Bold.2e17ae6d.woff) format("woff"), url(/static/media/MyriadPro-Bold.ec64422d.ttf) format("truetype"), url(/static/media/MyriadPro-Bold.7bf49eac.svg#MyriadPro-Bold) format("svg");
  font-weight: bold;
  font-style: normal; }

.preloadFont {
  font-family: 'MyriadPro', Arial;
  font-weight: normal;
  font-style: normal; }

canvas {
  font-family: 'MyriadPro', Arial;
  font-weight: normal;
  font-style: normal;
  /* For scaling */
  /* border-radius: 20px;
     width: 400px; */ }

.columnheader {
  color: #000000;
  font-size: 30px;
  line-height: 29px;
  font-family: 'HelveticaNeueLTPro-Md'; }

.sub-header {
  color: #666666;
  font-size: 18px;
  margin-top: -10px;
  padding-bottom: 50px; }

.canvas-card {
  width: 475px;
  height: 299px;
  background-size: contain;
  margin: auto;
  position: relative; }

.card-front {
  background-image: url("/images/card/Licence_Card_Front.png"); }

.card-back {
  background-image: url("/images/card/Licence_Card_Back.png"); }

.card-front-text {
  top: 230px;
  width: 200px;
  padding-left: 30px;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.front-barcode {
  font-family: 'MyriadPro';
  text-align: left;
  font-size: 30px;
  line-height: 1; }

.front-expiry {
  font-family: 'MyriadPro';
  display: inline-block;
  text-align: left;
  font-size: 16px; }

.back-barcode-container {
  display: -webkit-flex;
  display: flex; }

.mini-card-icons {
  width: 30px;
  padding-bottom: 3px; }

.mini-licence-tile {
  display: inline-block;
  padding-top: 5px;
  padding-right: 3px;
  padding-left: 3px;
  padding-bottom: 2px;
  border-radius: 25px 25px 5px 5px;
  width: 38px; }

.mini-icon-text {
  font-family: 'MyriadPro';
  font-size: 8px;
  color: white; }

.mini-tile-wrapper {
  position: absolute;
  bottom: 20px;
  left: 40px; }

.mini-licence-tile:nth-child(n + 2) {
  margin-left: 10px; }

.gasfitter-tile {
  background-color: #c3511d; }

.plumber-tile {
  background-color: #672e8f; }

.drainlayer-tile {
  background-color: #328b39; }

.card-profile-photo {
  position: absolute;
  width: 140px;
  left: 29px;
  top: 26px;
  border-radius: 13px;
  height: 170px;
  object-fit: cover; }

.no-photo-supplied {
  position: absolute;
  width: 120px;
  left: 40px;
  top: 50px;
  height: 128px;
  font-size: 20px;
  line-height: 40px;
  font-weight: bold; }

.disc-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-top: 13px; }

.detail-wrapper {
  display: -webkit-flex;
  display: flex;
  height: 85%; }

.photoContainer {
  display: -webkit-flex;
  display: flex;
  width: 40%;
  padding: 25px; }

.name-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 100%; }
  .name-container .card-firstname {
    font-size: 1.1em;
    margin: 0px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-align: left; }
  .name-container .card-lastname {
    font-size: 1.1em;
    margin: 0px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-align: left; }

.details-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 60%;
  padding: 25px 15px 0px 0px; }

.barcode-details-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
  -webkit-justify-content: end;
          justify-content: end;
  padding-bottom: 2px; }
  .barcode-details-container p {
    margin: 0px; }
  .barcode-details-container .licence-number-text {
    font-weight: bold;
    font-size: 1.3em;
    margin-bottom: -8px; }
  .barcode-details-container .expiry-text {
    font-weight: bold;
    font-style: italic;
    font-size: 0.7em; }

.barcode-container {
  margin-right: 5px; }

.licence-type-container {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 5px; }
  .licence-type-container .licence-type-logo {
    margin-left: 0px;
    max-height: 25px; }
  .licence-type-container .licence-type-text {
    margin: 0px 10px; }

.callout {
  display: block;
  margin: 1.5rem 1rem; }

.callout-body {
  text-align: left;
  border-radius: 15px;
  background-color: #fff;
  padding: 20px;
  position: relative; }
  .callout-body:before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 15px solid transparent;
    border-right: 15px solid #fff;
    border-top: 15px solid #fff;
    border-bottom: 15px solid transparent;
    left: -16px;
    top: 0px;
    margin: 0; }

@media (max-width: 992px) {
  .callout-profile-img {
    float: left; }
  .callout-body {
    left: 30px; } }

@media (min-width: 993px) {
  .callout-profile-img {
    float: left;
    margin-left: -80px; } }

.columnheader {
  color: #000000;
  font-size: 30px;
  line-height: 29px;
  font-family: 'HelveticaNeueLTPro-Md'; }

.sub-header {
  color: #666666;
  font-size: 18px;
  margin-top: -10px;
  padding-bottom: 50px; }

.licence-modal {
  background-color: transparent;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); }

.loading-wrapper-main {
  position: absolute;
  top: 25%;
  left: 50%;
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%); }
  .loading-wrapper-main .loading-wrapper-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .loading-wrapper-main .loading-wrapper-oops-text {
    padding-bottom: 10px;
    color: #ee3224; }

.loading-overlay-container {
  position: relative;
  width: 100%;
  height: 100%; }
  .loading-overlay-container .loading-overlay-animator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(244, 244, 244, 0.7);
    background-image: url("/images/loading.svg");
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    z-index: 999; }

.loading-fade-in {
  -webkit-animation: 0.4s linear 0s 1 fadeIn;
          animation: 0.4s linear 0s 1 fadeIn; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.registration-container .collapsible-header {
  background-color: inherit;
  border-bottom: none;
  display: inherit;
  padding: 0; }
  .registration-container .collapsible-header i {
    position: absolute;
    right: 25px;
    top: 38px; }
  .registration-container .collapsible-header .header-column {
    position: relative; }
  .registration-container .collapsible-header .row {
    margin-bottom: 0; }

.registration-container .collapsible-body {
  padding: inherit;
  border-bottom: none; }

.registration-container .collapsible {
  border: none;
  margin: inherit;
  box-shadow: none;
  -webkit-box-shadow: none; }
  .registration-container .collapsible .header-column i.add-icon {
    display: inherit; }
  .registration-container .collapsible .header-column i.remove-icon {
    display: none; }
  .registration-container .collapsible .active .header-column i.add-icon {
    display: none; }
  .registration-container .collapsible .active .header-column i.remove-icon {
    display: inherit; }
  .registration-container .collapsible .active .collapsible-body {
    display: block; }

.registration-container .registration-trade-header {
  background: #ffffff;
  text-align: center;
  margin-top: 20px;
  padding: 15px;
  border-radius: 50px; }
  .registration-container .registration-trade-header h2 {
    color: #03115c;
    margin: 20px 10px; }

.registration-container .row.no-margin {
  margin-bottom: 0; }

.registration-container .trade-row.col.s2 {
  margin-left: 0;
  margin-right: 5px;
  padding-left: 0;
  padding-right: 0; }

.registration-container .trade-row .application-section {
  display: table;
  min-height: 334px; }
  .registration-container .trade-row .application-section .row.complete {
    display: table-cell;
    vertical-align: bottom; }

.registration-container .registration-row {
  position: relative; }
  .registration-container .registration-row .certifier-complete {
    position: absolute;
    bottom: 0; }

.registration-container .trade-box {
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: inline-block;
  width: 28%;
  vertical-align: text-bottom;
  margin-right: 3px;
  position: relative; }
  .registration-container .trade-box.completed.P {
    background-image: -webkit-linear-gradient(#9561a8, #03115c);
    background-image: linear-gradient(#9561a8, #03115c); }
  .registration-container .trade-box.completed.G {
    background-image: -webkit-linear-gradient(#f78f1e, #c3511d);
    background-image: linear-gradient(#f78f1e, #c3511d); }
  .registration-container .trade-box.completed.D {
    background-image: -webkit-linear-gradient(#8cc63f, #328b39);
    background-image: linear-gradient(#8cc63f, #328b39); }
  .registration-container .trade-box.completed p {
    color: #ffffff; }
  .registration-container .trade-box.completed img {
    margin: auto;
    left: 0;
    right: 0;
    position: absolute;
    top: -100%;
    height: 100%;
    max-height: 100px;
    width: 100%;
    max-width: 100px; }
  .registration-container .trade-box.pending {
    background-color: #ffffff; }
    .registration-container .trade-box.pending img {
      display: none; }
  .registration-container .trade-box.suspended {
    background-image: -webkit-linear-gradient(#c4c4c4, #666666);
    background-image: linear-gradient(#c4c4c4, #666666); }
    .registration-container .trade-box.suspended p {
      color: #ffffff; }
    .registration-container .trade-box.suspended img {
      display: none; }
  .registration-container .trade-box.journeyman {
    padding-top: 40px;
    padding-bottom: 10px;
    margin-top: 210px; }
    .registration-container .trade-box.journeyman img {
      top: -100px; }
    .registration-container .trade-box.journeyman img.trophy-stars {
      top: -125px; }
    .registration-container .trade-box.journeyman.pending {
      padding-top: 77px; }
  .registration-container .trade-box.tradesman {
    padding-top: 100px;
    padding-bottom: 10px; }
    .registration-container .trade-box.tradesman img {
      top: -100px; }
    .registration-container .trade-box.tradesman img.trophy-stars {
      top: -125px; }
    .registration-container .trade-box.tradesman.pending {
      padding-top: 117px; }
  .registration-container .trade-box.certifying {
    padding-top: 160px;
    padding-bottom: 10px; }
    .registration-container .trade-box.certifying img {
      display: none; }
    .registration-container .trade-box.certifying.pending {
      padding-top: 177px; }
  .registration-container .trade-box img.discipline-img {
    position: absolute;
    width: 25%;
    max-width: 25px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -115px; }
  .registration-container .trade-box .trade-level {
    font-size: 10px;
    line-height: 14px;
    text-transform: capitalize; }
  .registration-container .trade-box .trade-status {
    font-size: 11px;
    line-height: 17px;
    font-weight: bold;
    text-transform: uppercase; }
    .registration-container .trade-box .trade-status.completed {
      color: #ffffff; }
    .registration-container .trade-box .trade-status.pending {
      color: #8cc63f; }
    .registration-container .trade-box .trade-status.not-available {
      color: #ee3224; }
  .registration-container .trade-box p {
    color: #000000;
    text-align: center;
    vertical-align: text-bottom; }

.registration-container .trade-heading {
  font-size: 14px;
  line-height: 17px;
  font-weight: bold; }

.registration-container .trade-name {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 19px;
  letter-spacing: 0; }

.registration-container .trophy-img-section {
  position: relative; }

.registration-container .application-name {
  font-weight: bolder;
  font-size: 20px; }

.registration-container .application-heading {
  font-weight: bold; }

.registration-container .application-condition p {
  margin-top: 5px; }

.registration-container .application-condition .row {
  margin-bottom: 0; }

.registration-container .application-condition i {
  text-align: center;
  width: 100%; }
  .registration-container .application-condition i.smaller {
    font-size: 8px;
    margin-top: 11px; }

.registration-container .certifier-suspended {
  text-align: center;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding-bottom: 5px;
  padding-top: 80px; }
  .registration-container .certifier-suspended h3 {
    padding: 0px 20px 80px 20px; }

.registration-container .certifier-complete {
  text-align: center;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding-bottom: 5px; }
  .registration-container .certifier-complete .trophy-img {
    max-width: 45%; }
  .registration-container .certifier-complete .discipline-img {
    position: absolute;
    width: 15%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 20%; }
  .registration-container .certifier-complete h3 {
    color: #03115c;
    font-weight: bold;
    line-height: 50px; }
  .registration-container .certifier-complete p {
    margin: 0 auto;
    font-size: 12px;
    color: #03115c; }
  .registration-container .certifier-complete .registration-congratulations-text {
    font-weight: bold; }

.registration-container .trade-splitter {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  top: 100px;
  font-size: 20px; }

.registration-container .trade-subtitle,
.registration-container .apply-now-btn,
.registration-container .invalid-registration-title {
  text-align: center; }

.registration-container .fit-text {
  margin-top: 20px;
  text-align: right;
  font-style: italic;
  font-size: 12px; }
  .registration-container .fit-text .btn-small {
    padding: 10px 25px;
    font-size: 12px; }

.fit-modal-body {
  padding: 0 30px 5px 30px; }
  .fit-modal-body p {
    text-align: left; }

.fit-model-icon {
  cursor: pointer;
  margin: 10px 10px 0 0; }

.mt-10 {
  margin-top: 10px; }

.mb-0 {
  margin-bottom: 0px; }

.m-0 {
  margin: 0px; }

.vertical-alignment {
  vertical-align: middle; }

.min-width-170 {
  min-width: 170px; }

.registration-click-here-button {
  right: 30px;
  margin-left: 30px !important; }

.application-status-details {
  vertical-align: middle;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.application-status-row {
  background: #ffffff;
  text-align: center;
  margin-top: 20px;
  padding: 15px 0 15px 0;
  border-radius: 50px; }
  .application-status-row h2 {
    color: #03115c;
    margin: 20px 10px; }

.application-action-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.override-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin: 0 0px !important;
  padding-left: 25px !important;
  padding-right: 25px !important; }

.override-margin-large {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin: 0 0px !important;
  padding-left: 9px !important;
  padding-right: 9px !important; }

.delete-application-buttons {
  float: right;
  padding: 0px 20px 20px 0px; }

.action-buttons,
.draft-action-buttons {
  text-overflow: ''; }

@media (max-width: 1238px) {
  .draft-action-buttons {
    min-width: 85px;
    padding: 15px 20px 30px 20px !important;
    font-size: 9px !important; } }

.modal-cancel-button {
  padding: 10px 15px 10px 15px;
  border-radius: 28px;
  border: #00afdb 1px solid;
  border-width: 2px;
  border-style: solid;
  background-color: #f5f5f5;
  color: #00afdb;
  text-align: center;
  font-family: 'HelveticaNeueLTPro-Md';
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  text-transform: none;
  width: 100px;
  margin-right: 10px;
  cursor: pointer; }

.modal-delete-button {
  padding: 10px 15px 10px 15px;
  border-radius: 28px;
  border: #ee3224 1px solid;
  border-width: 2px;
  border-style: solid;
  background-color: #ee3224;
  color: #ffffff;
  text-align: center;
  font-family: 'HelveticaNeueLTPro-Md';
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  text-transform: none;
  min-width: 100px;
  cursor: pointer; }

.delete-modal {
  z-index: 1003;
  top: 10%;
  width: 500px;
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fafafa;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2); }

.missing-condition {
  color: #00afdb;
  font-size: 2.2rem;
  padding-bottom: 0.5rem; }

.outstanding-conditions > li {
  margin-top: 5px; }

.title-message-pop-up {
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 40px; }

@media (max-width: 500px) {
  .delete-modal {
    width: auto; } }

.btn-primary {
  margin-left: 5px;
  margin-right: 5px; }

.btn-secondary {
  margin-left: 5px;
  margin-right: 5px;
  border: #00afdb 1px solid;
  color: #00afdb;
  background-color: #ffffff !important;
  text-align: center; }

.btn-secondary:hover {
  border-color: #00afdb;
  color: #00afdb;
  background-color: #ffffff !important; }

.model-dialog {
  display: block;
  position: fixed;
  font-size: 16px;
  background-color: white;
  color: black;
  color: #000000;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-top-left-radius: 0.3rem;
  padding: 15px;
  z-index: 1003;
  background-color: #fafafa;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2); }

.model-dialog-overlay {
  z-index: 1002;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); }

.model-dialog-footer {
  padding-top: 50px;
  text-align: center; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity 500ms ease-in-out;
  transition: opacity 500ms ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.registration-container .row.no-margin {
  margin-bottom: 0; }

.side-note-background {
  border: 1px #ddd;
  background-color: white;
  margin-bottom: 10px;
  margin-top: 10px; }

.important-note {
  padding: 15px;
  background-color: #ffffff; }
  .important-note .important-note-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .important-note .important-note-header svg {
      margin-right: 5px;
      color: #00afdb; }
  .important-note .important-note-content svg {
    font-size: 10px;
    margin-right: 5px; }
  .important-note .important-note-content .important-note-sublist {
    margin-left: 15px; }
  .important-note .important-note-content ul {
    padding-left: 0;
    list-style: none; }
    .important-note .important-note-content ul li {
      padding-left: 1.5rem;
      text-indent: -1rem; }
  .important-note .important-note-content ol {
    padding-left: 1rem; }
    .important-note .important-note-content ol li {
      padding-left: 1rem;
      text-indent: 0rem; }
  .important-note .application-fee-title {
    font-weight: bold; }

.important-note-footer {
  font-weight: bold; }

.registration-header {
  padding-bottom: 15px; }

.registration-subheading {
  font-weight: 600;
  padding-bottom: 5px; }

.registration-list p {
  margin: 8px 0 0 0; }

.important-note-email {
  word-wrap: break-word; }

.important-note-phone {
  white-space: nowrap; }

.application-fees {
  padding-bottom: 15px; }
  .application-fees p {
    margin: 10px 0 10px 0; }

.radio-left-groups {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 10px;
  border-bottom: solid 1px #c4c4c4;
  width: 100%; }
  .radio-left-groups .radio-label {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 10px; }
  .radio-left-groups .radio-option {
    display: -webkit-flex;
    display: flex; }
  .radio-left-groups .radio-display {
    display: -webkit-flex;
    display: flex; }
  @media (max-width: 1350px) and (min-width: 993px) {
    .radio-left-groups .radio-display {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: flex-start;
              justify-content: flex-start; }
    .radio-left-groups .radio-option {
      padding-bottom: 20px; } }
  @media (max-width: 700px) {
    .radio-left-groups .radio-display {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: flex-start;
              justify-content: flex-start; }
    .radio-left-groups .radio-option {
      padding-bottom: 20px; } }
  .radio-left-groups .radio-option-label {
    width: 220px; }
  .radio-left-groups .label-icon {
    margin-right: 10px; }

.registration-type-profile-row {
  background-color: none;
  margin-bottom: 0; }

.registration-container .row.no-margin {
  margin-bottom: 0; }

.registration-container .radio-left-groups [disabled|=""] + .radio-option-label {
  cursor: auto; }

.registration-container .registration-type-profile-row button.loading-button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 28px;
  padding-bottom: 28px; }

.important-note {
  padding: 15px;
  background-color: #ffffff; }
  .important-note .important-note-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .important-note .important-note-header svg {
      margin-right: 5px;
      color: #00afdb; }
  .important-note .important-note-content svg {
    font-size: 10px;
    margin-right: 5px; }
  .important-note .important-note-content .important-note-sublist {
    margin-left: 15px; }
  .important-note .important-note-footer {
    -webkit-flex-direction: column;
            flex-direction: column; }
    .important-note .important-note-footer svg {
      font-size: 10px;
      margin-right: 5px;
      color: #00afdb; }
    .important-note .important-note-footer .important-note-sublist {
      margin-left: 15px; }

.view-only-note {
  color: #ee3224; }

.contact-numnber {
  color: #00afdb; }

.navigate-buttons {
  text-align: center;
  text-transform: none;
  float: right;
  display: -webkit-flex;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px; }

.btn-invert {
  background-color: transparent;
  color: #00afdb;
  border: solid 2px #00afdb; }

.checkbox-wrapper,
.link-wrapper {
  margin-top: 16px; }

#root .next-button-wrapper {
  margin-top: 40px;
  margin-bottom: 145px; }

.profile-page-padding {
  margin-top: 50px; }

.registration-application-photo {
  height: 194px;
  width: 162px;
  border-radius: 6px;
  border: solid 1px #c4c4c4;
  background-color: #ffffff;
  color: #00afdb;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: table; }
  .registration-application-photo:hover a {
    display: table-cell !important;
    background-color: rgba(0, 0, 0, 0.3);
    font-weight: bold;
    text-shadow: #fff 0px 0px 5px, #fff 0px 0px 10px, #fff 0px 0px 15px, #00539f 0px 0px 20px, #00539f 0px 0px 30px, #00539f 0px 0px 40px, #00539f 0px 0px 50px, #00539f 0px 0px 75px; }
  .registration-application-photo a,
  .registration-application-photo label {
    display: table-cell;
    text-align: center;
    vertical-align: middle; }
  .registration-application-photo:hover label {
    display: none !important; }

.photo-button-wrapper {
  display: -webkit-flex;
  display: flex; }

.photo-description {
  margin-top: 45px;
  max-width: 60%; }

.photo-description-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column; }

.profile-row {
  margin-bottom: 0; }

.section-header {
  font-weight: bold;
  font-size: 18px;
  padding: 0;
  display: inline-block;
  font-family: 'HelveticaNeueLTPro-Md'; }

.section-break {
  margin-top: 46px; }

.red-text {
  color: #ee3224; }

.green-text {
  color: #8cc63f; }

.button-text {
  margin-top: 20px;
  display: inline-block; }

.photo-upload-section .tnc-row {
  margin-bottom: 40px; }
  .photo-upload-section .tnc-row .black-text {
    font-size: 14px !important; }

.photo-upload-section .control-row {
  margin-bottom: 70px; }

input:focus.input-invalid {
  border: 3px solid #ee3224 !important; }

input.input-invalid {
  border: 1px solid #ee3224 !important; }

.checkbox-wrapper,
.link-wrapper {
  margin-top: 16px; }

#root .next-button-wrapper {
  margin-top: 40px;
  margin-bottom: 145px; }

.profile-page-padding {
  margin-top: 50px; }

.upload-photo {
  height: 194px;
  width: 162px;
  border-radius: 6px;
  border: solid 1px #c4c4c4;
  background-color: #ffffff;
  color: #00afdb;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: table; }
  .upload-photo:hover {
    border: solid 1px #00afdb; }
    .upload-photo:hover a {
      display: table-cell !important;
      background-color: rgba(0, 0, 0, 0.3);
      font-weight: bold;
      text-shadow: #fff 0px 0px 5px, #fff 0px 0px 10px, #fff 0px 0px 15px, #00539f 0px 0px 20px, #00539f 0px 0px 30px, #00539f 0px 0px 40px, #00539f 0px 0px 50px, #00539f 0px 0px 75px; }
  .upload-photo a,
  .upload-photo label {
    display: table-cell;
    text-align: center;
    vertical-align: middle; }
  .upload-photo:hover label {
    display: none !important; }

.photo-button-wrapper {
  display: -webkit-flex;
  display: flex; }

.photo-description {
  margin-top: 45px;
  max-width: 60%; }

.photo-description-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column; }

.profile-row {
  margin-bottom: 0; }

.section-header {
  font-weight: bold;
  font-size: 18px;
  padding: 0;
  display: inline-block;
  font-family: 'HelveticaNeueLTPro-Md'; }

.section-break {
  margin-top: 46px; }

.red-text {
  color: #ee3224; }

.green-text {
  color: #8cc63f; }

.button-text {
  margin-top: 20px;
  display: inline-block; }

.photo-upload-section .tnc-row {
  margin-bottom: 40px; }
  .photo-upload-section .tnc-row .black-text {
    font-size: 14px !important; }

.photo-upload-section .control-row {
  margin-bottom: 70px; }

.registration-container .row.no-margin {
  margin-bottom: 0; }

.side-note-background {
  border: 1px #ddd;
  background-color: white;
  margin-bottom: 10px;
  margin-top: 10px; }

.important-note {
  padding: 15px;
  background-color: #ffffff; }
  .important-note .important-note-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .important-note .important-note-header svg {
      margin-right: 5px;
      color: #00afdb; }
  .important-note .important-note-content svg {
    font-size: 10px;
    margin-right: 5px; }

.important-note-header-content,
.document-format,
.important-note-footer {
  font-weight: bold; }

.registration-header {
  padding-bottom: 15px; }

.registration-subheading {
  font-weight: 600;
  padding-bottom: 5px; }

.registration-list p {
  margin: 8px 0 0 0; }

.important-note-email {
  word-wrap: break-word; }

.important-note-phone {
  white-space: nowrap; }

.document-note-content {
  padding-bottom: 5px; }
  .document-note-content p {
    margin: 7px 0 0 0; }

.country-extra-component-text {
  padding-left: 10px;
  padding-right: 30px; }

.subheading-container {
  padding-left: 10px;
  padding-bottom: 20px;
  color: #888888;
  font-style: italic; }

.fit-and-proper-navigate-button:disabled {
  pointer-events: none;
  background-color: #DFDFDF !important;
  box-shadow: none;
  color: #9F9F9F !important;
  cursor: default; }

.reg-fit-and-prop-button-container button.fit-proper-next-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 28px;
  padding-bottom: 28px; }

.radio-groups {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 10px;
  border-bottom: solid 1px #c4c4c4;
  width: 100%; }
  .radio-groups .radio-label {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 10px; }
  .radio-groups .radio-option {
    display: -webkit-flex;
    display: flex; }
  .radio-groups .radio-display {
    display: -webkit-flex;
    display: flex; }
  @media (max-width: 1350px) and (min-width: 993x) {
    .radio-groups .registration-type-radio.radio-display {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: flex-start;
              justify-content: flex-start; }
    .radio-groups .radio-option {
      padding-bottom: 20px; } }
  @media (max-width: 700px) {
    .radio-groups .registration-type-radio.radio-display {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: flex-start;
              justify-content: flex-start; }
    .radio-groups .radio-option {
      padding-bottom: 20px; } }
  .radio-groups .radio-option-label {
    padding-right: 12px; }
  .radio-groups .label-icon {
    margin-right: 10px; }
  @media (min-width: 1375px) {
    .radio-groups .registration-fit-and-proper-radio-buttons {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row; } }
  @media (max-width: 1375px) {
    .radio-groups .registration-fit-and-proper-radio-options {
      padding-left: 17px; } }
  .radio-groups .registration-fit-and-proper-question-text {
    padding-right: 30px; }
  .radio-groups .registration-fit-and-proper-radio-buttons {
    margin-bottom: 30px; }

.country-multiselect .country-selector .country-select-header {
  display: block;
  margin: 20px 0 6px 20px; }

.country-multiselect .country-selector .country-dropdown {
  display: -webkit-inline-flex;
  display: inline-flex;
  padding-left: 18px; }

.country-multiselect .countries-selected-list {
  padding: 5px;
  margin: 10px; }
  .country-multiselect .countries-selected-list .country-item {
    display: inline-block;
    padding: 10px;
    padding-left: 15px;
    margin-top: 5px;
    margin-left: 5px;
    border-radius: 28px;
    background-color: #ffffff;
    color: #000000;
    min-width: 100px;
    font-weight: 200; }
    .country-multiselect .countries-selected-list .country-item .delete-button {
      cursor: pointer;
      margin-left: 25px; }

.country-add-button {
  min-width: 128px; }

.filepond--root {
  height: unset !important;
  display: -webkit-flex;
  display: flex; }

.filepond--browser {
  display: none; }

.filepond--data {
  display: none; }

.filepond--drop-label label {
  font-size: 1em;
  font-weight: bold; }

.filepond--list-scroller {
  display: none; }

.hideContainer {
  display: none; }

.upload-document-container {
  margin: 25px 0px; }

.upload-document-button {
  cursor: pointer;
  border: none;
  text-decoration: none;
  color: grey;
  background-color: #f4f4f4;
  letter-spacing: .5px;
  padding: 15px;
  border-radius: 28px;
  line-height: 16px;
  font-size: 12px;
  text-transform: none;
  float: right;
  border: solid 2px grey;
  min-height: 100%; }

.upload-document-button:focus {
  cursor: pointer;
  background-color: #f4f4f4; }

.upload-document-button:hover {
  background-color: #c4c4c4; }

.upload-document-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .upload-document-container .document-type-container .section-title {
    font-weight: bold; }
  .upload-document-container .document-type-container .info-icon {
    cursor: pointer;
    color: #666666;
    float: left; }
  .upload-document-container .tool-tip-container {
    width: 80%;
    max-width: 450px;
    padding: 25px;
    background-color: white;
    position: absolute;
    z-index: 1004; }
    .upload-document-container .tool-tip-container .close-icon {
      margin-top: -15px;
      margin-right: -15px;
      cursor: pointer; }
    .upload-document-container .tool-tip-container .innter-text {
      margin-top: 10px;
      color: #666666; }

.reg-application-uploaddoc-button button.btn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 28px;
  padding-bottom: 28px; }

.upload-document-subheading {
  color: grey; }

.upload-section .border-red {
  border: solid 1px #ee3224;
  color: #ee3224; }

.upload-section .invalid-file {
  margin-top: -5px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  background-color: #ee3224;
  color: #ffffff; }

.upload-section .uploaded-files {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px; }
  .upload-section .uploaded-files .uploaded-file-item {
    padding: 2px;
    height: 50px;
    margin: 5px;
    background-color: #e4e4e4;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .upload-section .uploaded-files .uploaded-file-item .uploaded-file-icon-and-name {
      padding-top: 4px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      max-width: 70%; }
    .upload-section .uploaded-files .uploaded-file-item .attach-icon {
      margin-right: 15px; }
    .upload-section .uploaded-files .uploaded-file-item .delete-button {
      margin-top: 10px;
      margin-right: 10px; }
    .upload-section .uploaded-files .uploaded-file-item .delete-button:hover {
      color: #00afdb; }
    .upload-section .uploaded-files .uploaded-file-item .disabled {
      color: #c4c4c4; }
    .upload-section .uploaded-files .uploaded-file-item .file-name {
      cursor: pointer;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .upload-section .uploaded-files .uploaded-file-item .file-name:hover {
      font-weight: bold; }
    .upload-section .uploaded-files .uploaded-file-item .uploaded-file-delete {
      cursor: pointer; }
    .upload-section .uploaded-files .uploaded-file-item .loading-button {
      margin-top: 10px;
      height: 45px;
      width: 45px;
      background-color: transparent;
      border: none; }
    .upload-section .uploaded-files .uploaded-file-item .uploaded-file-review {
      cursor: default; }

.registration-container .collapsible-header {
  background-color: inherit;
  border-bottom: none;
  display: inherit;
  padding: 0; }
  .registration-container .collapsible-header i {
    position: absolute;
    right: 25px;
    top: 38px; }
  .registration-container .collapsible-header .header-column {
    position: relative; }
  .registration-container .collapsible-header .row {
    margin-bottom: 0; }

.registration-container .collapsible-body {
  padding: inherit;
  border-bottom: none; }

.registration-container .collapsible {
  border: none;
  margin: inherit;
  box-shadow: none;
  -webkit-box-shadow: none; }
  .registration-container .collapsible .header-column i.add-icon {
    display: inherit; }
  .registration-container .collapsible .header-column i.remove-icon {
    display: none; }
  .registration-container .collapsible .active .header-column i.add-icon {
    display: none; }
  .registration-container .collapsible .active .header-column i.remove-icon {
    display: inherit; }
  .registration-container .collapsible .active .collapsible-body {
    display: block; }

.registration-container .registration-trade-header {
  background: #ffffff;
  text-align: center;
  margin-top: 20px;
  padding: 15px;
  border-radius: 50px; }
  .registration-container .registration-trade-header h2 {
    color: #03115c;
    margin: 20px 10px; }

.registration-container .row.no-margin {
  margin-bottom: 0; }

.registration-container .trade-row.col.s2 {
  margin-left: 0;
  margin-right: 5px;
  padding-left: 0;
  padding-right: 0; }

.registration-container .trade-row .application-section {
  display: table;
  min-height: 334px; }
  .registration-container .trade-row .application-section .row.complete {
    display: table-cell;
    vertical-align: bottom; }

.registration-container .registration-row {
  position: relative; }
  .registration-container .registration-row .certifier-complete {
    position: absolute;
    bottom: 0; }

.registration-container .trade-box {
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: inline-block;
  width: 28%;
  vertical-align: text-bottom;
  margin-right: 3px;
  position: relative; }
  .registration-container .trade-box.completed.P {
    background-image: -webkit-linear-gradient(#9561a8, #03115c);
    background-image: linear-gradient(#9561a8, #03115c); }
  .registration-container .trade-box.completed.G {
    background-image: -webkit-linear-gradient(#f78f1e, #c3511d);
    background-image: linear-gradient(#f78f1e, #c3511d); }
  .registration-container .trade-box.completed.D {
    background-image: -webkit-linear-gradient(#8cc63f, #328b39);
    background-image: linear-gradient(#8cc63f, #328b39); }
  .registration-container .trade-box.completed p {
    color: #ffffff; }
  .registration-container .trade-box.completed img {
    margin: auto;
    left: 0;
    right: 0;
    position: absolute;
    top: -100%;
    height: 100%;
    max-height: 100px;
    width: 100%;
    max-width: 100px; }
  .registration-container .trade-box.pending {
    background-color: #ffffff; }
    .registration-container .trade-box.pending img {
      display: none; }
  .registration-container .trade-box.suspended {
    background-image: -webkit-linear-gradient(#c4c4c4, #666666);
    background-image: linear-gradient(#c4c4c4, #666666); }
    .registration-container .trade-box.suspended p {
      color: #ffffff; }
    .registration-container .trade-box.suspended img {
      display: none; }
  .registration-container .trade-box.journeyman {
    padding-top: 40px;
    padding-bottom: 10px;
    margin-top: 210px; }
    .registration-container .trade-box.journeyman img {
      top: -100px; }
    .registration-container .trade-box.journeyman img.trophy-stars {
      top: -125px; }
    .registration-container .trade-box.journeyman.pending {
      padding-top: 77px; }
  .registration-container .trade-box.tradesman {
    padding-top: 100px;
    padding-bottom: 10px; }
    .registration-container .trade-box.tradesman img {
      top: -100px; }
    .registration-container .trade-box.tradesman img.trophy-stars {
      top: -125px; }
    .registration-container .trade-box.tradesman.pending {
      padding-top: 117px; }
  .registration-container .trade-box.certifying {
    padding-top: 160px;
    padding-bottom: 10px; }
    .registration-container .trade-box.certifying img {
      display: none; }
    .registration-container .trade-box.certifying.pending {
      padding-top: 177px; }
  .registration-container .trade-box img.discipline-img {
    position: absolute;
    width: 25%;
    max-width: 25px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -115px; }
  .registration-container .trade-box .trade-level {
    font-size: 10px;
    line-height: 14px;
    text-transform: capitalize; }
  .registration-container .trade-box .trade-status {
    font-size: 11px;
    line-height: 17px;
    font-weight: bold;
    text-transform: uppercase; }
    .registration-container .trade-box .trade-status.completed {
      color: #ffffff; }
    .registration-container .trade-box .trade-status.pending {
      color: #8cc63f; }
    .registration-container .trade-box .trade-status.not-available {
      color: #ee3224; }
  .registration-container .trade-box p {
    color: #000000;
    text-align: center;
    vertical-align: text-bottom; }

.registration-container .trade-heading {
  font-size: 14px;
  line-height: 17px;
  font-weight: bold; }

.registration-container .trade-name {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 19px;
  letter-spacing: 0; }

.registration-container .trophy-img-section {
  position: relative; }

.registration-container .application-name {
  font-weight: bolder;
  font-size: 20px; }

.registration-container .application-heading {
  font-weight: bold; }

.registration-container .application-condition p {
  margin-top: 5px; }

.registration-container .application-condition .row {
  margin-bottom: 0; }

.registration-container .application-condition i {
  text-align: center;
  width: 100%; }
  .registration-container .application-condition i.smaller {
    font-size: 8px;
    margin-top: 11px; }

.registration-container .certifier-suspended {
  text-align: center;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding-bottom: 5px;
  padding-top: 80px; }
  .registration-container .certifier-suspended h3 {
    padding: 0px 20px 80px 20px; }

.registration-container .certifier-complete {
  text-align: center;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding-bottom: 5px; }
  .registration-container .certifier-complete .trophy-img {
    max-width: 45%; }
  .registration-container .certifier-complete .discipline-img {
    position: absolute;
    width: 15%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 20%; }
  .registration-container .certifier-complete h3 {
    color: #03115c;
    font-weight: bold;
    line-height: 50px; }
  .registration-container .certifier-complete p {
    margin: 0 auto;
    font-size: 12px;
    color: #03115c; }
  .registration-container .certifier-complete .registration-congratulations-text {
    font-weight: bold; }

.registration-container .trade-splitter {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  top: 100px;
  font-size: 20px; }

.registration-container .trade-subtitle,
.registration-container .apply-now-btn,
.registration-container .invalid-registration-title {
  text-align: center; }

.registration-container .fit-text {
  margin-top: 20px;
  text-align: right;
  font-style: italic;
  font-size: 12px; }
  .registration-container .fit-text .btn-small {
    padding: 10px 25px;
    font-size: 12px; }

.fit-modal-body {
  padding: 0 30px 5px 30px; }
  .fit-modal-body p {
    text-align: left; }

.fit-model-icon {
  cursor: pointer;
  margin: 10px 10px 0 0; }

.registration-review-container {
  border-bottom: solid 1px #c4c4c4;
  margin-bottom: 10px; }

.review-heading {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  .review-heading .review-title {
    color: #808080;
    font-weight: 700; }

.review-edit-section {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end; }
  .review-edit-section .review-edit-button {
    color: #808080;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .review-edit-section .edit-icon {
    font-size: 18px;
    margin-right: 5px;
    color: #808080; }

.fit-and-proper-review-index {
  margin-right: 10px; }

.fit-and-proper-review-questions {
  margin-bottom: 10px; }

.fit-and-proper-review-answers {
  font-weight: 700; }

.review-details, .review-description {
  margin-top: 10px;
  margin-bottom: 10px; }

.review-description {
  text-transform: uppercase; }

.review-subheading {
  font-weight: 700; }

.review-checkbox-label input[type="checkbox"] {
  opacity: 1;
  position: static; }

.review-checkbox-label .checkbox-input {
  margin-right: 15px; }

.review-checkbox-label label {
  font-size: inherit;
  color: black; }

.upload-section .uploaded-files {
  margin-left: 0px;
  margin-right: 10px;
  margin-bottom: 30px; }
  .upload-section .uploaded-files .uploaded-file-item {
    padding: 2px;
    height: 50px;
    margin: 5px;
    background-color: #e4e4e4;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .upload-section .uploaded-files .uploaded-file-item .uploaded-file-icon-and-name {
      padding-top: 4px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      max-width: 70%; }
    .upload-section .uploaded-files .uploaded-file-item .file-name {
      cursor: pointer;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.questions-page-divider {
  display: -webkit-flex;
  display: flex;
  grid-gap: 50px;
  gap: 50px; }
  .questions-page-divider .questions-section.fit-and-proper-page {
    min-width: 600px; }
  .questions-page-divider .important-note {
    padding: 40px;
    background-color: #ffffff; }
    .questions-page-divider .important-note .important-note-header {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .questions-page-divider .important-note .important-note-header svg {
        margin-right: 5px;
        color: #00afdb; }
    .questions-page-divider .important-note .important-note-content svg {
      font-size: 10px;
      margin-right: 5px;
      color: #00afdb; }
    .questions-page-divider .important-note .important-note-content .important-note-sublist {
      margin-left: 15px; }

.view-only-note {
  color: #ee3224; }

.contact-numnber {
  color: #00afdb; }

#cpd-section {
  margin-bottom: 2em;
  font-family: 'HelveticaNeueLTPro-Md'; }
  #cpd-section a {
    color: #00afdb; }

#cpd-section-header {
  font-size: 30px;
  font-weight: bold; }

#cpd-section-sub-header {
  color: #666666;
  font-size: 16px;
  margin-top: 0; }

#cpd-status-header {
  color: #000000;
  font-size: 30px;
  margin-bottom: 0;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.2em; }

#cpd-sub-description {
  color: #000000;
  font-size: 16px;
  margin-top: 15px; }

#cpd-description {
  color: #000000;
  font-size: 30px;
  padding-bottom: 30px;
  margin-top: 5px;
  margin-bottom: 0;
  line-height: 1.2em; }
  #cpd-description.active-blue {
    color: #00afdb; }

.ml-0 {
  margin-left: 0px !important; }

.mr-1 {
  margin-right: 1rem; }

.ml-1 {
  margin-left: 1rem; }

#modal-footer .btn-outline {
  background-color: transparent;
  color: #00afdb;
  border: solid 2px #00afdb; }

.modal-footer {
  padding-bottom: 50px; }
  .modal-footer a {
    margin: 0 15px; }

.modal-content-padding {
  padding: 55px;
  padding-bottom: 0; }

.modal-content-line-height {
  line-height: 1.2; }

.generic-modal {
  z-index: 1003;
  top: 10%;
  width: 500px;
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fafafa;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2); }

.generic-modal-overlay {
  z-index: 1002;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); }

.button-margin {
  margin: 0 10px; }

.left-section {
  margin-left: 25%;
  padding-bottom: 10px; }

.radio-align-left {
  text-align: left;
  padding-bottom: 20px; }

.datepicker-controls input[type=text]:not(.browser-default) {
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  height: 3rem;
  font-size: 16px;
  padding: 0;
  box-shadow: none;
  box-sizing: content-box;
  -webkit-transition: border .3s, -webkit-box-shadow .3s;
  -webkit-transition: box-shadow .3s, border .3s;
  transition: box-shadow .3s, border .3s; }

.blue-link-design {
  color: #00afdb !important;
  border: none !important;
  background-color: transparent !important;
  text-align: center !important;
  cursor: pointer !important; }

.datepicker-date-display {
  background-color: #00afdb; }

.datepicker-cancel, .datepicker-clear, .datepicker-today, .datepicker-done {
  color: #00afdb; }

.datepicker-table td.is-selected {
  background-color: #00afdb; }

.datepicker-table td.is-today {
  color: #00afdb; }

.datepicker-day-button:focus {
  background-color: rgba(0, 175, 219, 0.25); }

.dropdown-content li > a, .dropdown-content li > span {
  color: #00afdb; }

.left-section-35 {
  margin-left: 35%;
  padding-bottom: 10px; }

.radio-align-left {
  text-align: left;
  padding-bottom: 20px; }

.exam .customHr {
  border-top: 1px solid lightgray;
  padding-top: 10px; }

.exam .thickerLine {
  border-bottom: 3px solid gray; }

.exam .removeBorder {
  border-bottom: 0; }

.exam .grey-text {
  color: gray !important; }

.exam .exam-link-button {
  background-color: transparent;
  color: #039be5 !important;
  cursor: pointer;
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
  -webkit-tap-highlight-color: transparent; }

.exam .generic-table tr:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.exam .eligibility-report .eligibility-course-list .eligibility-course-name:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

.exam .eligibility-report .eligibility-course-list .eligibility-course-name {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
  padding: 15px; }

.exam .eligibility-report .control-group {
  padding: 10px;
  margin-bottom: 20px; }
  .exam .eligibility-report .control-group .btn {
    margin-right: 20px;
    margin-bottom: 10px; }

#modal-footer .btn-outline {
  background-color: transparent;
  color: #00afdb;
  border: solid 2px #00afdb; }

.modal-footer {
  padding-bottom: 50px; }
  .modal-footer a {
    margin: 0 15px; }

.modal-content-padding {
  padding: 55px;
  padding-bottom: 0; }

.modal-content-line-height {
  line-height: 1.2; }

.generic-modal {
  z-index: 1003;
  top: 10%;
  width: 500px;
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fafafa;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2); }

.generic-modal-overlay {
  z-index: 1002;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); }

.button-margin {
  margin: 0 10px; }

.left-section {
  margin-left: 25%;
  padding-bottom: 10px; }

.radio-align-left {
  text-align: left;
  padding-bottom: 20px; }

.generic-table {
  margin-top: 20px; }
  .generic-table .supervisor-body .table-heading {
    border-top: 0; }

.table-data {
  padding: 15px 0; }

.inline-icon {
  vertical-align: bottom;
  margin-right: 5px;
  float: left; }

.height-adjust {
  margin-top: 2px; }

.block-text {
  display: block; }

.status-cell {
  max-width: 300px; }

.my-supervisor-text {
  padding-bottom: 10px; }

.second-line {
  margin-left: 29px;
  margin-right: 10px; }

.supervision-search-text {
  margin-left: 20px; }

.table-text-box {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.supervisor-body .table-text-box input {
  min-width: 75px; }

.payment-column {
  font-size: 14px;
  color: #666666; }

.receipt-item-first, .receipt-item-middle {
  border-bottom: none; }

.receipt-item-first > td, .receipt-item-middle > td {
  padding-bottom: 7.5px; }

.receipt-item-last > td, .receipt-item-middle > td {
  padding-top: 6px; }

.invalid-message-block {
  margin: 5px 0 0 0; }

.btn-search-margin {
  margin-top: 20px;
  margin-bottom: 10px; }

.search-switch-link {
  font-size: 14px;
  font-weight: 500;
  font-family: 'HelveticaNeueLTPro-Md';
  color: #00afdb;
  vertical-align: middle; }

.multi-line-cell {
  margin-bottom: 3px; }

.cell-valign {
  vertical-align: top; }

.btn-toggle {
  line-height: 1em;
  height: auto;
  padding: 10px 10px 5px 10px;
  margin: -7px 5px 0px 5px; }

.btn-toggle:active,
.btn-toggle:hover {
  background-color: #e4e4e4; }

.min-cell-width {
  table-layout: fixed;
  width: 1px;
  /* min non-zero value */ }

.pr-highlighted-result {
  /*
      halfway between --pgdb-verylightgrey (#f5f5f5) and white (#fff) 
      as the definition is for the background to be #fff with opacity of 0.5
      where the rest of the page has a background color of #f5f5f5
    */
  background-color: #fafafa; }

.pr-row-hover {
  cursor: pointer; }

.pr-row-hover:hover {
  background-color: #fafafa; }

.pr-separate-border {
  border-collapse: separate; }

.pr-row-right-pad {
  padding-right: 50px; }

.pr-row-left-indent {
  padding-left: 45px; }

.pr-table {
  border-collapse: separate; }

.pr-loading-spinner {
  background-image: url(/images/loading-spinner-1.svg);
  background-position-x: 10px;
  background-position-y: 50%;
  background-repeat: no-repeat;
  padding-left: 60px;
  padding-top: 10px;
  padding-bottom: 10px; }

#root .pr-table-header {
  border-top: 0; }

#root .pr-sticky-table-header {
  font-weight: bold;
  border-top: 1px solid #d6d6d6;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #f4f4f4; }

#root .pr-row-section-header {
  padding: 6px 0;
  color: #9b9b9b;
  border-bottom: 1px #d6d6d6 solid;
  font-size: 12px;
  margin-left: 15px; }

.pr-row-section-header .col {
  padding: 6px 0; }

.pr-row-no-vert-pad {
  padding-top: 0;
  padding-bottom: 0; }

.pr-row-small-mb {
  margin-bottom: 10px; }

.pr-text-active {
  color: #8cc63f; }

.pr-text-inactive {
  color: #c4c4c4; }

.pr-subtext {
  color: #666666; }

.pr-advanced-row-footer {
  margin-bottom: 20px; }

.pr-row-no-border {
  border-bottom: 0; }

.drop-down {
  margin-bottom: 20px; }

.condition-modal {
  width: 700px; }

@font-face {
  font-family: 'Material-Icons-Round';
  src: url(/static/media/Material-Icons-Round.6524aa1f.ttf) format("truetype"); }

@font-face {
  font-family: 'HelveticaNeueLTPro-Roman';
  src: url(/static/media/38CC3B_0_0.86a8dd2b.eot);
  src: url(/static/media/38CC3B_0_0.86a8dd2b.eot?#iefix) format("embedded-opentype"), url(/static/media/38CC3B_0_0.e0dd0921.woff2) format("woff2"), url(/static/media/38CC3B_0_0.3b7662dc.woff) format("woff"), url(/static/media/38CC3B_0_0.ca919ae8.ttf) format("truetype"); }

@font-face {
  font-family: 'HelveticaNeueLTPro-Md';
  src: url(/static/media/38CC3B_1_0.b5197c4e.eot);
  src: url(/static/media/38CC3B_1_0.b5197c4e.eot?#iefix) format("embedded-opentype"), url(/static/media/38CC3B_1_0.0738e0a8.woff2) format("woff2"), url(/static/media/38CC3B_1_0.89df1c20.woff) format("woff"), url(/static/media/38CC3B_1_0.9fdf36f6.ttf) format("truetype"); }

@font-face {
  font-family: 'HelveticaNeueLTPro-Bd';
  src: url(/static/media/38CC3B_2_0.bb52ad4e.eot);
  src: url(/static/media/38CC3B_2_0.bb52ad4e.eot?#iefix) format("embedded-opentype"), url(/static/media/38CC3B_2_0.fa12cbfd.woff2) format("woff2"), url(/static/media/38CC3B_2_0.09691427.woff) format("woff"), url(/static/media/38CC3B_2_0.5829e8dd.ttf) format("truetype"); }

i.round {
  font-family: 'Material-Icons-Round'; }

body {
  background-color: #f4f4f4;
  display: -webkit-flex !important;
  display: flex !important;
  min-height: 100vh !important;
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
  font-family: 'HelveticaNeueLTPro-Roman' !important; }

* {
  font-family: 'HelveticaNeueLTPro-Roman'; }

main {
  -webkit-flex: 1 0 auto !important;
          flex: 1 0 auto !important; }

.title-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.license-application-container {
  margin: 0px 20px 0px 20px; }

.licence-application-banner {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  background-image: -webkit-linear-gradient(#0058a8, #03115c);
  background-image: linear-gradient(#0058a8, #03115c);
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: center;
          align-self: center;
  border-radius: 15px;
  padding: 5px; }

.licence-application-title {
  margin-top: 20px;
  color: white;
  padding-left: 10px;
  padding-right: 10px; }

.licence-application-button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  border: none;
  outline: 0;
  text-decoration: none;
  color: #fff;
  padding: 15px 45px 15px 45px;
  border-radius: 28px;
  background-color: #00afdb;
  font-family: 'HelveticaNeueLTPro-Md';
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }

.btn,
.btn-large,
.btn-small {
  padding-top: 20px;
  padding-bottom: 35px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 28px;
  background-color: #00afdb;
  text-align: center;
  font-family: 'HelveticaNeueLTPro-Md';
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
  text-transform: none; }

.btn:hover,
.btn-large:hover,
.btn-small:hover,
.btn:focus,
.btn-large:focus,
.btn-small:focus {
  background-color: #00afdb; }

.btn.btn-inactive {
  background-color: #f5f5f5;
  color: #00afdb;
  border-width: 2px;
  border-style: solid;
  padding: 18px 28px 33px 28px;
  /* removes 2px to account for the border */ }
  .btn.btn-inactive:hover, .btn.btn-inactive:focus {
    background-color: #f5f5f5;
    border-width: 2px;
    border-style: solid; }

.registration-application-button {
  border: none;
  outline: 0;
  text-decoration: none;
  color: #fff;
  letter-spacing: .5px;
  padding: 15px;
  border-radius: 28px;
  background-color: #00afdb;
  text-align: center;
  font-family: 'HelveticaNeueLTPro-Md';
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  float: right;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  min-height: 100%; }

.registration-application-button:focus {
  background-color: #00afdb; }

.vline,
hr.vertical {
  border: none;
  border-right: 1px solid #c4c4c4;
  height: 438px;
  width: 1px; }

::-ms-clear {
  /* Stops Edge from displaying the X in edited input fields*/
  display: none; }

.input-header {
  margin: 20px 0 6px 20px;
  display: inline-block; }

.no-bottom-margin {
  margin-bottom: 0; }

/**
      TODO: end
  **/
.grey2-text {
  color: #666666; }

.grey2-Note-text {
  color: #666666;
  font-style: italic; }

.smaller {
  font-size: 12px; }

.small {
  font-size: 14px; }

.medium-text {
  font-size: 18px; }

.far-left {
  /* left: -250px; */
  padding: 0;
  padding-top: 5px !important;
  padding-left: 40px; }

@media (min-width: 993px) {
  .logo {
    max-height: 30px;
    margin-left: 40px; } }

@media (max-width: 992px) {
  .logo {
    max-height: 30px; } }

.link-underline {
  /* border-bottom: 1px solid #039be5 !important; */ }

/* Navbar */
nav {
  height: 80px !important;
  line-height: 80px !important; }
  nav .button-collapse i {
    height: 80px !important;
    line-height: 80px !important; }
  nav .brand-logo {
    font-size: 1.6rem; }

.dashboard-panels {
  box-sizing: border-box;
  height: 301px;
  border: 1px solid #f0f3f4;
  border-radius: 10px !important;
  background-color: #ffffff; }

.card-layout.disabled {
  display: none; }

.card-header {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  text-align: center; }

.card-line {
  box-sizing: border-box;
  height: 1px;
  width: 16px;
  border: 2px solid;
  border-radius: 100px !important;
  margin: auto;
  margin-bottom: 20px; }

.notification-icon {
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 50%;
  display: inline-block;
  border: 7px solid #ee3224; }

.arrow {
  line-height: 20px;
  padding-right: 20px;
  font-size: 20px; }

.dashboard-link-header {
  font-weight: bold;
  font-size: 18px;
  padding: 15px 5px;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px; }

.loading-screen-user-message {
  z-index: 10000000002;
  position: absolute;
  top: 54%;
  width: 100%;
  padding: 10px;
  text-align: center; }

@media (max-width: 992px) {
  .loading-screen-user-message {
    font-size: smaller; }
    .loading-screen-user-message h3,
    .loading-screen-user-message h4 {
      font-size: small; } }

.dashboard-special-user-message {
  color: #f78f1e;
  text-align: center;
  padding: 10px 100px; }

.modal-easter {
  width: 40% !important;
  height: 60% !important; }

.sub-text {
  margin-top: -20px;
  font-size: 18px;
  display: inline-block; }

.bold {
  font-weight: bold; }

.helvetica-bold {
  font-family: 'HelveticaNeueLTPro-Bd'; }

.exam-header {
  border-top: 1px solid #d6d6d6;
  font-size: 12px;
  line-height: 14px;
  font-weight: 100;
  padding: 40px 0px 10px 40px; }

.table-heading {
  border-top: 1px solid #d6d6d6;
  font-size: 12px;
  line-height: 14px;
  font-weight: 100; }

.table-header {
  border-top: 1px solid #d6d6d6;
  font-size: 12px;
  line-height: 14px;
  font-weight: 100;
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px; }

.table-item {
  margin-top: 0;
  margin-bottom: 0; }

.table-text-box {
  max-width: 210px; }

.table-set-size {
  width: 400px; }

.checkbox-blue[type='checkbox'].filled-in:checked + label:after {
  border: 2px solid #ff9800 !important;
  background-color: #ff9800 !important; }

.input-center {
  width: 120% !important;
  text-align: center !important; }

.footer-copyright {
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c4c4c4;
  padding-left: 0px !important; }

.footer-menu {
  opacity: 0.7;
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666666;
  padding-top: 25px !important;
  margin-bottom: -20px !important; }

footer {
  padding-top: 70px !important;
  padding-bottom: 30px !important; }
  footer div > div > div > p {
    font-size: 14px !important; }

.grey-text {
  color: #c4c4c4; }

.darkgrey-text {
  color: #666666; }

.activeblue-text {
  color: #00afdb; }

.medium-font {
  font-family: 'HelveticaNeueLTPro-Md'; }

.medium-font-size {
  font-size: 16px; }

.position-absolute {
  position: absolute; }

.disable-wordwrap {
  white-space: nowrap; }

.inner-page-container {
  max-width: 800px;
  margin: auto; }

.text-align-left {
  text-align: left; }

.icon-left-pad {
  padding-left: 29px;
  /* icon=24px, existing padding=5px */ }

.no-left-pad {
  padding-left: 0; }

.registration-payment-table {
  font-size: 14px; }

.registration-payment-font-size {
  font-size: 14px !important; }

.small-right-pad {
  padding-right: 10px; }

/* ------------------------------- */
/* Text styles */
.H8 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 50px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h1 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MH8 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h2 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h3 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h4 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MH1 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h5 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h6 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.H7 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body1 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body2 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body3 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body4 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body5 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body6 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody4 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody5 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody6 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body7 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body8 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body9 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody10 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody11 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody12 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody13 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body10 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody15 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 8px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

/* ------------------------------- */
.title-bold {
  object-fit: contain;
  font-family: 'HelveticaNeueLTPro-Md';
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-left: -5px !important; }

.sub-title {
  object-fit: contain;
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #666666; }

a.large {
  object-fit: contain;
  opacity: 0.7;
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #00afdb; }

p.medium {
  object-fit: contain;
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #666666; }

p.footer {
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666666; }

.input-field > label:not(.label-icon).active {
  object-fit: contain !important;
  font-family: 'HelveticaNeueLTPro-Roman' !important;
  font-size: 17px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #666666 !important; }

#root .input-invalid {
  border: 1px solid #ee3224; }

nav ul a.active {
  color: #00afdb !important;
  border-bottom: 2px solid #00afdb; }

/* -- underline -- */
.link {
  font-family: 'HelveticaNeueLTPro-Md' !important;
  text-decoration: none;
  position: relative;
  color: #00afdb !important;
  padding-bottom: 4px; }
  .link:after {
    content: '';
    height: 1px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0%;
    background: #00afdb;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease; }
  .link:hover:after {
    width: 100%;
    left: 0; }

/* ---------------- */
#dropdown1 li > * {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* line-height: 20px !important; */
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 14px;
  color: #666666; }

#dropdown1 li:first-child {
  margin-top: 20px; }

#dropdown1 li:last-child {
  margin-top: 20px;
  margin-bottom: 20px; }

.text-red {
  color: #ee3224; }

/* @media only screen and (min-width: 601px)
  nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
      height: 20px !important;
      line-height: 20px !important;
  } */
.licence-padding {
  margin-top: 40px !important;
  padding-bottom: 30px !important; }

.password-padding {
  padding-bottom: 19px !important; }

.login-btn {
  margin-top: 10px !important; }

.company-padding {
  margin-top: 27px !important; }

.alert {
  padding: 0px !important; }

.alert_box {
  border-radius: 6px !important; }

/* Address Finder Result Box */
ul.af_list {
  z-index: 5;
  list-style: none;
  margin: 0;
  border: solid 1px #999;
  border-radius: 5px;
  background: #fff;
  font-size: 1em; }

li.af_item {
  cursor: pointer;
  padding: 0.5em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

li.af_hover {
  background-color: #0058a8;
  color: #fff; }

li.af_footer {
  font-size: 0.8em;
  color: #666;
  text-align: right;
  display: none; }

.cursor-pointer {
  cursor: pointer; }

.manual-address-link {
  float: right;
  cursor: pointer;
  padding-top: 0.5em; }

.manual-address-fields {
  padding: 0.5em 1em 1.5em 1em;
  background-color: #e8e8e8; }

.af_empty {
  padding: 5px; }

.btn-small.manual-save {
  margin-top: 1em; }

.btn-small.manual-cancel {
  margin-top: 1em;
  margin-left: 0.5em; }

.btn-ml-30 {
  margin-left: 30px; }

button.btn-link {
  background: none;
  border-radius: 5px;
  color: #00afdb;
  font-weight: 500;
  font-family: 'HelveticaNeueLTPro-Md';
  text-transform: none; }

.button-colour-invert {
  background: none;
  border: solid 1px #00afdb;
  color: #00afdb;
  box-shadow: none; }
  .button-colour-invert:hover {
    color: white; }

select.select-input {
  outline: 0;
  /* removes border when selecting */
  border: 1px solid #c4c4c4; }

select.select-highlighted, select:focus, select:active {
  border: 3px solid #00afdb;
  padding: 13px; }

option {
  font-size: 16px; }

#root input[type='text'].text-input:focus, #root input[type='text'].text-input:active {
  border: 3px solid #00afdb;
  box-shadow: none;
  padding: 13px; }

#root .text-input {
  border: 1px solid #c4c4c4; }

input[type='text'].input-disabled:disabled {
  background-color: #ddd;
  border: 1px solid #666666;
  padding: 15px; }
  input[type='text'].input-disabled:disabled::-webkit-input-placeholder {
    color: #000; }
  input[type='text'].input-disabled:disabled::-ms-input-placeholder {
    color: #000; }
  input[type='text'].input-disabled:disabled::placeholder {
    color: #000; }

.inline-text {
  display: inline-block; }

.subheader {
  font-family: 'HelveticaNeueLTPro-Md'; }

.invalid-message {
  color: #ee3224; }

.input-label-horizontal {
  font-size: 100%;
  margin-left: 20px; }

.hide-element {
  display: none; }

/* Checkbox */
[type='checkbox'] + span:not(.lever) {
  height: 20px; }

[type='checkbox'].filled-in + span:not(.lever):after {
  border-radius: 3px; }

[type='checkbox'].filled-in:checked + span:not(.lever):after {
  border: 2px solid #00afdb;
  background-color: #00afdb;
  width: 24px;
  height: 24px; }

[type='checkbox'].filled-in:not(:checked) + span:not(.lever):after {
  height: 24px;
  width: 24px;
  border: 2px solid #c4c4c4; }

[type='checkbox'].filled-in:checked + span:not(.lever):before {
  width: 10px;
  height: 16px; }

[type='radio']:checked + span:after {
  border: 2px solid #00afdb; }

[type='radio'].with-gap:checked + span:before, [type='radio'].with-gap:checked + span:after {
  border: 2px solid #00afdb; }

[type='radio']:checked + span:after,
[type='radio'].with-gap:checked + span:after {
  background-color: #00afdb; }

[type='radio'] + span:before, [type='radio'] + span:after {
  width: 24px;
  height: 24px; }

[type='radio']:not(:checked) + span,
[type='radio']:checked + span {
  line-height: 30px; }

.input-error {
  max-width: 210px;
  background-color: #ee3224;
  z-index: 20;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 8px;
  font-size: 14px;
  color: #fff; }

.input-error:after {
  content: '';
  position: absolute;
  top: 0;
  right: 70%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #ee3224;
  margin-top: -20px; }

.arrow-text {
  padding-left: 45px;
  display: flow-root;
  margin-top: -25px; }

/* Removed blue outline from chrome */
:focus {
  outline: none !important; }

ul.bullet li {
  padding-left: 45px;
  padding-bottom: 5px;
  position: relative; }
  ul.bullet li:before {
    content: 'arrow_forward';
    font-family: 'Material Icons';
    position: absolute;
    font-size: 20px;
    left: 0;
    top: 0;
    line-height: 20px;
    -webkit-font-feature-settings: 'liga';
            font-feature-settings: 'liga'; }

ul.cross li {
  padding-left: 45px;
  padding-bottom: 5px;
  position: relative; }
  ul.cross li:before {
    content: 'clear';
    font-family: 'Material Icons';
    position: absolute;
    font-size: 22px;
    left: 0;
    top: 0;
    line-height: 24px;
    -webkit-font-feature-settings: 'liga';
            font-feature-settings: 'liga'; }

.loading-button {
  background-image: url(/images/loading-spinner-1.svg);
  background-position-x: 10px;
  background-position-y: 50%;
  background-repeat: no-repeat;
  padding-left: 60px; }

.btn-primary {
  margin-left: 5px;
  margin-right: 5px; }

.btn-secondary {
  margin-left: 5px;
  margin-right: 5px;
  border-color: #00afdb;
  color: #00afdb;
  background-color: #ffffff !important;
  text-align: center; }

.btn-secondary:hover {
  border-color: #00afdb;
  color: #00afdb;
  background-color: #ffffff !important; }

.profile-photo-container > .photo-editor {
  padding: 0px; }
  .profile-photo-container > .photo-editor > .avatar-frame > canvas {
    border: solid 4px #666666;
    border-radius: 0;
    background-image: url(/images/grid.svg);
    background-size: auto auto; }
  .profile-photo-container > .photo-editor > .avatar-control-frame {
    position: relative;
    max-width: 320px;
    height: 380px;
    margin-left: -12px !important;
    background-color: #666666;
    margin-left: 0px; }
    .profile-photo-container > .photo-editor > .avatar-control-frame > .control-button-list {
      display: inline-grid;
      padding-left: 45px;
      padding-right: 100px;
      padding-top: 10px;
      margin-top: 10px; }
    .profile-photo-container > .photo-editor > .avatar-control-frame ul > li {
      color: #f5f5f5;
      display: -webkit-inline-flex;
      display: inline-flex;
      margin-top: 20px;
      background: transparent;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .profile-photo-container > .photo-editor > .avatar-control-frame ul > li:hover {
        color: #00afdb; }
      .profile-photo-container > .photo-editor > .avatar-control-frame ul > li > span {
        cursor: pointer;
        padding-left: 10px;
        padding-left: 10px; }

.profile-photo-container > .controls button {
  margin-right: 20px; }

.callout-body > .canvasCard {
  width: 350px; }

.preview-modal {
  background: transparent;
  border: none;
  display: block;
  position: fixed;
  color: #000000;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1003;
  margin: auto; }
  .preview-modal:focus {
    outline: none; }

.card-preview:focus {
  outline: none; }

.summary hr {
  border: none;
  height: 1px;
  color: black;
  /* Old IE */
  background-color: black;
  /* Modern Browsers */ }

.summary .thick {
  height: 3px; }

.summary .drawer {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  font-family: 'HelveticaNeueLTPro-Bd'; }
  .summary .drawer .mock-collapsible-header {
    padding: 15px; }
  .summary .drawer li {
    display: inline; }
    .summary .drawer li .drawer-title {
      background-color: inherit;
      border: none;
      display: -webkit-flex;
      display: flex;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000; }
      .summary .drawer li .drawer-title .header-title {
        margin-right: auto; }
      .summary .drawer li .drawer-title .edit-btn {
        color: #00afdb;
        color: #00afdb;
        font-size: 14px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        margin: 0 20px; }
      .summary .drawer li .drawer-title .caret {
        margin-right: 0;
        -webkit-transition: -webkit-transform 0.2s ease;
        transition: -webkit-transform 0.2s ease;
        transition: transform 0.2s ease;
        transition: transform 0.2s ease, -webkit-transform 0.2s ease; }
    .summary .drawer li.active .drawer-title .caret {
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg);
      -webkit-transform-origin: 0.6em 0.5em;
              transform-origin: 0.6em 0.5em; }
    .summary .drawer li .default-cursor {
      cursor: auto; }
    .summary .drawer li .drawer-outer-body {
      padding: 0rem;
      border: none; }
      .summary .drawer li .drawer-outer-body .drawer-inner-body {
        padding: 0rem 2rem; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .licence-number-cell,
        .summary .drawer li .drawer-outer-body .drawer-inner-body .name-cell {
          width: 150px; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .cpd-cell {
          padding-left: 80px; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .shorter {
          margin-bottom: 5px; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .subtitle {
          font-size: 14px;
          font-family: 'HelveticaNeueLTPro-Roman';
          color: #c4c4c4; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .description {
          font-size: 16px;
          font-family: 'HelveticaNeueLTPro-Md'; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .terms-and-cond {
          line-height: 1.2rem;
          font-size: 12px; }
    .summary .drawer li .table-container {
      font-family: 'HelveticaNeueLTPro-Roman'; }
      .summary .drawer li .table-container .row {
        margin-bottom: 0; }
  .summary .drawer .licence-table tr,
  .summary .drawer .licence-table th {
    border: none; }
  .summary .drawer .licence-table .licence-cell {
    width: 450px; }
  .summary .drawer .licence-table .disciplinary-cell {
    padding-left: 29px; }
  .summary .drawer .licence-table .amount-header {
    text-align: right; }
  .summary .drawer .licence-table .amount-cell {
    width: 9%;
    text-align: right;
    padding-right: 5px; }

.summary .total-table td {
  font-weight: bold;
  text-align: right; }

.summary .total-table .currency-cell {
  width: 10px; }

.summary .total-table .total-cell {
  width: 12.5%;
  padding-right: 35px; }

.summary .total-table .total-label-cell {
  width: 20%;
  text-align: right;
  padding-right: 25px; }

.summary .generic-table tr:first-child {
  border-bottom: none; }

.summary .generic-table .licence-cell {
  border-bottom: none;
  font-weight: bold; }

.summary .supervisor-table .supervisor-licence-cell {
  width: 450px; }

.question-and-answer > .col {
  padding-top: 15px;
  padding-bottom: 15px; }

.question-and-answer .s2 {
  text-align: right;
  font-weight: bold; }

.summary hr {
  border: none;
  height: 1px;
  color: black;
  /* Old IE */
  background-color: black;
  /* Modern Browsers */ }

.summary .thick {
  height: 3px; }

.summary .drawer {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  font-family: 'HelveticaNeueLTPro-Bd'; }
  .summary .drawer .mock-collapsible-header {
    padding: 15px; }
  .summary .drawer li {
    display: inline; }
    .summary .drawer li .drawer-title {
      background-color: inherit;
      border: none;
      display: -webkit-flex;
      display: flex;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000; }
      .summary .drawer li .drawer-title .header-title {
        margin-right: auto; }
      .summary .drawer li .drawer-title .edit-btn {
        color: #00afdb;
        color: #00afdb;
        font-size: 14px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        margin: 0 20px; }
      .summary .drawer li .drawer-title .caret {
        margin-right: 0;
        -webkit-transition: -webkit-transform 0.2s ease;
        transition: -webkit-transform 0.2s ease;
        transition: transform 0.2s ease;
        transition: transform 0.2s ease, -webkit-transform 0.2s ease; }
    .summary .drawer li.active .drawer-title .caret {
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg);
      -webkit-transform-origin: 0.6em 0.5em;
              transform-origin: 0.6em 0.5em; }
    .summary .drawer li .default-cursor {
      cursor: auto; }
    .summary .drawer li .drawer-outer-body {
      padding: 0rem;
      border: none; }
      .summary .drawer li .drawer-outer-body .drawer-inner-body {
        padding: 0rem 2rem; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .licence-number-cell,
        .summary .drawer li .drawer-outer-body .drawer-inner-body .name-cell {
          width: 150px; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .cpd-cell {
          padding-left: 80px; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .shorter {
          margin-bottom: 5px; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .subtitle {
          font-size: 14px;
          font-family: 'HelveticaNeueLTPro-Roman';
          color: #c4c4c4; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .description {
          font-size: 16px;
          font-family: 'HelveticaNeueLTPro-Md'; }
        .summary .drawer li .drawer-outer-body .drawer-inner-body .terms-and-cond {
          line-height: 1.2rem;
          font-size: 12px; }
    .summary .drawer li .table-container {
      font-family: 'HelveticaNeueLTPro-Roman'; }
      .summary .drawer li .table-container .row {
        margin-bottom: 0; }
  .summary .drawer .licence-table tr,
  .summary .drawer .licence-table th {
    border: none; }
  .summary .drawer .licence-table .licence-cell {
    width: 450px; }
  .summary .drawer .licence-table .disciplinary-cell {
    padding-left: 29px; }
  .summary .drawer .licence-table .amount-header {
    text-align: right; }
  .summary .drawer .licence-table .amount-cell {
    width: 9%;
    text-align: right;
    padding-right: 5px; }

.summary .total-table td {
  font-weight: bold;
  text-align: right; }

.summary .total-table .currency-cell {
  width: 10px; }

.summary .total-table .total-cell {
  width: 12.5%;
  padding-right: 35px; }

.summary .total-table .total-label-cell {
  width: 20%;
  text-align: right;
  padding-right: 25px; }

.summary .generic-table tr:first-child {
  border-bottom: none; }

.summary .generic-table .licence-cell {
  border-bottom: none;
  font-weight: bold; }

.summary .supervisor-table .supervisor-licence-cell {
  width: 450px; }

.question-and-answer > .col {
  padding-top: 15px;
  padding-bottom: 15px; }

.question-and-answer .s2 {
  text-align: right;
  font-weight: bold; }

.licence-payment-view-table {
  margin-bottom: 20px; }
  .licence-payment-view-table tr {
    border-top: solid 1px #e4e4e4;
    border-bottom: solid 1px #e4e4e4; }
    .licence-payment-view-table tr:first-child {
      border-top-color: #c4c4c4; }
    .licence-payment-view-table tr:last-child {
      border-bottom-color: #c4c4c4; }
    .licence-payment-view-table tr td {
      padding-left: 20px; }
    .licence-payment-view-table tr .activeblue-text {
      font-weight: bold; }
  .licence-payment-view-table .name-cell {
    font-weight: bold;
    width: 170px; }
  .licence-payment-view-table .supervisor-cell {
    width: 320px; }

.s5ths,
.m5ths,
.l5ths,
.xl5ths {
  margin-left: auto;
  left: auto;
  right: auto; }

.row .col.s5ths {
  width: 20%; }

@media only screen and (min-width: 601px) {
  .row .col.m5ths {
    width: 20%; } }

@media only screen and (min-width: 993px) {
  .row .col.l5ths {
    width: 20%; } }

.exam-confirmation .s5ths,
.exam-confirmation .m5ths,
.exam-confirmation .l5ths,
.exam-confirmation .xl5ths {
  margin-left: auto;
  left: auto;
  right: auto; }

.exam-confirmation .row .col.s5ths {
  width: 20%; }

@media only screen and (min-width: 601px) {
  .exam-confirmation .row .col.m5ths {
    width: 20%; } }

@media only screen and (min-width: 993px) {
  .exam-confirmation .row .col.l5ths {
    width: 20%; } }

.exam-confirmation .sub-header {
  padding-bottom: 0px; }

.exam-confirmation .sub-header-small {
  margin-bottom: -15px; }

.exam-confirmation .total-table .total-cell {
  padding-right: 25px; }

.exam-confirmation .total-cell {
  width: 9.5%;
  padding-right: 35px; }

.exam-root .summary hr {
  border: none;
  height: 1px;
  color: black;
  /* Old IE */
  background-color: black;
  /* Modern Browsers */ }

.exam-root .summary .thick {
  height: 3px; }

.exam-root .borderless {
  border: 0; }

.exam-root .drawer {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  font-family: 'HelveticaNeueLTPro-Bd'; }
  .exam-root .drawer .mock-collapsible-header {
    padding: 15px; }
  .exam-root .drawer li {
    display: inline; }
    .exam-root .drawer li .drawer-title {
      background-color: inherit;
      border: none;
      display: -webkit-flex;
      display: flex;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000; }
      .exam-root .drawer li .drawer-title .header-title {
        margin-right: auto; }
      .exam-root .drawer li .drawer-title .edit-btn {
        color: #00afdb;
        color: #00afdb;
        font-size: 14px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        margin: 0 20px; }
      .exam-root .drawer li .drawer-title .caret {
        margin-right: 0;
        -webkit-transition: -webkit-transform 0.2s ease;
        transition: -webkit-transform 0.2s ease;
        transition: transform 0.2s ease;
        transition: transform 0.2s ease, -webkit-transform 0.2s ease; }
    .exam-root .drawer li.active .drawer-title .caret {
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg);
      -webkit-transform-origin: 0.6em 0.5em;
              transform-origin: 0.6em 0.5em; }
    .exam-root .drawer li .default-cursor {
      cursor: auto; }
    .exam-root .drawer li .drawer-outer-body {
      padding: 0rem;
      border: none; }
      .exam-root .drawer li .drawer-outer-body .drawer-inner-body {
        padding: 0rem 2rem; }
        .exam-root .drawer li .drawer-outer-body .drawer-inner-body .licence-number-cell {
          width: 80px; }
        .exam-root .drawer li .drawer-outer-body .drawer-inner-body .cpd-cell {
          padding-left: 80px; }
        .exam-root .drawer li .drawer-outer-body .drawer-inner-body .shorter {
          margin-bottom: 5px; }
        .exam-root .drawer li .drawer-outer-body .drawer-inner-body .subtitle {
          font-size: 14px;
          font-family: 'HelveticaNeueLTPro-Roman';
          color: #c4c4c4; }
        .exam-root .drawer li .drawer-outer-body .drawer-inner-body .description {
          font-size: 16px;
          font-family: 'HelveticaNeueLTPro-Md'; }
        .exam-root .drawer li .drawer-outer-body .drawer-inner-body .terms-and-cond {
          line-height: 1.2rem;
          font-size: 12px; }
    .exam-root .drawer li .table-container {
      font-family: 'HelveticaNeueLTPro-Roman'; }
      .exam-root .drawer li .table-container .row {
        margin-bottom: 0; }
  .exam-root .drawer .licence-table tr,
  .exam-root .drawer .licence-table th {
    border: none; }

.exam-root .amount-header {
  text-align: right; }

.exam-root .amount-cell {
  text-align: right;
  padding-right: 5px; }

.exam-root .total-table td {
  font-weight: bold;
  text-align: right;
  width: 15%; }

.exam-root .total-table .total-cell {
  padding-right: 30px; }

.exam-root .generic-table tr:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.error-page-container .controls {
  padding-top: 50px; }
  .error-page-container .controls button:first-child {
    margin-left: 0px; }

.error-page-container .warning-icon {
  color: red;
  padding-right: 20px; }

.bulk-renew-container {
  padding-top: 30px; }
  .bulk-renew-container .controls {
    margin-top: 30px; }
    .bulk-renew-container .controls button:last-child {
      margin-left: 30px; }

.confirm-controls {
  text-align: center; }
  .confirm-controls button:last-child {
    margin-left: 30px; }

.confirm-modal-body {
  max-height: 30px;
  padding: 55px; }
  .confirm-modal-body .confirm-message {
    font-size: 20px; }

.pay-and-nominate .columnheader {
  line-height: 35px; }

.pay-and-nominate .sub-heading {
  color: #666666;
  font-size: 18px;
  font-family: 'HelveticaNeueLTPro-Roman' !important; }

.pay-and-nominate .licence-search-button {
  margin-top: 32px;
  margin-left: 0px; }

.pay-and-nominate .control-row {
  margin-bottom: 70px; }

.pay-and-nominate .application-table .generic-table .name-header-cell {
  padding-left: 0px; }

.pay-and-nominate .application-table .generic-table .currency-cell {
  font-weight: bold; }

.pay-and-nominate .application-table .generic-table th,
.pay-and-nominate .application-table .generic-table td {
  padding-left: 10px;
  border-bottom: none; }
  .pay-and-nominate .application-table .generic-table th:first-child,
  .pay-and-nominate .application-table .generic-table td:first-child {
    padding-left: 0px; }

.pay-and-nominate .application-table .generic-table i {
  padding-right: 10px;
  float: right; }

.pay-and-nominate .application-table .generic-table .link-button {
  cursor: pointer; }

.pay-and-nominate .application-table .generic-table .licence-row {
  border-bottom: none;
  font-weight: bold; }

.pay-and-nominate .application-table .generic-table .first-row {
  border-top: solid 1px #d6d6d6; }

.pay-and-nominate .application-table .generic-table .fee-row {
  border-bottom: none; }
  .pay-and-nominate .application-table .generic-table .fee-row td {
    padding-top: 0px; }

.pay-and-nominate .application-table .generic-table .sub-total-row {
  font-weight: bold;
  border-top: solid 1px #d6d6d6;
  border-bottom: none; }

.pay-and-nominate .application-table .generic-table .gst-total-row {
  border-bottom: none; }
  .pay-and-nominate .application-table .generic-table .gst-total-row .currency-cell {
    font-weight: normal; }

.pay-and-nominate .application-table .generic-table .total-row {
  border-top: 1px solid;
  border-bottom: 2px solid;
  font-weight: bold; }

.pay-and-nominate .application-table .generic-table .name-cell {
  padding-left: 0px; }

.pay-and-nominate .application-table .generic-table .amount-cell {
  text-align: right;
  padding-right: 15px; }

.pay-and-nominate .application-table .generic-table .table-text-box {
  width: 210px;
  height: 56px; }
  .pay-and-nominate .application-table .generic-table .table-text-box div {
    width: 100px;
    float: left; }
  .pay-and-nominate .application-table .generic-table .table-text-box span.cancel-nomination {
    float: left;
    font-weight: normal;
    margin-left: 10px; }

.pay-and-nominate .application-table .generic-table .supervisor-name {
  display: inline-block;
  height: 27px;
  line-height: 27px;
  min-width: 100px;
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.company-contact-details .control-row {
  margin-bottom: 70px; }

.pay-and-nominate .columnheader {
  line-height: 35px; }

.pay-and-nominate .sub-heading {
  color: #666666;
  font-size: 18px;
  font-family: 'HelveticaNeueLTPro-Roman' !important; }

.pay-and-nominate .control-row {
  margin-bottom: 70px; }

.pay-and-nominate .application-table .generic-table .name-header-cell {
  padding-left: 0px; }

.pay-and-nominate .application-table .generic-table .currency-cell {
  font-weight: bold; }

.pay-and-nominate .application-table .generic-table th,
.pay-and-nominate .application-table .generic-table td {
  padding-left: 10px;
  border-bottom: none; }
  .pay-and-nominate .application-table .generic-table th:first-child,
  .pay-and-nominate .application-table .generic-table td:first-child {
    padding-left: 0px; }

.pay-and-nominate .application-table .generic-table i {
  padding-right: 10px;
  float: right; }

.pay-and-nominate .application-table .generic-table .link-button {
  cursor: pointer; }

.pay-and-nominate .application-table .generic-table .licence-row {
  border-bottom: none;
  font-weight: bold; }

.pay-and-nominate .application-table .generic-table .first-row {
  border-top: solid 1px #d6d6d6; }

.pay-and-nominate .application-table .generic-table .fee-row {
  border-bottom: none; }
  .pay-and-nominate .application-table .generic-table .fee-row td {
    padding-top: 0px; }

.pay-and-nominate .application-table .generic-table .sub-total-row {
  font-weight: bold;
  border-top: solid 1px #d6d6d6;
  border-bottom: none; }

.pay-and-nominate .application-table .generic-table .gst-total-row {
  border-bottom: none; }
  .pay-and-nominate .application-table .generic-table .gst-total-row .currency-cell {
    font-weight: normal; }

.pay-and-nominate .application-table .generic-table .total-row {
  border-top: 1px solid;
  border-bottom: 2px solid;
  font-weight: bold; }

.pay-and-nominate .application-table .generic-table .name-cell {
  padding-left: 0px; }

.pay-and-nominate .application-table .generic-table .amount-cell {
  text-align: right;
  padding-right: 15px; }

.pay-and-nominate .application-table .generic-table .table-text-box {
  width: 210px;
  height: 56px; }
  .pay-and-nominate .application-table .generic-table .table-text-box div {
    width: 100px;
    float: left; }
  .pay-and-nominate .application-table .generic-table .table-text-box span.cancel-nomination {
    float: left;
    font-weight: normal;
    margin-left: 10px; }

.payment-success .active-blue {
  color: #00afdb; }

.payment-success .hand-cursor {
  cursor: pointer; }

.payment-success .rating-radio-collection .radio-group {
  padding: 25px; }

.company-root .generic-table .main-row {
  font-weight: bold; }

.company-root .generic-table .supervisor-cell {
  width: 300px; }

.company-root .generic-table .name-cell {
  width: 150px; }

.company-root .generic-table .currency-cell {
  padding: 0; }

.company-root .generic-table tr:first-child {
  border-bottom: none; }

.company-root .company-section .col {
  padding-left: 0px; }

.company-root .total-table td {
  font-weight: bold;
  text-align: right; }

.company-root .total-table .total-cell {
  width: 10%;
  padding-right: 35px; }

.company-root .total-table .currency-cell {
  width: 20px; }

.company-root .total-table .total-label-cell {
  width: 50%; }

.company-root .drawer {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  font-family: 'HelveticaNeueLTPro-Bd'; }
  .company-root .drawer .mock-collapsible-header {
    padding: 15px; }
  .company-root .drawer li {
    display: inline; }
    .company-root .drawer li .drawer-title {
      background-color: inherit;
      border: none;
      display: -webkit-flex;
      display: flex;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000; }
      .company-root .drawer li .drawer-title .header-title {
        margin-right: auto;
        font-weight: bold; }
      .company-root .drawer li .drawer-title .edit-btn {
        color: #00afdb;
        color: #00afdb;
        font-size: 14px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        margin: 0 20px; }
      .company-root .drawer li .drawer-title .caret {
        margin-right: 0;
        -webkit-transition: -webkit-transform 0.2s ease;
        transition: -webkit-transform 0.2s ease;
        transition: transform 0.2s ease;
        transition: transform 0.2s ease, -webkit-transform 0.2s ease; }
    .company-root .drawer li.active .drawer-title .caret {
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg);
      -webkit-transform-origin: 0.6em 0.5em;
              transform-origin: 0.6em 0.5em; }
    .company-root .drawer li .default-cursor {
      cursor: auto; }
    .company-root .drawer li .drawer-outer-body {
      padding: 0rem;
      border: none; }
      .company-root .drawer li .drawer-outer-body .drawer-inner-body {
        padding: 0rem 2rem; }
        .company-root .drawer li .drawer-outer-body .drawer-inner-body .licence-number-cell {
          width: 150px; }
        .company-root .drawer li .drawer-outer-body .drawer-inner-body .cpd-cell {
          padding-left: 150px; }
        .company-root .drawer li .drawer-outer-body .drawer-inner-body .shorter {
          margin-bottom: 5px; }
        .company-root .drawer li .drawer-outer-body .drawer-inner-body .subtitle {
          font-size: 14px;
          font-family: 'HelveticaNeueLTPro-Roman';
          color: #c4c4c4;
          width: 150px; }
        .company-root .drawer li .drawer-outer-body .drawer-inner-body .description {
          font-size: 16px;
          font-family: 'HelveticaNeueLTPro-Md'; }
        .company-root .drawer li .drawer-outer-body .drawer-inner-body .terms-and-cond {
          line-height: 1.2rem;
          font-size: 12px; }
    .company-root .drawer li .table-container {
      font-family: 'HelveticaNeueLTPro-Roman'; }
      .company-root .drawer li .table-container .row {
        margin-bottom: 0; }
  .company-root .drawer .licence-table tr,
  .company-root .drawer .licence-table th {
    border: none; }
  .company-root .drawer .licence-table .amount-header {
    text-align: right; }
  .company-root .drawer .licence-table .amount-cell {
    text-align: right;
    padding-right: 5px;
    width: 6%; }

.upload-document-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .upload-document-container .document-type-container .section-title {
    font-weight: bold; }
  .upload-document-container .document-type-container .info-icon {
    cursor: pointer;
    color: #666666;
    float: left; }
  .upload-document-container .tool-tip-container {
    width: 80%;
    max-width: 450px;
    padding: 25px;
    background-color: white;
    position: absolute;
    z-index: 1004; }
    .upload-document-container .tool-tip-container .close-icon {
      margin-top: -15px;
      margin-right: -15px;
      cursor: pointer; }
    .upload-document-container .tool-tip-container .innter-text {
      margin-top: 10px;
      color: #666666; }

.upload-document-subheading {
  color: grey; }

.upload-section .border-red {
  border: solid 1px #ee3224;
  color: #ee3224; }

.upload-section .invalid-file {
  margin-top: -5px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  background-color: #ee3224;
  color: #ffffff; }

.upload-section .uploaded-files {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px; }
  .upload-section .uploaded-files .uploaded-file-item {
    padding: 2px;
    height: 50px;
    margin: 5px;
    background-color: #e4e4e4;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .upload-section .uploaded-files .uploaded-file-item .uploaded-file-icon-and-name {
      padding-top: 4px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      max-width: 70%; }
    .upload-section .uploaded-files .uploaded-file-item .attach-icon {
      margin-right: 15px; }
    .upload-section .uploaded-files .uploaded-file-item .delete-button {
      margin-top: 10px;
      margin-right: 10px; }
    .upload-section .uploaded-files .uploaded-file-item .delete-button:hover {
      color: #00afdb; }
    .upload-section .uploaded-files .uploaded-file-item .disabled {
      color: #c4c4c4; }
    .upload-section .uploaded-files .uploaded-file-item .file-name {
      cursor: pointer;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .upload-section .uploaded-files .uploaded-file-item .file-name:hover {
      font-weight: bold; }
    .upload-section .uploaded-files .uploaded-file-item .uploaded-file-delete {
      cursor: pointer; }
    .upload-section .uploaded-files .uploaded-file-item .loading-button {
      margin-top: 10px;
      height: 45px;
      width: 45px;
      background-color: transparent;
      border: none; }

.registration-payment-header {
  font-size: 1.5em;
  font-weight: 700; }

.registration-payment-container .radio-display {
  -webkit-flex-direction: column;
          flex-direction: column; }
  .registration-payment-container .radio-display .radio-option {
    margin: 10px 0px; }

.registration-payment-container .radio-groups {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: none; }
  .registration-payment-container .radio-groups .radio-option {
    display: inline; }

.registration-payment-container .subheader {
  color: grey;
  font-size: 1.1em;
  font-style: italic; }

.registration-payment-container [type="checkbox"].filled-in:disabled:not(:checked) + span:not(.lever)::after {
  background-color: lightgray; }

.registration-payment-container .registration-only-container {
  margin: 10px 0px 0px 40px; }

.registration-payment-container .spinner-container {
  display: -webkit-inline-flex;
  display: inline-flex;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center; }
  .registration-payment-container .spinner-container .loading-button {
    width: 50px;
    height: 50px; }

.payment-subheader {
  font-size: 1.1em; }

.total-amount-container {
  width: 100%;
  background-color: white;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(87, 89, 89, 0.49); }
  .total-amount-container .initial-amount {
    color: grey; }
    .total-amount-container .initial-amount .text {
      padding: 12px 20px; }
    .total-amount-container .initial-amount .amount {
      padding: 12px 20px; }
  .total-amount-container .first-row {
    padding: 16px 20px 8px 20px; }
  .total-amount-container .last-row {
    padding: 8px 20px 16px 20px; }
  .total-amount-container td {
    padding: 8px 20px; }
  .total-amount-container tr {
    border-bottom: none;
    padding: 10px; }
  .total-amount-container .text {
    width: 70%;
    font-weight: bold; }
  .total-amount-container .amount {
    width: 30%;
    text-align: right;
    font-weight: bold; }

.close-modal-button {
  padding: 0px 0px 20px 0px; }

.close-payment-button {
  padding: 10px 15px 10px 15px;
  border-radius: 28px;
  border: #00afdb 1px solid;
  border-width: 2px;
  border-style: solid;
  background-color: #00afdb;
  color: #ffffff;
  text-align: center;
  font-family: 'HelveticaNeueLTPro-Md';
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  text-transform: none;
  min-width: 100px;
  cursor: pointer; }

.poli-pay-button, .windcave-pay-button {
  width: 85%;
  box-shadow: 1px 1px 4px 0px rgba(156, 156, 156, 0.84);
  background-color: #fafafa;
  border: 1px solid #f0f3f4;
  padding-top: 10px;
  padding-bottom: 10px; }

.poli-pay-button {
  margin-top: 10px; }

.windcave-pay-button {
  margin-top: 20px;
  margin-bottom: 20px; }

.registration-pay-button:hover {
  background-color: #00afdb !important; }

@media (max-width: 500px) {
  .poli-pay-button, .windcave-pay-button {
    width: 90%; } }

.payment-title {
  text-align: center !important;
  font-weight: 700; }

.payment-text {
  text-align: center !important; }

.payment-cursor {
  cursor: pointer; }

.waiver-text {
  font-weight: bold;
  margin: 0px; }

.table-clause-container {
  margin-top: 10px; }
  .table-clause-container .table-clause-text {
    font-weight: bold;
    margin: 0px; }

@font-face {
  font-family: 'Material-Icons-Round';
  src: url(/static/media/Material-Icons-Round.6524aa1f.ttf) format("truetype"); }

@font-face {
  font-family: 'HelveticaNeueLTPro-Roman';
  src: url(/static/media/38CC3B_0_0.86a8dd2b.eot);
  src: url(/static/media/38CC3B_0_0.86a8dd2b.eot?#iefix) format("embedded-opentype"), url(/static/media/38CC3B_0_0.e0dd0921.woff2) format("woff2"), url(/static/media/38CC3B_0_0.3b7662dc.woff) format("woff"), url(/static/media/38CC3B_0_0.ca919ae8.ttf) format("truetype"); }

@font-face {
  font-family: 'HelveticaNeueLTPro-Md';
  src: url(/static/media/38CC3B_1_0.b5197c4e.eot);
  src: url(/static/media/38CC3B_1_0.b5197c4e.eot?#iefix) format("embedded-opentype"), url(/static/media/38CC3B_1_0.0738e0a8.woff2) format("woff2"), url(/static/media/38CC3B_1_0.89df1c20.woff) format("woff"), url(/static/media/38CC3B_1_0.9fdf36f6.ttf) format("truetype"); }

@font-face {
  font-family: 'HelveticaNeueLTPro-Bd';
  src: url(/static/media/38CC3B_2_0.bb52ad4e.eot);
  src: url(/static/media/38CC3B_2_0.bb52ad4e.eot?#iefix) format("embedded-opentype"), url(/static/media/38CC3B_2_0.fa12cbfd.woff2) format("woff2"), url(/static/media/38CC3B_2_0.09691427.woff) format("woff"), url(/static/media/38CC3B_2_0.5829e8dd.ttf) format("truetype"); }

i.round {
  font-family: 'Material-Icons-Round'; }

body {
  background-color: #f4f4f4;
  display: -webkit-flex !important;
  display: flex !important;
  min-height: 100vh !important;
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
  font-family: 'HelveticaNeueLTPro-Roman' !important; }

* {
  font-family: 'HelveticaNeueLTPro-Roman'; }

main {
  -webkit-flex: 1 0 auto !important;
          flex: 1 0 auto !important; }

.title-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.license-application-container {
  margin: 0px 20px 0px 20px; }

.licence-application-banner {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  background-image: -webkit-linear-gradient(#0058a8, #03115c);
  background-image: linear-gradient(#0058a8, #03115c);
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: center;
          align-self: center;
  border-radius: 15px;
  padding: 5px; }

.licence-application-title {
  margin-top: 20px;
  color: white;
  padding-left: 10px;
  padding-right: 10px; }

.licence-application-button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  border: none;
  outline: 0;
  text-decoration: none;
  color: #fff;
  padding: 15px 45px 15px 45px;
  border-radius: 28px;
  background-color: #00afdb;
  font-family: 'HelveticaNeueLTPro-Md';
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }

.btn,
.btn-large,
.btn-small {
  padding-top: 20px;
  padding-bottom: 35px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 28px;
  background-color: #00afdb;
  text-align: center;
  font-family: 'HelveticaNeueLTPro-Md';
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
  text-transform: none; }

.btn:hover,
.btn-large:hover,
.btn-small:hover,
.btn:focus,
.btn-large:focus,
.btn-small:focus {
  background-color: #00afdb; }

.btn.btn-inactive {
  background-color: #f5f5f5;
  color: #00afdb;
  border-width: 2px;
  border-style: solid;
  padding: 18px 28px 33px 28px;
  /* removes 2px to account for the border */ }
  .btn.btn-inactive:hover, .btn.btn-inactive:focus {
    background-color: #f5f5f5;
    border-width: 2px;
    border-style: solid; }

.registration-application-button {
  border: none;
  outline: 0;
  text-decoration: none;
  color: #fff;
  letter-spacing: .5px;
  padding: 15px;
  border-radius: 28px;
  background-color: #00afdb;
  text-align: center;
  font-family: 'HelveticaNeueLTPro-Md';
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  float: right;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  min-height: 100%; }

.registration-application-button:focus {
  background-color: #00afdb; }

.vline,
hr.vertical {
  border: none;
  border-right: 1px solid #c4c4c4;
  height: 438px;
  width: 1px; }

::-ms-clear {
  /* Stops Edge from displaying the X in edited input fields*/
  display: none; }

.input-header {
  margin: 20px 0 6px 20px;
  display: inline-block; }

.no-bottom-margin {
  margin-bottom: 0; }

/**
      TODO: end
  **/
.grey2-text {
  color: #666666; }

.grey2-Note-text {
  color: #666666;
  font-style: italic; }

.smaller {
  font-size: 12px; }

.small {
  font-size: 14px; }

.medium-text {
  font-size: 18px; }

.far-left {
  /* left: -250px; */
  padding: 0;
  padding-top: 5px !important;
  padding-left: 40px; }

@media (min-width: 993px) {
  .logo {
    max-height: 30px;
    margin-left: 40px; } }

@media (max-width: 992px) {
  .logo {
    max-height: 30px; } }

.link-underline {
  /* border-bottom: 1px solid #039be5 !important; */ }

/* Navbar */
nav {
  height: 80px !important;
  line-height: 80px !important; }
  nav .button-collapse i {
    height: 80px !important;
    line-height: 80px !important; }
  nav .brand-logo {
    font-size: 1.6rem; }

.dashboard-panels {
  box-sizing: border-box;
  height: 301px;
  border: 1px solid #f0f3f4;
  border-radius: 10px !important;
  background-color: #ffffff; }

.card-layout.disabled {
  display: none; }

.card-header {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  text-align: center; }

.card-line {
  box-sizing: border-box;
  height: 1px;
  width: 16px;
  border: 2px solid;
  border-radius: 100px !important;
  margin: auto;
  margin-bottom: 20px; }

.notification-icon {
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 50%;
  display: inline-block;
  border: 7px solid #ee3224; }

.arrow {
  line-height: 20px;
  padding-right: 20px;
  font-size: 20px; }

.dashboard-link-header {
  font-weight: bold;
  font-size: 18px;
  padding: 15px 5px;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px; }

.loading-screen-user-message {
  z-index: 10000000002;
  position: absolute;
  top: 54%;
  width: 100%;
  padding: 10px;
  text-align: center; }

@media (max-width: 992px) {
  .loading-screen-user-message {
    font-size: smaller; }
    .loading-screen-user-message h3,
    .loading-screen-user-message h4 {
      font-size: small; } }

.dashboard-special-user-message {
  color: #f78f1e;
  text-align: center;
  padding: 10px 100px; }

.modal-easter {
  width: 40% !important;
  height: 60% !important; }

.sub-text {
  margin-top: -20px;
  font-size: 18px;
  display: inline-block; }

.bold {
  font-weight: bold; }

.helvetica-bold {
  font-family: 'HelveticaNeueLTPro-Bd'; }

.exam-header {
  border-top: 1px solid #d6d6d6;
  font-size: 12px;
  line-height: 14px;
  font-weight: 100;
  padding: 40px 0px 10px 40px; }

.table-heading {
  border-top: 1px solid #d6d6d6;
  font-size: 12px;
  line-height: 14px;
  font-weight: 100; }

.table-header {
  border-top: 1px solid #d6d6d6;
  font-size: 12px;
  line-height: 14px;
  font-weight: 100;
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px; }

.table-item {
  margin-top: 0;
  margin-bottom: 0; }

.table-text-box {
  max-width: 210px; }

.table-set-size {
  width: 400px; }

.checkbox-blue[type='checkbox'].filled-in:checked + label:after {
  border: 2px solid #ff9800 !important;
  background-color: #ff9800 !important; }

.input-center {
  width: 120% !important;
  text-align: center !important; }

.footer-copyright {
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c4c4c4;
  padding-left: 0px !important; }

.footer-menu {
  opacity: 0.7;
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666666;
  padding-top: 25px !important;
  margin-bottom: -20px !important; }

footer {
  padding-top: 70px !important;
  padding-bottom: 30px !important; }
  footer div > div > div > p {
    font-size: 14px !important; }

.grey-text {
  color: #c4c4c4; }

.darkgrey-text {
  color: #666666; }

.activeblue-text {
  color: #00afdb; }

.medium-font {
  font-family: 'HelveticaNeueLTPro-Md'; }

.medium-font-size {
  font-size: 16px; }

.position-absolute {
  position: absolute; }

.disable-wordwrap {
  white-space: nowrap; }

.inner-page-container {
  max-width: 800px;
  margin: auto; }

.text-align-left {
  text-align: left; }

.icon-left-pad {
  padding-left: 29px;
  /* icon=24px, existing padding=5px */ }

.no-left-pad {
  padding-left: 0; }

.registration-payment-table {
  font-size: 14px; }

.registration-payment-font-size {
  font-size: 14px !important; }

.small-right-pad {
  padding-right: 10px; }

/* ------------------------------- */
/* Text styles */
.H8 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 50px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h1 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MH8 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h2 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h3 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h4 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MH1 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h5 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

h6 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.H7 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body1 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body2 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body3 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body4 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body5 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body6 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody4 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody5 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody6 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body7 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body8 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body9 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody10 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody11 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody12 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody13 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.Body10 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

.MBody15 {
  font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 8px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #08080d; }

/* ------------------------------- */
.title-bold {
  object-fit: contain;
  font-family: 'HelveticaNeueLTPro-Md';
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-left: -5px !important; }

.sub-title {
  object-fit: contain;
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #666666; }

a.large {
  object-fit: contain;
  opacity: 0.7;
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #00afdb; }

p.medium {
  object-fit: contain;
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #666666; }

p.footer {
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666666; }

.input-field > label:not(.label-icon).active {
  object-fit: contain !important;
  font-family: 'HelveticaNeueLTPro-Roman' !important;
  font-size: 17px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #666666 !important; }

#root .input-invalid {
  border: 1px solid #ee3224; }

nav ul a.active {
  color: #00afdb !important;
  border-bottom: 2px solid #00afdb; }

/* -- underline -- */
.link {
  font-family: 'HelveticaNeueLTPro-Md' !important;
  text-decoration: none;
  position: relative;
  color: #00afdb !important;
  padding-bottom: 4px; }
  .link:after {
    content: '';
    height: 1px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0%;
    background: #00afdb;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease; }
  .link:hover:after {
    width: 100%;
    left: 0; }

/* ---------------- */
#dropdown1 li > * {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* line-height: 20px !important; */
  font-family: 'HelveticaNeueLTPro-Roman';
  font-size: 14px;
  color: #666666; }

#dropdown1 li:first-child {
  margin-top: 20px; }

#dropdown1 li:last-child {
  margin-top: 20px;
  margin-bottom: 20px; }

.text-red {
  color: #ee3224; }

/* @media only screen and (min-width: 601px)
  nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
      height: 20px !important;
      line-height: 20px !important;
  } */
.licence-padding {
  margin-top: 40px !important;
  padding-bottom: 30px !important; }

.password-padding {
  padding-bottom: 19px !important; }

.login-btn {
  margin-top: 10px !important; }

.company-padding {
  margin-top: 27px !important; }

.alert {
  padding: 0px !important; }

.alert_box {
  border-radius: 6px !important; }

/* Address Finder Result Box */
ul.af_list {
  z-index: 5;
  list-style: none;
  margin: 0;
  border: solid 1px #999;
  border-radius: 5px;
  background: #fff;
  font-size: 1em; }

li.af_item {
  cursor: pointer;
  padding: 0.5em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

li.af_hover {
  background-color: #0058a8;
  color: #fff; }

li.af_footer {
  font-size: 0.8em;
  color: #666;
  text-align: right;
  display: none; }

.cursor-pointer {
  cursor: pointer; }

.manual-address-link {
  float: right;
  cursor: pointer;
  padding-top: 0.5em; }

.manual-address-fields {
  padding: 0.5em 1em 1.5em 1em;
  background-color: #e8e8e8; }

.af_empty {
  padding: 5px; }

.btn-small.manual-save {
  margin-top: 1em; }

.btn-small.manual-cancel {
  margin-top: 1em;
  margin-left: 0.5em; }

.btn-ml-30 {
  margin-left: 30px; }

button.btn-link {
  background: none;
  border-radius: 5px;
  color: #00afdb;
  font-weight: 500;
  font-family: 'HelveticaNeueLTPro-Md';
  text-transform: none; }

.button-colour-invert {
  background: none;
  border: solid 1px #00afdb;
  color: #00afdb;
  box-shadow: none; }
  .button-colour-invert:hover {
    color: white; }

select.select-input {
  outline: 0;
  /* removes border when selecting */
  border: 1px solid #c4c4c4; }

select.select-highlighted, select:focus, select:active {
  border: 3px solid #00afdb;
  padding: 13px; }

option {
  font-size: 16px; }

#root input[type='text'].text-input:focus, #root input[type='text'].text-input:active {
  border: 3px solid #00afdb;
  box-shadow: none;
  padding: 13px; }

#root .text-input {
  border: 1px solid #c4c4c4; }

input[type='text'].input-disabled:disabled {
  background-color: #ddd;
  border: 1px solid #666666;
  padding: 15px; }
  input[type='text'].input-disabled:disabled::-webkit-input-placeholder {
    color: #000; }
  input[type='text'].input-disabled:disabled::-ms-input-placeholder {
    color: #000; }
  input[type='text'].input-disabled:disabled::placeholder {
    color: #000; }

.inline-text {
  display: inline-block; }

.subheader {
  font-family: 'HelveticaNeueLTPro-Md'; }

.invalid-message {
  color: #ee3224; }

.input-label-horizontal {
  font-size: 100%;
  margin-left: 20px; }

.hide-element {
  display: none; }

/* Checkbox */
[type='checkbox'] + span:not(.lever) {
  height: 20px; }

[type='checkbox'].filled-in + span:not(.lever):after {
  border-radius: 3px; }

[type='checkbox'].filled-in:checked + span:not(.lever):after {
  border: 2px solid #00afdb;
  background-color: #00afdb;
  width: 24px;
  height: 24px; }

[type='checkbox'].filled-in:not(:checked) + span:not(.lever):after {
  height: 24px;
  width: 24px;
  border: 2px solid #c4c4c4; }

[type='checkbox'].filled-in:checked + span:not(.lever):before {
  width: 10px;
  height: 16px; }

[type='radio']:checked + span:after {
  border: 2px solid #00afdb; }

[type='radio'].with-gap:checked + span:before, [type='radio'].with-gap:checked + span:after {
  border: 2px solid #00afdb; }

[type='radio']:checked + span:after,
[type='radio'].with-gap:checked + span:after {
  background-color: #00afdb; }

[type='radio'] + span:before, [type='radio'] + span:after {
  width: 24px;
  height: 24px; }

[type='radio']:not(:checked) + span,
[type='radio']:checked + span {
  line-height: 30px; }

.input-error {
  max-width: 210px;
  background-color: #ee3224;
  z-index: 20;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 8px;
  font-size: 14px;
  color: #fff; }

.input-error:after {
  content: '';
  position: absolute;
  top: 0;
  right: 70%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #ee3224;
  margin-top: -20px; }

.arrow-text {
  padding-left: 45px;
  display: flow-root;
  margin-top: -25px; }

/* Removed blue outline from chrome */
:focus {
  outline: none !important; }

ul.bullet li {
  padding-left: 45px;
  padding-bottom: 5px;
  position: relative; }
  ul.bullet li:before {
    content: 'arrow_forward';
    font-family: 'Material Icons';
    position: absolute;
    font-size: 20px;
    left: 0;
    top: 0;
    line-height: 20px;
    -webkit-font-feature-settings: 'liga';
            font-feature-settings: 'liga'; }

ul.cross li {
  padding-left: 45px;
  padding-bottom: 5px;
  position: relative; }
  ul.cross li:before {
    content: 'clear';
    font-family: 'Material Icons';
    position: absolute;
    font-size: 22px;
    left: 0;
    top: 0;
    line-height: 24px;
    -webkit-font-feature-settings: 'liga';
            font-feature-settings: 'liga'; }

.loading-button {
  background-image: url(/images/loading-spinner-1.svg);
  background-position-x: 10px;
  background-position-y: 50%;
  background-repeat: no-repeat;
  padding-left: 60px; }

.btn-primary {
  margin-left: 5px;
  margin-right: 5px; }

.btn-secondary {
  margin-left: 5px;
  margin-right: 5px;
  border-color: #00afdb;
  color: #00afdb;
  background-color: #ffffff !important;
  text-align: center; }

.btn-secondary:hover {
  border-color: #00afdb;
  color: #00afdb;
  background-color: #ffffff !important; }

/*
  This file specifies css styles that are meant to override the base styles provided by materialize.
  The selectors here are duplicated from the materialize library, and are used to override the base styles
  to the ones as per design.

  The selectors are big and cumbersome because thats what materialize uses, and we are using them to ensure
  we are overriding the right elements.

  If you are implementing new styles, and wish to override these base styles, you can use a class with a high
  specificity like the following:

  #root .text-input {
  }

  For more information:
  https://developer.mozilla.org/en-US/docs/Web/CSS/Specificity
  https://specificity.keegan.st/
*/

input:not([type]),
input[type='text']:not(.browser-default),
input[type='password']:not(.browser-default),
input[type='email']:not(.browser-default),
input[type='url']:not(.browser-default),
input[type='time']:not(.browser-default),
input[type='date']:not(.browser-default),
input[type='datetime']:not(.browser-default),
input[type='datetime-local']:not(.browser-default),
input[type='tel']:not(.browser-default),
input[type='number']:not(.browser-default),
input[type='search']:not(.browser-default),
textarea.materialize-textarea,
select {
   border: 1px solid var(--pgdb-grey);
   background-color: #ffffff;
   text-align: left;
   border-radius: 6px;
   padding: 15px;
   color: #666666;
   margin: 0;
   height: 56px;
   box-sizing: border-box;
   font-family: 'HelveticaNeueLTPro-Roman', 'Helvetica Neue Light',
      'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
   font-size: 100%;
   -webkit-transition: none;
   transition: none;
}

select {
   border: 1px solid var(--pgdb-darkgrey);
}

input:not([type]):disabled,
input:not([type])[readonly='readonly'],
input[type='text']:not(.browser-default):disabled,
input[type='text']:not(.browser-default)[readonly='readonly'],
input[type='password']:not(.browser-default):disabled,
input[type='password']:not(.browser-default)[readonly='readonly'],
input[type='email']:not(.browser-default):disabled,
input[type='email']:not(.browser-default)[readonly='readonly'],
input[type='url']:not(.browser-default):disabled,
input[type='url']:not(.browser-default)[readonly='readonly'],
input[type='time']:not(.browser-default):disabled,
input[type='time']:not(.browser-default)[readonly='readonly'],
input[type='date']:not(.browser-default):disabled,
input[type='date']:not(.browser-default)[readonly='readonly'],
input[type='datetime']:not(.browser-default):disabled,
input[type='datetime']:not(.browser-default)[readonly='readonly'],
input[type='datetime-local']:not(.browser-default):disabled,
input[type='datetime-local']:not(.browser-default)[readonly='readonly'],
input[type='tel']:not(.browser-default):disabled,
input[type='tel']:not(.browser-default)[readonly='readonly'],
input[type='number']:not(.browser-default):disabled,
input[type='number']:not(.browser-default)[readonly='readonly'],
input[type='search']:not(.browser-default):disabled,
input[type='search']:not(.browser-default)[readonly='readonly'],
textarea.materialize-textarea:disabled,
textarea.materialize-textarea[readonly='readonly'] {
   border-bottom: 1px solid #666666;
   color: #c4c4c4;
}

input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
   border: 1px solid #00afdb;
   box-shadow: none;
}

#toast-container {
   top: auto !important;
   bottom: 2%;
   width: 20em;
   right: 0;
   left: 0;
   margin-right: auto;
   margin-left: auto;
}

#toast-container .toast.success {
   background-color: #26a69a;
}

#toast-container .toast.error {
   background-color: #bb2b2b;
}

