@import '../../../Theme/base.scss';

.registration-container {
   
   .row.no-margin {
      margin-bottom: 0;
   }
}

.side-note-background {
   border: 1px #ddd;
   background-color: white;
   margin-bottom: 10px;
   margin-top: 10px;
}

.important-note {
   padding: 15px;
   background-color: $pgdb-white;
   .important-note-header {
      display: flex;
      align-items: center;
      svg {
         margin-right: 5px;
         color: $pgdb-activeblue; 
      }
   }

   .important-note-content {
      svg {
      font-size: 10px;
      margin-right: 5px;       
      }
      .important-note-sublist {
         margin-left: 15px;
      }

      ul {
         padding-left:0;
         list-style: none;
         li {
            padding-left: 1.5rem;
            text-indent: -1rem;
         }
      }

      ol {
         padding-left: 1rem;
         li {
            padding-left: 1rem;
            text-indent: 0rem; 
         }
      }
   }

   .application-fee-title{
      font-weight: bold;
   }
   
 }

.important-note-footer {
   font-weight: bold;
}

.registration-header{
   padding-bottom: 15px;
}

.registration-subheading{
   font-weight: 600;
   padding-bottom: 5px;
}

.registration-list p{
   margin: 8px 0 0 0;
}

.important-note-email {
   word-wrap: break-word;
}

.important-note-phone {
   white-space: nowrap;
}

.application-fees {
   padding-bottom:15px;
   p {
   margin: 10px 0 10px 0;
}
}