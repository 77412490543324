@import '../../../../Theme/base.scss';

.mt-10 {
   margin-top: 10px;
}

.mb-0 {
   margin-bottom: 0px;
}

.m-0 {
   margin: 0px;
}

.vertical-alignment {
   vertical-align: middle;
}

.min-width-170 {
   min-width: 170px;
}

.registration-click-here-button {
   right: 30px;
   margin-left: 30px !important;
}

.application-status-details {
   vertical-align: middle;
   display: flex;
   align-items: center;
}

.application-status-row {
   h2 {
      color: $pgdb-darkpurple;
      margin: 20px 10px;
   }

   background: $pgdb-white;
   text-align: center;
   margin-top: 20px;
   padding: 15px 0 15px 0;
   border-radius: 50px;
}

.application-action-buttons {
   display: flex;
   align-items: center;
   justify-content: center;
}

.override-margin {
   margin-left: 0px !important;
   margin-right: 0px !important;
   margin: 0 0px !important;
   padding-left: 25px !important;
   padding-right: 25px !important;
}

.override-margin-large {
   margin-left: 0px !important;
   margin-right: 0px !important;
   margin: 0 0px !important;
   padding-left: 9px !important;
   padding-right: 9px !important;
}

.delete-application-buttons {
   float: right;
   padding: 0px 20px 20px 0px;
}

.action-buttons,
.draft-action-buttons {
   text-overflow: '';
}

@media (max-width: 1238px) {
   .draft-action-buttons {
      min-width: 85px;
      padding: 15px 20px 30px 20px !important;
      font-size: 9px !important;
   }
}

.modal-cancel-button {
   padding: 10px 15px 10px 15px;
   border-radius: 28px;
   border: $pgdb-activeblue 1px solid;
   border-width: 2px;
   border-style: solid;
   background-color: $pgdb-verylightgrey;
   color: $pgdb-activeblue;
   text-align: center;
   font-family: 'HelveticaNeueLTPro-Md';
   line-height: 16px;
   font-size: 12px;
   font-weight: 500;
   text-transform: none;
   width: 100px;
   margin-right: 10px;
   cursor: pointer;
}

.modal-delete-button {
   padding: 10px 15px 10px 15px;
   border-radius: 28px;
   border: $pgdb-red 1px solid;
   border-width: 2px;
   border-style: solid;
   background-color: $pgdb-red;
   color: $pgdb-white;
   text-align: center;
   font-family: 'HelveticaNeueLTPro-Md';
   line-height: 16px;
   font-size: 12px;
   font-weight: 500;
   text-transform: none;
   min-width: 100px;
   cursor: pointer;
}

.delete-modal {
   z-index: 1003;
   top: 10%;
   width: 500px;
   text-align: center;
   position: fixed;
   left: 0;
   right: 0;
   background-color: #fafafa;
   margin: auto;
   border-radius: 10px;
   box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
}

.missing-condition {
   color: $pgdb-activeblue;
   font-size: 2.2rem;
   padding-bottom: 0.5rem;
}

.outstanding-conditions > li {
   margin-top: 5px;
}

.title-message-pop-up {
   margin-top: 0px;
   margin-left: 0px;
   padding-left: 40px;
}

@media (max-width: 500px) {
   .delete-modal {
      width: auto;
   }
}
