@import '../../../Theme/base.scss';

.registration-container {
   .row.no-margin {
      margin-bottom: 0;
   }
}

.side-note-background {
   border: 1px #ddd;
   background-color: white;
   margin-bottom: 10px;
   margin-top: 10px;
}

.important-note {
   padding: 15px;
   background-color: $pgdb-white;
   .important-note-header {
      display: flex;
      align-items: center;
      svg {
         margin-right: 5px;
         color: $pgdb-activeblue; 
      }
   }

   .important-note-content {
      svg {
      font-size: 10px;
      margin-right: 5px;         
      }
   }
}

.important-note-header-content,
.document-format,
.important-note-footer {
   font-weight: bold;
}

.registration-header{
   padding-bottom: 15px;
}

.registration-subheading{
   font-weight: 600;
   padding-bottom: 5px;
}

.registration-list p{
   margin: 8px 0 0 0;
}

.important-note-email {
   word-wrap: break-word;
}

.important-note-phone {
   white-space: nowrap;
}

.document-note-content {
   padding-bottom: 5px;
   p {
   margin: 7px 0 0 0;
}
}

.country-extra-component-text {
   padding-left: 10px;
   padding-right:30px;
}

.subheading-container{
   padding-left: 10px;
   padding-bottom: 20px;
   color: #888888;
   font-style:italic;
}

.fit-and-proper-navigate-button:disabled{
   pointer-events: none;
   background-color: #DFDFDF !important;
   -webkit-box-shadow: none;
   box-shadow: none;
   color: #9F9F9F !important;
   cursor: default;
}

.reg-fit-and-prop-button-container{
   button.fit-proper-next-btn {
      display: flex;
      align-items: center;
      padding-top: 28px;
      padding-bottom: 28px;
    }
}